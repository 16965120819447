<template>
  <v-text-field
    :type="show ? 'text' : 'password'"
    v-bind="$attrs"
    solo
    outlined
    flat
    :rules="[rules.minLength8, rules.required]"
    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
    :label="label"
    :value="value"
    @input="(v) => $emit('input', v)"
    @click:append="show = !show"
  ></v-text-field>
</template>

<script>
import validation from "../../use/validations";

export default {
  name: "InputElement",
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      rules: validation,
    };
  },
};
</script>

<style></style>
