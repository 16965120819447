<template>
  <div class="nurses-patient-table mt-4">
    <div class="mb-8">
      <div class="text-h5 mb-4">Seznam pacientů</div>
      <div
        class="blue lighten-4 pa-4 rounded-lg primary--text d-flex align-center"
      >
        <v-icon class="mr-2" color="primary">mdi-information</v-icon>
        <div>
          Pokud pacient přijal Vaši žádost a nevidíte ji v tabulce pacientů,
          prosím aktualizujte stránku prohlížeče.
        </div>
      </div>
    </div>
    <v-row>
      <v-col>
        <div>
          <small class="grey--text">Vyhledávání</small>
        </div>
        <InputSearchQuery @search="search"></InputSearchQuery>
      </v-col>
      <v-col>
        <div>
          <small class="grey--text"> Aktivita v užívání léků </small>
        </div>
        <InputSelectElement
          v-model="medicationFilter.value"
          :clearable="false"
          label="Aktivita v medikaci"
          :items="medicationFilter.options"
          @change="fetchPatients"
        ></InputSelectElement>
      </v-col>
      <v-col>
        <div>
          <small class="grey--text"> Aktivita ve zdravotních metrikách </small>
        </div>
        <InputSelectElement
          v-model="healthMetricsFilter.value"
          :clearable="false"
          label="Aktivita ve zdravotních metrikách"
          :items="healthMetricsFilter.options"
          @change="fetchPatients"
        ></InputSelectElement>
      </v-col>
    </v-row>
    <DataTable
      :loading="tableLoading"
      v-if="patients"
      :headers="headers"
      :items="patients"
      :metadata="metadata"
      @updateFooterOptions="updateFooterOptions"
    >
      <template #item.patientOfNurseFrom="{ item }">
        {{ getCzechDate(item.patientOfNurseFrom) }}
      </template>
      <template v-slot:item.isActivelyTakingMedication="{ item }">
        <div class="text-center">
          <PatientStatusIcon
            :is-actively-taking-medication="item.isActivelyTakingMedication"
            :is-actively-rejecting-medication="
              item.isActivelyRejectingMedication
            "
            :is-not-tracking-medication="item.isNotTrackingMedication"
            :is-possibly-rejecting-medication="
              item.isPossiblyRejectingMedication
            "
          />
        </div>
      </template>
      <template v-slot:item.detail="{ item }">
        <v-btn @click="seeDetail(item)" color="primary" text outlined>
          Detail
        </v-btn>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "../elements/DataTable.vue";

import PatientStatusIcon from "../icons/PatientStatusIcon.vue";
import useApiCall from "../../use/apiCall";

import InputSearchQuery from "../elements/InputSearchQuery.vue";
import InputSelectElement from "../elements/InputSelectElement.vue";
import useFormatDate from "../../use/formatDate";

export default {
  components: {
    DataTable,
    InputSelectElement,
    PatientStatusIcon,
    InputSearchQuery,
  },

  setup() {
    const { getData } = useApiCall();
    const { getCzechDate } = useFormatDate();

    return {
      getData,
      getCzechDate,
    };
  },

  data() {
    return {
      patients: [],
      showDialog: false,
      tableLoading: false,
      medicationFilter: {
        value: null,
        options: [
          {
            text: "Všichni pacienti",
            value: null,
          },
          {
            text: "Aktivní pacienti",
            value: "isActivelyTakingMedication",
          },
          {
            text: "Pacienti odmítající léčbu",
            value: "isActivelyRejectingMedication",
          },
          {
            text: "Pacienti nezaznamenávající léčbu",
            value: "isNotTrackingMedication",
          },
          {
            text: "Pacienti s nestabilním záznamem léčby",
            value: "isPossiblyRejectingMedication",
          },
        ],
      },
      healthMetricsFilter: {
        value: null,
        options: [
          {
            text: "Všichni pacienti",
            value: null,
          },
          {
            text: "Zaznamenávající hmotnost alespoň 1x za měsíc",
            value: false,
          },
          {
            text: "Nezaznamenávající hmotnost alespoň 1x za měsíc",
            value: true,
          },
        ],
      },
      patientsTableParams: {
        skip: 0,
        take: 10,
        searchQuery: null,
      },
      metadata: {
        orderBy: "occurredAtDesc",
      },
      headers: [
        {
          text: "Jméno pacienta",
          value: "fullName",
          sortable: false,
        },
        {
          text: "Email pacienta",
          value: "email",
          sortable: false,
        },
        {
          text: "Rok narození",
          value: "birthYear",
          sortable: false,
        },
        {
          text: "Datum propojení",
          value: "patientOfNurseFrom",
          sortable: false,
        },
        {
          text: "Status pacienta",
          value: "isActivelyTakingMedication",
          sortable: false,
        },
        {
          text: "",
          value: "detail",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    seeDetail(patient) {
      this.$store.commit("setPatientActivityStatus", {
        isActivelyTakingMedication: patient.isActivelyTakingMedication,
        isActivelyRejectingMedication: patient.isActivelyRejectingMedication,
        isNotTrackingMedication: patient.isNotTrackingMedication,
        isPossiblyRejectingMedication: patient.isPossiblyRejectingMedication,
      });
      this.$router.push({ path: `pacienti/${patient.id}/rybelsus` });
    },

    async updateFooterOptions(event) {
      this.patientsTableParams.skip = event.page * event.itemsPerPage;
      this.patientsTableParams.take = event.itemsPerPage;
      await this.fetchPatients();
    },

    async fetchPatients() {
      this.tableLoading = true;
      const params = new URLSearchParams();

      params.append("skip", this.patientsTableParams.skip);
      params.append("take", this.patientsTableParams.take);

      this.patientsTableParams.searchQuery &&
        params.append("searchQuery", this.patientsTableParams.searchQuery);

      this.medicationFilter.value &&
        params.append(this.medicationFilter.value, true);

      if (this.healthMetricsFilter.value !== null) {
        params.append(
          "isNotTrackingHealthMetrics",
          this.healthMetricsFilter.value
        );
      }

      const { patients, metadata } = await this.getData("patients", { params });
      this.metadata = metadata;
      this.patients = patients;
      this.tableLoading = false;
    },

    async search(query) {
      this.patientsTableParams.searchQuery = query;
      await this.fetchPatients();
    },
  },
};
</script>

<style lang="scss" scoped></style>
