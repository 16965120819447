<template>
  <div class="patient-status-icon">
    <div v-if="isActivelyRejectingMedication" class="status status__reject">
      <v-icon color="white" size="medium">mdi-close</v-icon>
    </div>
    <div v-if="isActivelyTakingMedication" class="status status__active">
      <v-icon color="white">mdi-check</v-icon>
    </div>
    <div v-if="isNotTrackingMedication" class="status status__inactive">
      <v-icon color="gray">mdi-circle-outlined</v-icon>
    </div>
    <div v-if="isPossiblyRejectingMedication" class="status status__fallback">
      <v-icon color="red" size="30">mdi-circle-half-full</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isActivelyRejectingMedication: {
      type: Boolean,
      required: true,
    },
    isActivelyTakingMedication: {
      type: Boolean,
      required: true,
    },
    isNotTrackingMedication: {
      type: Boolean,
      required: true,
    },
    isPossiblyRejectingMedication: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &__reject {
    background-color: rgb(248, 91, 91);
  }
  &__inactive {
    background-color: #DCDCDC;
  }

  &__fallback {
    background-color: #DCDCDC;
  }

  &__active {
    background-color: rgb(48, 198, 48);
  }
}
</style>
