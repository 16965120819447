<template>
  <v-form class="reset-password-form" ref="form" @submit.prevent="onSubmit">
    <InputElement
      :rules="[atLeastOneNameRequired]"
      v-model="nurse.firstName"
      label="Jméno"
    ></InputElement>
    <InputElement
      :rules="[atLeastOneNameRequired]"
      v-model="nurse.lastName"
      label="Příjmení"
    ></InputElement>

    <div class="text-center">
      <ButtonElement
        text="Změnit údaje"
        color="primary"
        type="submit"
        :loading="loading"
      />
    </div>
  </v-form>
</template>

<script>
import ButtonElement from "../elements/ButtonElement.vue";
import validation from "../../use/validations";
import useApiCall from "../../use/apiCall";
import InputElement from "../elements/InputElement.vue";

export default {
  components: { ButtonElement, InputElement },
  setup() {
    const { putData } = useApiCall();
    return { putData };
  },
  data() {
    return {
      nurse: {
        firstName: null,
        lastName: null,
      },
      loading: false,
      rules: validation,
    };
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  computed: {
    atLeastOneNameRequired() {
      return this.rules.atLeastOneNameRequired(
        this.nurse.firstName,
        this.nurse.lastName
      );
    },
  },

  methods: {
    async onSubmit() {
      const userId = this.$store.getters.getUser.id;

      try {
        this.loading = true;
        const nurse = this.nurse;
        const response = await this.putData(
          `/nurses/${userId}`,
          {
            nurse: {
              firstName: nurse.firstName
                ? nurse.firstName
                : this.user.firstName,
              lastName: nurse.lastName ? nurse.lastName : this.user.lastName,
              maxPatientsCount: null,
              nurseBatchNumber: null,
              nurseBatchNumberMedicineId: null,
            },
          },
          null,
          true
        );

        if (response.user) {
          this.$store.commit("setFormMessages", {
            texts: [
              `Vaše jméno bylo úspěšně změněno na ${response.user.fullName}`,
            ],
            type: "success",
          });

          this.$store.commit("setUserFullName", response.user.fullName);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 100%;
}
</style>
