<template>
  <div class="diabetes-type-one page mb-12">
    <div class="diabetes-type-one__background"></div>
    <img
      width="30"
      src="../assets/img/illustrations/bulp.png"
      class="bulp-illustration"
    />
    <div class="container">
      <SectionHeader
        title="Cukrovka 1. typu"
        text="<p>U cukrovky 1. typu tělo produkuje velmi málo inzulinu nebo vůbec žádný. Nejčastěji je diagnostikována u dětí a mladých dospělých osob.<br>Cukrovka 1. typu s sebou přináší v průběhu života nejrůznější události. Co mohu jíst? Jak vysvětlit dítěti, že je diabetik? Jak zvládat těhotsntví s cukrovkou?</p><p>Pro tyto a další situace jsme pro vás připravili praktické materiály. Každou brožurku si můžete stáhnout do počítače a vytisknout.</p>"
      />
      <div class="card-wrapper">
        <img
          v-if="$vuetify.breakpoint.mdAndUp"
          width="100"
          src="../assets/img/illustrations/human.svg"
          cover
          class="human-illustration"
        />
        <DownloadCardGrid v-if="cards" :cards="cards" />
        <div class="text-center mt-8">
          <ButtonElement
            text="Všechny materiály ke stažení"
            class="mt-4"
            @click="$router.push({ name: 'Downloads' })"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadCardGrid from "../components/DownloadCardGrid.vue";
import SectionHeader from "../components/SectionHeader.vue";
import ButtonElement from "../components/elements/ButtonElement.vue";
import Downloads from "../data/downloads.json";

export default {
  components: { ButtonElement, SectionHeader, DownloadCardGrid },
  computed: {
    cards() {
      const ids = [1, 2, 3, 4, 5, 6, 14];
      let cards = [];
      ids.forEach((id) => {
        Downloads.forEach((download) => {
          if (download.id === id) {
            cards.push(download);
          }
        });
      });
      return cards;
    },
  },
};
</script>

<style lang="scss" scoped>
.diabetes-type-one {
  position: relative;
  &__background {
    background: var(--v-bgLight-base);
    height: 40rem;
    width: 100%;
    position: absolute;
    left: 0;
    top: -5rem;
  }

  .card-wrapper {
    position: relative;

    .human-illustration {
      position: absolute;
      left: -5rem;
      top: -4rem;
    }
  }

  .bulp-illustration {
    position: absolute;
    left: 50%;
    top: -3rem;
    transform: translate(-50%);
  }
}
</style>
