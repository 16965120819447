<template>
  <vue-pdf-app :pdf="source"></vue-pdf-app>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

export default {
  components: {
    VuePdfApp,
  },

  data() {
    return {
      source: "../../files/pdfs/muj-pokrok.pdf",
    };
  },
};
</script>

<style lang="scss" scoped></style>
