<template>
  <div class="data-table">
    <v-data-table
      :loading="loading"
      ref="dataTable"
      no-data-text="Žádná data k zobrazení"
      v-bind="$attrs"
      :items="items"
      :headers="headers"
      :show-expand="showExpand"
      :item-class="itemClass"
      hide-default-footer
      :items-per-page="-1"
      @update:sort-by="$emit('sortBy', $event)"
      @update:sort-desc="$emit('sortDesc', $event)"
    >
      <!-- pass through scoped slots -->
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        v-slot:[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>

      <!-- pass through normal slots -->
      <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
        <slot :name="slotName" />
      </template>

      <template v-slot:footer>
        <div class="data-table-footer d-flex align-center justify-end pa-4">
          <div class="mr-2"><small> Počet položek na stránku </small></div>
          <div class="mr-4">
            <v-select
              hide-details
              v-model="footerOptions.itemsPerPage"
              :items="itemsPerPageOptions"
              item-text="name"
              item-value="value"
              class="pt-0 mt-0 item-per-page-select"
              @change="itemPerPageChange"
            />
          </div>
          <div v-if="!loading" class="mr-4">
            <small> celkem položek {{ metadata.totalRecords }}</small>
          </div>
          <div v-if="!loading">
            <v-btn icon :disabled="disabledLeft" @click="onPrev">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <small>
              stránka {{ `${footerOptions.page + 1} z ${pageCount}` }}</small
            >

            <v-btn icon :disabled="disabledRight" @click="onNext">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    btnText: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    metadata: {
      type: Object,
      required: true,
    },
    itemClass: {
      type: [String, Function],
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    footerOptions: {
      type: Object,
      default: () => ({
        itemsPerPage: 10,
        page: 0,
      }),
    },
  },

  mounted() {
    this.$emit("updateFooterOptions", this.footerOptions);
  },

  data() {
    return {
      itemsPerPageOptions: [
        {
          name: 5,
          value: 5,
        },
        {
          name: 10,
          value: 10,
        },
        {
          name: 20,
          value: 20,
        },
        {
          name: 100,
          value: 100,
        },
      ],
    };
  },

  computed: {
    pageCount() {
      return Math.ceil(
        this.metadata.totalRecords / this.footerOptions.itemsPerPage
      );
    },
    disabledLeft() {
      return this.footerOptions.page === 0;
    },
    disabledRight() {
      return (
        this.footerOptions.page + 1 === this.pageCount || this.pageCount === 0
      );
    },
  },

  methods: {
    itemPerPageChange() {
      this.footerOptions.page = 0;
      this.$emit("updateFooterOptions", this.footerOptions);
    },
    onNext() {
      this.footerOptions.page = this.footerOptions.page + 1;
      this.$emit("updateFooterOptions", this.footerOptions);
    },
    onPrev() {
      this.footerOptions.page = this.footerOptions.page - 1;
      this.$emit("updateFooterOptions", this.footerOptions);
    },
  },
};
</script>

<style lang="scss" scoped>
.data-table {
  ::v-deep .v-data-table__expanded__content {
    box-shadow: none !important;
    background: #0638a409;
  }

  .item-per-page-select {
    max-width: 4rem;
    font-size: 0.85rem;
  }
}

.data-table-footer {
  border-top: 1px solid gainsboro;
}
</style>
