<template>
  <div class="user-setting page mb-12">
    <div class="container">
      <SectionHeader title="Nastavení účtu" />
      <div v-if="isPatient" class="card mb-4">
        <UserProfile v-if="isPatient"></UserProfile>
      </div>
      <div v-if="isPatient" class="card mb-4">
        <UserSettingForm />
      </div>

      <div v-if="isNurse" class="card mb-4">
        <div class="primary--text mb-4 text-h5">Změna údajů</div>
        <ChangeUserDataForm :user="user"></ChangeUserDataForm>
      </div>

      <div class="card">
        <div class="primary--text mb-4 text-h5">Nastavení nového hesla</div>
        <SetNewPasswordForm></SetNewPasswordForm>
      </div>
    </div>
  </div>
</template>

<script>
import SetNewPasswordForm from "../components/forms/SetNewPasswordForm.vue";
import ChangeUserDataForm from "../components/forms/ChangeUserDataForm.vue";
import UserSettingForm from "../components/forms/UserSettingForm.vue";
import SectionHeader from "../components/SectionHeader.vue";
import UserProfile from "../components/UserProfile.vue";
import useApiCall from "../use/apiCall";

export default {
  setup() {
    const { getData } = useApiCall();
    return { getData };
  },
  components: {
    SectionHeader,
    UserSettingForm,
    UserProfile,
    SetNewPasswordForm,
    ChangeUserDataForm,
  },

  data() {
    return {
      user: {},
    };
  },

  computed: {
    isAdmin() {
      return this.$store.getters.getUserRole === "admin";
    },
    isNurse() {
      return this.$store.getters.getUserRole === "nurse";
    },
    isPatient() {
      return this.$store.getters.getUserRole === "patient";
    },
  },

  async mounted() {
    if (this.isNurse) {
      const { user } = await this.getData(
        `/users/${this.$store.getters.getUser.id}`
      );
      this.user = user;
    }
  },
};
</script>

<style langs="scss" scoped>
.card {
  max-width: 35rem;
  margin: auto;
  padding: 2rem;

  background-color: var(--v-secondary-base);
}
</style>
