<template>
  <div class="export-patient-data-form">
    <div>Zvolte údaje, které chcete zahrnout do exportu:</div>
    <div v-for="(section, i) in sections" :key="i">
      <v-checkbox
        :label="section.label"
        v-model="section.value"
        hide-details
      ></v-checkbox>
    </div>

    <div class="mt-4">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div>
            <div>
              <small class="grey--text">Data za měsíc:</small>
            </div>
            <v-text-field
              outlined
              v-model="date"
              placeholder="Vyberte měsíc"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </div>
        </template>
        <v-date-picker
          locale="cs-CZ"
          v-model="date"
          :active-picker.sync="activePicker"
          type="month"
          @change="save"
        ></v-date-picker>
      </v-menu>
    </div>

    <div class="mt-2">
      <ButtonElement
        block
        :loading="generateLoading"
        text="Stáhnout"
        @click="handleDownloadReport"
      ></ButtonElement>
    </div>
  </div>
</template>

<script>
import ButtonElement from "../elements/ButtonElement.vue";
import useApi from "@/use/apiCall";
import useFormatDate from "../../use/formatDate";

export default {
  components: {
    ButtonElement,
  },

  setup() {
    const { getData } = useApi();
    const { getCzechDate } = useFormatDate();

    return {
      getData,
      getCzechDate,
    };
  },

  data() {
    return {
      generateLoading: false,
      sections: [
        { label: "Léčba Rybelsusem", value: true },
        { label: "Hmotnost", value: true },
        { label: "Kroky", value: true },
        { label: "Glykovaný hemoglobin", value: true },
      ],
    };
  },
  methods: {
    async handleDownloadReport() {
      this.generateLoading = true;

      try {
        const patientId = Number(this.$route.params.id);

        // Fetch the binary data
        const response = await this.getData(
          `exports/patient-report?patientId=${patientId}&relativeUrl=/pdf-report/${patientId}`,
          { responseType: "arraybuffer" } // Ensure response is in binary format
        );

        // Convert the binary data to a Blob
        const blob = new Blob([response], { type: "application/pdf" });

        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = `Patient_Report_${patientId}.pdf`;
        document.body.appendChild(link);
        link.click();

        // Clean up the temporary URL
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the report:", error);
      } finally {
        this.generateLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
