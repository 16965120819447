<template>
  <div>
    <v-row justify="center">
      <v-col md="4" cols="12" class="pa-0">
        <UserTypeSwitch class="py-8" />
        <div class="primary nurse-login-form-wrapper pa-12">
          <div class="white--text">
            <h1 class="white--text mb-8 text-center">
              Přihlášení zdravotní sestry
            </h1>
            <NurseLoginForm />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserTypeSwitch from "../../components/elements/UserTypeSwitch.vue";
import NurseLoginForm from "../../components/forms/NurseLoginForm.vue";

export default {
  components: { NurseLoginForm, UserTypeSwitch },
};
</script>
