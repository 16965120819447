<template>
    <div class="case-card">
        <div class="d-flex align-center flex-column flex-md-row">
            <div>
                <h1 class="mb-4">Samolepka na inzulinové pero</h1>
                <p class="mb-12 primary--text">
                    Samolepka na inzulinové pero slouží k odlišení vašeho inzulinového pera od ostatních nebo jako vhodný
                    designový doplněk.
                    Samolepka je navržena pro chytré inzulinové pero NovoPen 6 nebo NovoPen EchoPlus.
                    Vybrat si můžete z 8 různých grafických motivů.
                </p>
                <ButtonElement text="Vybrat samolepku" @click="$router.push('/samolepky')" />
            </div>

            <v-img class="mt-8 mt-md-0" contain width="100%" max-width="600"
                src="https://novocare.b-cdn.net/samolepky/dohromady-removebg-preview.png"></v-img>
        </div>
    </div>
</template>
  
<script>
import ButtonElement from "./elements/ButtonElement.vue";
export default {
    components: { ButtonElement },
    methods: {
        order(code) {
            const products = this.$store.getters.getProducts;

            const index = products.findIndex((el) => {
                return el.code === code;
            });
            this.$store.commit("setIncreaseProduct", index);
        },
    },
};
</script>
  
<style lang="scss" scoped>
.case-card {
    background-color: var(--v-secondary-base);
    border-radius: 1rem;
    padding: 2rem;
}
</style>