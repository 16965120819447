<template>
  <div class="diabetes-type-one page primary--text">
    <div class="diabetes-type-one__background"></div>
    <img
      width="30"
      src="../assets/img/illustrations/bulp.png"
      class="bulp-illustration"
    />
    <div class="container">
      <SectionHeader
        title="Chytrá inzulinová pera NovoPen<sup>®</sup> 6 & NovoPen Echo<sup>®</sup> Plus "
      />
      <div>
        <p>
          NovoPen<sup>®</sup> 6 a NovoPen Echo<sup>®</sup> Plus jsou nová chytrá
          inzulinová pera, která automaticky zaznamenávají informace o užitých
          dávkách inzulinu pokaždé, když si ho aplikujete. Vedle toho disponují
          nová chytrá pera displejem, který zobrazuje přesné informace o vaší
          poslední aplikované dávce inzulinu. Na displeji pera se zobrazuje
          počet jednotek v poslední podané dávce a také čas, který uplynul od
          poslední aplikace. Od teď již nemusíte přemýšlet, zda, kolik a kdy
          jste si naposledy inzulin aplikovali.
        </p>
        <v-img
          class="mb-8"
          src="https://novocare.b-cdn.net/chytra%20pera/Barevné%20variace-min.PNG"
        ></v-img>
        <p>Specifikace NovoPen<sup>®</sup> 6 & NovoPen Echo<sup>®</sup> Plus</p>
        <ul class="mb-8">
          <li>Automaticky zaznamenává posledních 800 aplikací.</li>
          <li>
            Bezdrátový přenos údajů pacienta technologií Near Field
            Communication.
          </li>
          <li>
            Display zobrazuje velikost poslední dávky a čas od poslední
            aplikace.
          </li>
          <li>Snadné použití, snadné odečty údajů.</li>
          <li>Není třeba měnit ani dobíjet baterii.</li>
          <li>Životnost 4 až 5 let.</li>
          <li>
            Kompatibilní se zásobními vložkami Penfill<sup>®</sup> 3 ml od
            společnosti Novo Nordisk.
          </li>
        </ul>

        <p>
          Jakým způsobem lze stáhnout informace o aplikovaných dávkách inzulinu?
        </p>

        <p>
          Informace o dávkách inzulinu můžete přenést do své oblíbené
          kompatibilní aplikace a sledovat je společně s údaji o hodnotě hladiny
          cukru v krvi (z&nbsp;glukometru nebo senzoru). Zjistíte tak, jak
          jednotlivé aplikace inzulinu ovlivňují vaši hladinu cukru v krvi.
        </p>
        <p>
          Pokud máte k dispozici chytrý telefon*, tablet nebo jiné zařízení,
          které podporuje NFC (bezdrátovou komunikaci na krátkou vzdálenost),
          můžete informace o jednotlivých aplikacích inzulinu jednoduše přenášet
          ze svého chytrého pera do příslušného zařízení.
        </p>
        <p>
          Stačí si stáhnout kompatibilní aplikaci, která bude schopna načíst a
          zobrazit vaše údaje.
        </p>
        <h2 class="primary--text">
          Chytrá inzulinová pera NovoPen<sup>®</sup> 6 a NovoPen Echo<sup
            >®</sup
          >
          Plus spolupracují s následujícími aplikacemi
        </h2>
        <v-container class="pa-0 my-8">
          <v-row>
            <v-col cols="12" md="4">
              <div class="card">
                <v-img
                  class="ma-auto"
                  max-width="150"
                  height="250"
                  contain
                  src="https://novocare.b-cdn.net/chytra%20pera/LOWRES~1.PNG"
                ></v-img>
                <div>
                  <div class="text-h6 mb-4">MySugr</div>
                  <p>
                    Aplikace umožňuje zobrazovat data z&nbsp;glukometru
                    Accu-Chek<sup>®</sup> Instant spolu s daty z chytrého
                    inzulinového pera. Chytré inzulinové pero NovoPen<sup
                      >®</sup
                    >
                    6 nebo NovoPen Echo<sup>®</sup> Plus lze načíst pouze v
                    aplikaci pro operační systém iOS.
                  </p>
                  <p>
                    Více informací o mobilní aplikaci naleznete
                    <a
                      href="https://www.accu-chek.cz/cz/microsites/mySugr/index.html"
                      target="_blank"
                      >zde</a
                    >.
                  </p>
                  <div class="d-flex justify-space-between download-imgs">
                    <a
                      href="https://apps.apple.com/cz/app/mysugr-diabetes-tracker-log/id516509211?l=cs"
                      target="_blank"
                    >
                      <v-img
                        class="mr-4"
                        max-width="120"
                        src="https://novocare.b-cdn.net/chytra%20pera/Download_tlačítko_apple-removebg-preview%20(1).png"
                      ></v-img>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.mysugr.android.companion"
                      target="_blank"
                    >
                      <v-img
                        max-width="120"
                        src="https://novocare.b-cdn.net/chytra%20pera/Download_tlačítko_google-removebg-preview.png"
                      ></v-img>
                    </a>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="card">
                <v-img
                  class="ma-auto"
                  max-width="200"
                  height="250"
                  contain
                  src="https://novocare.b-cdn.net/chytra%20pera/diasend_GothamLogo_BLK.png"
                ></v-img>
                <div>
                  <div class="text-h6 mb-4">Diasend<sup>®</sup></div>
                  <p>
                    Aplikace umožňuje zobrazovat údaje o&nbsp;dávkách inzulinu z
                    chytrého inzulinového pera spolu s daty ze CGM a glukometrů.
                  </p>
                  <p>
                    Více informací o propojení s chytrými pery v aplikaci
                    Diasend<sup>®</sup> naleznete
                    <a
                      href="https://support.diasend.com/hc/en-us/sections/360011527694-How-to-use-insulin-pens-with-diasend"
                      target="_blank"
                      >zde</a
                    >.
                  </p>
                  <div class="d-flex justify-space-between download-imgs">
                    <a
                      href=" https://apps.apple.com/cz/app/diasend/id961929984?l=cs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <v-img
                        class="mr-4"
                        max-width="120"
                        src="https://novocare.b-cdn.net/chytra%20pera/Download_tlačítko_apple-removebg-preview%20(1).png"
                      ></v-img>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.diasend.diasend"
                      target="_blank"
                    >
                      <v-img
                        max-width="120"
                        src="https://novocare.b-cdn.net/chytra%20pera/Download_tlačítko_google-removebg-preview.png"
                      ></v-img
                    ></a>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="card">
                <v-img
                  max-width="200"
                  height="250"
                  class="ma-auto"
                  contain
                  src="https://novocare.b-cdn.net/chytra%20pera/Logo%20FSLL%20Primary%20Vertical.png"
                ></v-img>
                <div class="text-h6 mb-4">FreeStyle LibreLink<sup>®</sup></div>
                <p>
                  Aplikace pro zobrazení dat ze senzorů FreeStyle Libre spolu s
                  daty o dávkách inzulinu z chytrých inzulinových per.
                </p>
                <div class="d-flex justify-space-between download-imgs">
                  <a
                    href="https://apps.apple.com/cz/app/freestyle-librelink-cz/id1620058766?l=cs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <v-img
                      class="mr-4 mb-4 mb-md-0"
                      max-width="120"
                      src="https://novocare.b-cdn.net/chytra%20pera/Download_tlačítko_apple-removebg-preview%20(1).png"
                    ></v-img>
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.freestylelibre.app.cz"
                    target="_blank"
                  >
                    <v-img
                      max-width="120"
                      src="https://novocare.b-cdn.net/chytra%20pera/Download_tlačítko_google-removebg-preview.png"
                    ></v-img>
                  </a>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <h2 class="primary--text mb-4">
          Návod pro přetažení dat z chytrého pera do příslušného zařízení
        </h2>
        <p>
          Přidržte display pera přímo u označení NFC na příslušném elektronickém
          zařízení.
        </p>
        <p>
          Chytrá inzulinová pera jsou kompatibilní s telefony s operačními
          systémy Android a iOS. Lokalita NFC přenosového snímače se liší dle
          typu operačního systému a telefonu.
        </p>
      </div>
      <div class="card-wrapper">
        <img
          width="100"
          src="../assets/img/illustrations/human.svg"
          cover
          class="human-illustration"
          v-if="$vuetify.breakpoint.mdAndUp"
        />
        <DownloadCardGrid v-if="cards" :cards="cards" />
        <div class="text-center mt-8">
          <ButtonElement
            text="Všechny materiály ke stažení"
            class="mt-4"
            @click="$router.push({ name: 'Downloads' })"
          />
        </div>
        <small class="primary--text d-block my-8">
          Aplikátory inzulinu NovoPen<sup>®</sup> 6 a NovoPen Echo<sup>®</sup>
          Plus jsou zdravotnické prostředky. Čtěte pečlivě návod k použití a
          informace o bezpečném používání. NovoPen<sup>®</sup> 6 a NovoPen
          Echo<sup>®</sup> Plus jsou ochranné registrované známky společnosti
          Novo Nordisk A/S.
        </small>

        <div class="mb-4">
          <a
            href="https://www.novonordisk.com/content/dam/nncorp/global/en/our-products/pdf/instructions-for-use/novopen-6/Novopen6-CS.pdf"
            target="_blank"
            rel="noopener noreferrer"
            >Návod k použití NovoPen<sup>®</sup> 6</a
          >
        </div>
        <div class="mb-12">
          <a
            href="https://www.novonordisk.com/content/dam/nncorp/global/en/our-products/pdf/instructions-for-use/novopen-echo-plus/Novopen-echo-plus-CS.pdf"
            target="_blank"
            rel="noopener noreferrer"
            >Návod k použití NovoPen Echo<sup>®</sup> Plus</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadCardGrid from "../components/DownloadCardGrid.vue";
import SectionHeader from "../components/SectionHeader.vue";
import ButtonElement from "../components/elements/ButtonElement.vue";

export default {
  components: { ButtonElement, SectionHeader, DownloadCardGrid },
  data() {
    return {
      cards: [
        {
          title:
            "NovoPen<sup>®</sup> 6 a NovoPen Echo<sup>®</sup> Plus: Základní informace",
          img: "https://novocare.b-cdn.net/novopen/uzivatelska-brozura.png",
          download:
            "https://novocare.b-cdn.net/novopen/Novo%20Nordisk%202022-05%20broz%CC%8Cura%20A5%20na%20lezato%20S_08%20.pdf",
        },
        {
          title: "NovoPen<sup>®</sup> 6 a Diasend<sup>®</sup> přenos dat",
          img: "https://novocare.b-cdn.net/novopen/NovoPen%206%20a%20Diasend%20materiál-min%201.png",
          download:
            "https://novocare.b-cdn.net/novopen/Novo%20Nordisk%202022-07%20NP6%20A5%20skladacka%20S_08.pdf",
        },
        {
          title:
            "Návod na propojení s aplikací FreeStyle LibreLink<sup>®</sup>",
          img: "https://novocare.b-cdn.net/novopen/navod-plus.png",
          download:
            "https://novocare.b-cdn.net/Downloads/Freestyle%20navod.pdf",
        },
        {
          title: "Návod na propojení s aplikací MySugr",
          img: "https://novocare.b-cdn.net/novopen/navod-plus.png",
          download:
            "https://novocare.b-cdn.net/novopen/NP6%20a%20MySugr%20návod.pdf",
        },
        {
          title: "Jak propojím chytré inzulinové pero s mým mobilním telefonem",
          img: "https://novocare.b-cdn.net/Downloads/Supporting_NPE_small.png",
          download:
            "https://novocare.b-cdn.net/Downloads/NFC%20Fact%20sheet.pdf",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.diabetes-type-one {
  position: relative;
  &__background {
    background: var(--v-bgLight-base);
    height: 40rem;
    width: 100%;
    position: absolute;
    left: 0;
    top: -5rem;
  }

  .card-wrapper {
    position: relative;
    margin-top: 2rem;
    .human-illustration {
      position: absolute;
      left: -5rem;
      top: -4rem;
    }
  }

  .bulp-illustration {
    position: absolute;
    left: 50%;
    top: -3rem;
    transform: translate(-50%);
  }

  .card {
    position: relative;
    background: #dee7f7;
    color: var(--v-primary-base);

    padding: 2rem;
    padding-bottom: 4rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .download-imgs {
    position: absolute;
    bottom: 1rem;
  }
}

@media screen and (min-width: 960px) {
  .diabetes-type-one {
    .card {
      min-height: 40rem;
      padding-bottom: 0;
    }

    .card-wrapper {
      margin-top: 8rem;
    }
  }
}
</style>
