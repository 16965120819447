<template>
  <div class="reset-password mb-12">
    <div class="reset-password__card">
      <h1 class="white--text text-center mb-4">Nastavte si nové heslo</h1>
      <div class="divider"></div>
      <ResetPasswordForm />
    </div>
    <!-- <PageInformationText
      @showPageInformationText="showPageInformationText = true"
    /> -->
  </div>
</template>

<script>
import ResetPasswordForm from "../components/forms/ResetPasswordForm.vue";

export default {
  components: { ResetPasswordForm },

  data() {
    return {
      showPageInformationText: false,
    };
  },

  mounted() {
    this.logOut();
  },

  methods: {
    logOut() {
      this.$store.commit("setUserInfo", null);
      this.$store.commit("setBatchNumbers", null);
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password {
  &__card {
    position: relative;
    background: var(--v-primary-base);
    padding: 4rem;
    max-width: 35rem;
    margin: auto;
    margin-top: 5rem;
  }

  .divider {
    border: 2px solid var(--v-accent-base);
    margin: 1rem auto 2rem;
    max-width: 10rem;
  }
}
</style>
