<template>
  <div class="registration">
    <div class="registration__background"></div>
    <div class="registration__card">
      <h1 class="white--text text-center">Registrace</h1>
      <div class="divider"></div>
      <RegistrationForm />
    </div>
  </div>
</template>

<script>
import RegistrationForm from "../components/forms/RegistrationForm.vue";
export default {
  components: { RegistrationForm },
};
</script>

<style lang="scss" scoped>
.registration {
  &__background {
    background: var(--v-secondary-base);
    width: 100%;
    height: 20rem;
    position: absolute;
    top: 0;
  }

  &__card {
    position: relative;
    background: var(--v-primary-base);
    padding: 4rem;
    max-width: 35rem;
    margin: auto;
    margin-top: 5rem;
    margin-bottom: 2rem;
  }

  .divider {
    border: 2px solid var(--v-accent-base);
    margin: 1rem auto 2rem;
    max-width: 10rem;
  }
}
</style>