<template>
  <div v-if="order" class="order-detail container mb-12">
    <SectionHeader
      :title="'Objednávka'"
      :text="`ID objednávky ${order.hashId}`"
    />
    <table class="order-table">
      <tr>
        <td>Jméno a přijmení</td>
        <td>
          {{ order.billingInformation.fullName }}
        </td>
      </tr>
      <tr>
        <td>Telefonní číslo</td>
        <td>
          {{ order.billingInformation.number }}
        </td>
      </tr>
      <tr>
        <td>Email</td>
        <td>
          {{ order.buyer.email }}
        </td>
      </tr>
      <tr>
        <td>Adresa</td>
        <td>
          <div>{{ order.billingInformation.address.street }}</div>
          <div>{{ order.billingInformation.address.city }}</div>
          <div>{{ order.billingInformation.address.zipCode }}</div>
        </td>
      </tr>
      <tr>
        <td>Datum objednání</td>
        <td>
          {{ getCzechDate(order.orderedAt) }}
        </td>
      </tr>
      <tr>
        <td>Produkty</td>
        <td>
          <div v-for="(item, i) in order.items" :key="i">
            {{ item.amount }} x <span v-html="item.product.displayName"></span>
          </div>
        </td>
      </tr>
      <tr>
        <td>Poslední změna provedena</td>
        <td>
          {{ getCzechDate(order.statusLastSetAt) }}
        </td>
      </tr>
      <tr>
        <td>Stav</td>
        <td>
          <div class="d-flex align-center">
            <div>
              <v-icon :color="orderStatus.iconColor" class="mr-2">{{
                orderStatus.icon
              }}</v-icon>
              {{ orderStatus.name }}
            </div>
            <div class="ml-4">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="userRole === 'admin'"
                    v-on="on"
                    color="primary"
                    icon
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  >
                </template>
                <v-list nav dense>
                  <v-list-item-group v-model="selectedStatus" color="primary">
                    <v-list-item
                      v-for="(statusOption, i) in statusOptions"
                      :key="i"
                    >
                      <v-list-item-title>{{
                        statusOption.name
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import SectionHeader from "../components/SectionHeader.vue";
import useApiCall from "../use/apiCall";
import useFormatDate from "../use/formatDate";

export default {
  components: { SectionHeader },
  setup() {
    const { getData, putData } = useApiCall();
    const { getCzechDate } = useFormatDate();
    return { putData, getData, getCzechDate };
  },
  computed: {
    userRole() {
      return this.$store.getters.getUserRole;
    },
  },
  data() {
    return {
      order: null,
      orderStatus: {
        name: null,
        icon: null,
      },
      selectedStatus: null,
      statusOptions: [
        {
          name: "Nová objednávka",
        },
        {
          name: "Připravena k odeslání",
        },
        {
          name: "V přepravě",
        },
        {
          name: "Doručená",
        },
        {
          name: "Zrušená",
        },
      ],
    };
  },
  async mounted() {
    await this.fetchOrders();
  },
  watch: {
    async selectedStatus() {
      switch (this.selectedStatus) {
        case 0:
          if (this.order.status === "new") {
            return;
          }
          this.orderStatus.name = "Nová objednávka";
          this.orderStatus.icon = "mdi-package-variant";
          this.orderStatus.iconColor = "blue";
          this.setOrderStatus("new");
          break;
        case 1:
          if (this.order.status === "readyForTransit") {
            return;
          }
          this.orderStatus.name = "Připravena k odeslání";
          this.orderStatus.icon = "mdi-package-variant-closed";
          this.orderStatus.iconColor = "grey";
          this.setOrderStatus("readyForTransit");
          break;
        case 2:
          if (this.order.status === "inTransit") {
            return;
          }
          this.orderStatus.name = "V přepravě";
          this.orderStatus.icon = "mdi-truck-delivery-outline";
          this.orderStatus.iconColor = "orange";
          this.setOrderStatus("inTransit");
          break;
        case 3:
          if (this.order.status === "completed") {
            return;
          }
          this.orderStatus.name = "Doručená";
          this.orderStatus.icon = "mdi-package-variant-closed-check";
          this.orderStatus.iconColor = "green";
          this.setOrderStatus("completed");
          break;
        case 4:
          if (this.order.status === "canceled") {
            return;
          }
          this.orderStatus.name = "Zrušená";
          this.orderStatus.icon = "mdi-package-variant-remove";
          this.orderStatus.iconColor = "red";
          this.setOrderStatus("canceled");
          break;

        default:
          break;
      }
    },
  },
  methods: {
    async fetchOrders() {
      const hashId = this.$route.params.id;
      ({ order: this.order } = await this.getData(`orders/${hashId}`));
      this.getOrderStatus();
    },
    async setOrderStatus(status) {
      const hashId = this.$route.params.id;
      this.putData("orders/status", {
        order: {
          orderHashId: hashId,
          status: status,
        },
      });
      this.order.status = status;
    },
    getOrderStatus() {
      switch (this.order.status) {
        case "new":
          this.orderStatus.name = "Nová objednávka";
          this.orderStatus.icon = "mdi-package-variant";
          this.orderStatus.iconColor = "blue";
          break;
        case "readyForTransit":
          this.orderStatus.name = "Připravena k odeslání";
          this.orderStatus.icon = "mdi-package-variant-closed";
          this.orderStatus.iconColor = "grey";
          break;
        case "inTransit":
          this.orderStatus.name = "V přepravě";
          this.orderStatus.icon = "mdi-truck-delivery-outline";
          this.orderStatus.iconColor = "orange";
          break;
        case "completed":
          this.orderStatus.name = "Doručená";
          this.orderStatus.icon = "mdi-package-variant-closed-check";
          this.orderStatus.iconColor = "green";
          break;
        case "canceled":
          this.orderStatus.name = "Zrušená";
          this.orderStatus.icon = "mdi-package-variant-remove";
          this.orderStatus.iconColor = "red";
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order-table {
  border-collapse: collapse;

  margin: auto;

  td {
    border: 1px solid gainsboro;
    padding: 1rem;
  }
}
</style>
