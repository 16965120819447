import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#092b69',
        secondary: '#d6e7f3',
        accent: '#00b5ff',
        error: '#ff7878',
        bgLight: '#f5faff;',
      },
    },
  },
});
