<template>
  <div class="nurses-homepage">
    <v-container>
      <div
        v-if="$store.getters.getUser.role === 'nurse'"
        class="d-flex align-center mt-4 mb-12 justify-space-between"
      >
        <div class="d-flex">
          <div class="pa-4 mr-4 rounded-xl nurse-icon">
            <v-icon color="primary" size="60">mdi-doctor</v-icon>
          </div>
          <div>
            <small>Zdravotní sestra:</small>
            <div class="text-h5 primary--text">
              {{ $store.getters.getUserFullName }}
            </div>
          </div>
        </div>

        <div
          v-if="$store.getters.getUser.nurseBatchNumber"
          class="ml-12 code-card rounded-lg primary--text"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                Vaše šarže pro registrování pacientů:
                <v-icon color="primary" size="20"
                  >mdi-information-outline</v-icon
                >
              </span>
            </template>
            <span
              >Tato šarže slouží pro vstup pacientů/jejich registraci do mobilní
              aplikace NovoCare. Pacientovi tuto šarži předejte, aby se mohl
              registrovat.
            </span>
          </v-tooltip>

          <div class="d-flex mt-2">
            <div class="text-h5 font-weight-bold">
              {{ $store.getters.getUser.nurseBatchNumber }}
            </div>
            <sup> </sup>
          </div>
        </div>
      </div>
      <PatientsTable
        ref="patientsTable"
        class="mb-4"
        @patientRequestSent="fetchPatientRequests"
      />

      <div class="mb-12">
        <small class="mb-4 d-block grey--text">Legenda:</small>
        <div class="d-flex" v-for="(l, i) in legend" :key="i">
          <PatientStatusIcon
            :is-actively-taking-medication="l.isActivelyTakingMedication"
            :is-actively-rejecting-medication="l.isActivelyRejectingMedication"
            :is-not-tracking-medication="l.isNotTrackingMedication"
            :is-possibly-rejecting-medication="l.isPossiblyRejectingMedication"
            class="mb-4 mr-4"
          />
          <small>
            {{ l.text }}
          </small>
        </div>
      </div>

      <PatientsRequestsTable class="mb-12" ref="requestsTable" />

      <div class="text-h5 my-8">Historie propojení s pacienty</div>
      <PatientCareTable
        class="mb-12"
        @patientCareCanceled="fetchPatientsTable"
      ></PatientCareTable>
    </v-container>
  </div>
</template>

<script>
import PatientsTable from "@/components/table/PatientsTable.vue";
import PatientsRequestsTable from "@/components/table/PatientsRequestsTable.vue";
import PatientCareTable from "../../components/table/PatientCareTable.vue";
import PatientStatusIcon from "../../components/icons/PatientStatusIcon.vue";

export default {
  components: {
    PatientsTable,
    PatientsRequestsTable,
    PatientCareTable,
    PatientStatusIcon,
  },
  data() {
    return {
      legend: [
        {
          isActivelyTakingMedication: true,
          isActivelyRejectingMedication: false,
          isNotTrackingMedication: false,
          isPossiblyRejectingMedication: false,
          text: "Aktivní - pacient aktivně užívá léčbu v posledních 5 dnech",
        },
        {
          isActivelyTakingMedication: false,
          isActivelyRejectingMedication: true,
          isNotTrackingMedication: false,
          isPossiblyRejectingMedication: false,
          text: "Odmítající - pacient nebere léčbu v posledních 5 dnech",
        },
        {
          isActivelyTakingMedication: false,
          isActivelyRejectingMedication: false,
          isNotTrackingMedication: true,
          isPossiblyRejectingMedication: false,
          text: "Nezaznamenávající - pacient si nezaznamenává léčbu v posledních 5 dnech",
        },
        {
          isActivelyTakingMedication: false,
          isActivelyRejectingMedication: false,
          isNotTrackingMedication: false,
          isPossiblyRejectingMedication: true,
          text: "Nestabilní - pacient nebere a nezaznamenává si léčbu v posledních 5 dnech",
        },
      ],
    };
  },
  methods: {
    fetchPatientRequests() {
      // Call method in PatientsRequestsTable component to fetch patient requests
      this.$refs.requestsTable.fetchRequests();
    },

    fetchPatientsTable() {
      this.$refs.patientsTable.fetchPatients();
    },
  },
};
</script>

<style lang="scss" scoped>
.nurse-icon {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
}

.code-card {
  border: 1px solid gainsboro;
  padding: 1rem;
  background-color: white;
}
</style>
