<template>
  <v-form class="reset-password-form" ref="form" @submit.prevent="onSubmit">
    <div class="mb-4">
      <div class="white--text mb-2">
        Zadejte ověřovací kód, který jsme Vám zaslali na Váš email
      </div>

      <v-otp-input length="4" @change="otpChange" color="primary"></v-otp-input>

      <div v-if="otpError" class="otp-error">Ověřovací kód je povinný</div>
    </div>

    <PasswordInputElement
      :rules="[rules.required]"
      v-model="password"
      label="Nové heslo"
    />
    <PasswordInputElement
      :rules="[rules.required]"
      v-model="passwordAgain"
      label="Heslo znovu"
    ></PasswordInputElement>
    <div class="text-center">
      <ButtonElement
        text="Změnit heslo"
        color="accent"
        type="submit"
        :loading="loading"
      />
    </div>
  </v-form>
</template>

<script>
import ButtonElement from "../elements/ButtonElement.vue";

import PasswordInputElement from "../elements/PasswordInputElement.vue";
import validation from "../../use/validations";
import useApicall from "../../use/apiCall";

export default {
  components: { ButtonElement, PasswordInputElement },
  setup() {
    const { postData } = useApicall();
    return { postData };
  },
  data() {
    return {
      password: null,
      loading: false,
      passwordAgain: null,
      rules: validation,
      recoveryCode: null,
      otpError: false,
    };
  },

  methods: {
    async otpChange(otp) {
      this.recoveryCode = otp;
      if (otp.length === 4) {
        this.otpError = false;
      } else {
        this.otpError = true;
      }
    },

    async onSubmit() {
      this.otpError = false;

      if (this.password !== this.passwordAgain) {
        this.$store.commit("setFormMessages", {
          type: "error",
          texts: ["Hesla se neshodují"],
        });

        return;
      }

      this.loading = true;
      const response = await this.postData(
        "/auth/password",
        {
          newPassword: this.password,
          recoveryCode: this.recoveryCode,
          staySignedIn: true,
        },
        null,
        true
      );

      this.loading = false;

      if (!this.recoveryCode) {
        this.otpError = true;
        return;
      }

      if (response) {
        this.$store.commit("setFormMessages", {
          type: "success",
          texts: ["Vaše heslo bylo změněno"],
        });

        this.$router.push({ name: "Login" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 100%;
}

.otp-error {
  font-size: 12px;
  color: var(--v-error-base);
  padding-left: 10px;
}
</style>
