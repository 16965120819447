<template>
  <div v-if="patient" class="patient-detail mt-12 mb-12">
    <v-container>
      <div class="mb-8">
        <v-row>
          <v-col>
            <div class="mb-4 d-flex align-center">
              <div class="icon-wrapper">
                <v-icon class="mr-2" size="110" color="primary"
                  >mdi-account-circle</v-icon
                >

                <PatientStatusIcon
                  :is-actively-taking-medication="
                    patientActivityStatus.isActivelyTakingMedication
                  "
                  :is-actively-rejecting-medication="
                    patientActivityStatus.isActivelyRejectingMedication
                  "
                  :is-not-tracking-medication="
                    patientActivityStatus.isNotTrackingMedication
                  "
                  :is-possibly-rejecting-medication="
                    patientActivityStatus.isPossiblyRejectingMedication
                  "
                  class="patient-status"
                ></PatientStatusIcon>
              </div>

              <div>
                <div class="primary--text text-h6">
                  {{ patient.fullName }}
                </div>

                <div class="subtitle-2 font-weight-regular">
                  Registrace do aplikace:
                  {{ getCzechDate(patient.mobileRegistrationFinishedAt) }}
                </div>
                <div class="subtitle-2 font-weight-regular">
                  Propojení s Vámi:
                  {{ getCzechDate(patient.patientOfNurseFrom) }}
                </div>
                <div class="subtitle-2 font-weight-regular">
                  Věk: {{ patientAge }} let
                </div>
              </div>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <div class="text-right">
              <v-btn depressed color="primary" @click="showExportDialog = true">
                <v-icon left>mdi-download</v-icon> Stáhnout report
              </v-btn>
            </div>
          </v-col>

          <!-- <v-col md="auto">
            <div>
              <v-btn text color="red" @click="showCancelCareDialog = true">
                <v-icon left>mdi-link-variant-off</v-icon> Zrušit propojení s
                pacientem
              </v-btn>
            </div>
          </v-col> -->
        </v-row>
      </div>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="primary"
        grow
        hide-slider
      >
        <v-tab v-for="(item, i) in items" :key="i">
          {{ item.title }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-for="(item, i) in items" v-model="tab" :key="i">
        <v-tab-item :value="i" class="pa-4">
          <component :is="item.component" :patient="patient"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-container>

    <AppDialog
      hide-actions
      :title="`Opravdu chcete zrušit propojení s pacientem ${patient.fullName}`"
      :showDialog="showCancelCareDialog"
    >
      <CancelCareForm :patient="patient" />
    </AppDialog>

    <AppDialog
      hide-actions
      :title="`Stáhnout údaje o pacientovi ${patient.fullName}`"
      :showDialog="showExportDialog"
      @closeDialog="showExportDialog = false"
    >
      <ExportPatientDataForm :patient="patient" />
    </AppDialog>
  </div>
</template>

<script>
import PatientRybelsusTab from "@/components/tabs/PatientRybelsusTab.vue";
import PatientWeightTab from "@/components/tabs/PatientWeightTab.vue";
import PatientHemoglobinTab from "@/components/tabs/PatientHemoglobinTab.vue";
import PatientStepsTab from "@/components/tabs/PatientStepsTab.vue";
import AppDialog from "@/components/elements/AppDialog.vue";
import useApi from "@/use/apiCall";
import useFormatDate from "../../use/formatDate";
import PatientStatusIcon from "@/components/icons/PatientStatusIcon.vue";
import ExportPatientDataForm from "@/components/forms/ExportPatientDataForm.vue";

export default {
  components: {
    AppDialog,
    PatientRybelsusTab,
    PatientWeightTab,
    PatientStepsTab,
    PatientStatusIcon,
    PatientHemoglobinTab,
    ExportPatientDataForm,
  },
  setup() {
    const { getData } = useApi();
    const { getCzechDate } = useFormatDate();

    return {
      getData,
      getCzechDate,
    };
  },
  async mounted() {
    await this.fetchPatientDetail();
    switch (this.$route.params.tab) {
      case "rybelsus":
        this.tab = 0;
        break;
      case "hmotnost":
        this.tab = 1;
        break;
      case "kroky":
        this.tab = 2;
        break;
      case "hemoglobin":
        this.tab = 3;
        break;
      default:
        this.tab = 0;
    }
  },

  computed: {
    patientActivityStatus() {
      return this.$store.getters.getPatientActivityStatus;
    },
    patientAge() {
      return new Date().getFullYear() - this.patient.birthYear;
    },
  },

  watch: {
    tab() {
      switch (this.tab) {
        case 0:
          this.$router.push({ params: { tab: "rybelsus" } });
          break;
        case 1:
          this.$router.push({ params: { tab: "hmotnost" } });
          break;
        case 2:
          this.$router.push({ params: { tab: "kroky" } });
          break;
        case 3:
          this.$router.push({ params: { tab: "hemoglobin" } });
          break;
        default:
          this.$router.push({ params: { tab: "rybelsus" } });
          break;
      }
    },
  },
  data() {
    return {
      tab: 0,
      patient: null,
      showCancelCareDialog: false,
      generateLoading: false,
      showExportDialog: false,
      items: [
        {
          title: "Rybelsus",
          component: "PatientRybelsusTab",
        },
        {
          title: "Hmotnost",
          component: "PatientWeightTab",
        },
        {
          title: "Kroky",
          component: "PatientStepsTab",
        },
        {
          title: "Glykovaný hemoglobin",
          component: "PatientHemoglobinTab",
        },
      ],
    };
  },

  methods: {
    async fetchPatientDetail() {
      const patientId = Number(this.$route.params.id);
      const { patient } = await this.getData(`patients/${patientId}`);
      this.patient = patient;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tabs-items {
  background-color: white !important;
}

.v-tab--active {
  background-color: white;
  border-radius: 0.5rem 0.5rem 0 0;
}

.icon-wrapper {
  position: relative;
}

.patient-status {
  position: absolute;
  bottom: 1rem;
  right: 1.2rem;
}
</style>
