<template>
  <div class="diabetes-type-two page mb-12">
    <div class="diabetes-type-two__background"></div>
    <img
      width="30"
      src="../assets/img/illustrations/bulp.png"
      class="bulp-illustration"
    />
    <div class="container">
      <SectionHeader
        title="Cukrovka 2. typu"
        text="<p>U cukrovky 2. typu tělo nevytváří dostatek inzulinu nebo tělo na inzulin, který vytvoří nereaguje správně. Cukrovka 2. typu je obvykle diagnostikována u lidí středního nebo pokročilejšího věku. Léčí se pomocí tablet, v případě potřeby i inzulinem nebo jinými injekčně podávanými léky.</p><p>Jak na správný jídelníček, které sportovní aktivity zvolit nebo jak zvládat specifické situace, například těhotenství? Stáhněte si zdarma praktické brožurky, které si můžete následně vytisknout.</p>"
      />
      <div class="card-wrapper">
        <img
          v-if="$vuetify.breakpoint.mdAndUp"
          width="100"
          src="../assets/img/illustrations/human.svg"
          cover
          class="human-illustration"
        />
        <DownloadCardGrid v-if="cards" :cards="cards" />

        <div class="text-center mt-8">
          <ButtonElement
            text="Všechny materiály ke stažení"
            class="mt-4"
            @click="$router.push({ name: 'Downloads' })"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadCardGrid from "../components/DownloadCardGrid.vue";
import SectionHeader from "../components/SectionHeader.vue";
import ButtonElement from "../components/elements/ButtonElement.vue";
import Downloads from "../data/downloads.json";

export default {
  name: "DiabetesTypeTwo",
  components: { ButtonElement, SectionHeader, DownloadCardGrid },
  computed: {
    cards() {
      const ids = [1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17];
      let cards = [];
      ids.forEach((id) => {
        Downloads.forEach((download) => {
          if (download.id === id) {
            cards.push(download);
          }
        });
      });
      return cards;
    },
  },
};
</script>

<style lang="scss" scoped>
.diabetes-type-two {
  position: relative;

  &__background {
    background: var(--v-bgLight-base);
    height: 40rem;
    width: 100%;
    position: absolute;
    left: 0;
    top: -5rem;
  }

  .card-wrapper {
    position: relative;

    .human-illustration {
      position: absolute;
      left: -5rem;
      top: -4rem;
    }
  }

  .bulp-illustration {
    position: absolute;
    left: 50%;
    top: -3rem;
    transform: translate(-50%);
  }
}
</style>
