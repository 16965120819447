<template>
  <div class="stock container">
    <SectionHeader title="Sklad" />
    <div class="container">
      <h2 class="mb-4">Skladové zásoby</h2>
      <DataTable
        v-if="products"
        :items="products"
        :headers="headers"
        :metadata="metadata"
        @updateFooterOptions="updateFooterOptions"
      >
        <template v-slot:item.displayName="{ item }">
          <span v-html="item.displayName"></span>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn icon text class="mr-2" @click="showDiscardDialog(item)"
            ><v-icon>mdi-minus</v-icon></v-btn
          >
          <v-btn icon text @click="showRestockDialog(item)"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </template>
      </DataTable>
      <div class="my-8">
        <h2 class="mb-4">Transakce</h2>
        <TransactionTable ref="transactionTable" />
      </div>
    </div>
    <v-dialog v-model="showDialog" width="500">
      <v-form class="card" v-if="productItem" ref="form">
        <div class="text-h5 mb-4">
          Chcete změnit počet položek produktu:
          <strong v-html="productItem.displayName">?</strong>
        </div>
        <div class="mb-4">
          Počet položek které chcete
          {{
            productItem.action === "restock"
              ? "přidat na sklad"
              : "odebrat ze skladu"
          }}:
        </div>
        <div>
          <InputElement
            :label="'Množství'"
            v-model="amount"
            type="number"
            :rules="[rules.required]"
          />
        </div>
        <div class="text-right">
          <v-btn text class="mr-2" @click="showDialog = false"> Zavřít </v-btn>
          <v-btn color="primary" depressed @click="onSaveButton()">
            {{ productItem.action === "restock" ? "Přidat" : "Odebrat" }}
          </v-btn>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import DataTable from "../components/elements/DataTable.vue";
import InputElement from "../components/elements/InputElement.vue";
import SectionHeader from "../components/SectionHeader.vue";
import validation from "../use/validations";

import useApiCall from "../use/apiCall";
import TransactionTable from "../components/TransactionTable.vue";

export default {
  components: { SectionHeader, DataTable, InputElement, TransactionTable },
  setup() {
    const { getData, postData } = useApiCall();
    return {
      getData,
      postData,
    };
  },
  data() {
    return {
      products: null,
      metadata: null,
      rules: validation,
      productItem: null,
      amount: null,
      showDialog: false,
      skip: 0,
      take: 10,
      headers: [
        {
          text: "Název",
          value: "displayName",
          sortable: false,
        },
        {
          text: "Počet",
          value: "amount",
          sortable: false,
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "right",
        },
      ],
    };
  },
  async mounted() {
    await this.fetchProducts();
  },
  methods: {
    showRestockDialog(item) {
      this.showDialog = true;
      this.productItem = item;
      this.productItem.action = "restock";
    },
    showDiscardDialog(item) {
      this.showDialog = true;
      this.productItem = item;
      this.productItem.action = "discard";
    },
    async updateFooterOptions(event) {
      this.skip = event.page * event.itemsPerPage;
      this.take = event.itemsPerPage;
      await this.fetchProducts();
    },
    async fetchProducts() {
      const params = new URLSearchParams();

      params.append("take", this.take);
      params.append("skip", this.skip);

      const response = await this.getData("/products", {
        params: params,
      });
      this.products = response.products;
      this.metadata = response.metadata;
    },
    async onSaveButton() {
      const type = this.productItem.action;
      const payload = {};
      payload[type] = {
        productId: this.productItem.id,
        amount: this.amount,
      };

      const repsonse = await this.postData(
        `products/${this.productItem.action}`,
        payload,
        null,
        true
      );
      if (!repsonse) {
        return;
      }
      this.showDialog = false;
      await this.fetchProducts();
      this.$store.commit("setFormMessages", {
        texts: ["Počet položek změněn"],
        type: "success",
      });
      this.amount = null;
      this.$refs.form.resetValidation();
      this.$refs.transactionTable.fetchTransactions();
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: white;
  padding: 2rem;
}
</style>
