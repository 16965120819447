<template>
  <div class="nurses-patient-table mt-4">
    <InputSearchQuery @search="search"></InputSearchQuery>
    <DataTable
      v-if="cares"
      :loading="loading"
      :headers="headers"
      :items="cares"
      :metadata="metadata"
      @updateFooterOptions="updateFooterOptions"
    >
      <template #item.from="{ item }">
        {{ getCzechDate(item.from) }}
      </template>
      <template #item.to="{ item }">
        {{ item.to ? getCzechDate(item.to) : "-" }}
      </template>
      <template #item.status="{ item }">
        <v-icon :color="statusMapping[item.status].color" class="mr-2">
          {{ statusMapping[item.status].icon }}
        </v-icon>

        {{ statusMapping[item.status].text }}
      </template>

      <template #item.cancelCare="{ item }">
        <v-btn
          v-if="item.canCancel"
          @click="showCancelCareDialog(item)"
          text
          color="red"
          outlined
        >
          <v-icon left>mdi-link-variant-off</v-icon> Zrušit propojení
        </v-btn>
      </template>
    </DataTable>

    <AppDialog
      hide-actions
      :showDialog="cancelCareDialog"
      title="Zrušit propojení"
      @closeDialog="cancelCareDialog = false"
    >
      <div class="mb-12">
        Opravdu chcete zrušit propojení s pacientem
        {{ selectedCare?.patient?.displayName }}?
      </div>
      <div class="text-right">
        <ButtonElement
          :loading="cancelCareDialogLoading"
          text="Ano zrušit propojení"
          @click="cancelCare"
        ></ButtonElement>
      </div>
    </AppDialog>
  </div>
</template>

<script>
import DataTable from "../elements/DataTable.vue";
import useApiCall from "../../use/apiCall";
import useFormatDate from "../../use/formatDate";
import InputSearchQuery from "../elements/InputSearchQuery.vue";
import AppDialog from "../elements/AppDialog.vue";
import ButtonElement from "../elements/ButtonElement.vue";

export default {
  components: {
    AppDialog,
    DataTable,
    InputSearchQuery,
    ButtonElement,
  },

  setup() {
    const { getData, deleteData } = useApiCall();
    const { getCzechDate } = useFormatDate();

    return {
      getData,
      deleteData,
      getCzechDate,
    };
  },

  data() {
    return {
      cares: [],
      loading: false,
      cancelCareDialog: false,
      cancelCareDialogLoading: false,
      selectedCare: null,
      caresTableParams: {
        skip: 0,
        take: 10,
        searchQuery: null,
      },
      metadata: {
        orderBy: "occurredAtDesc",
      },
      headers: [
        {
          text: "Jméno pacienta",
          value: "patient.displayName",
          sortable: false,
        },
        {
          text: "Email pacienta",
          value: "patient.email",
          sortable: false,
        },
        {
          text: "Datum propojení",
          value: "from",
          sortable: false,
        },
        {
          text: "Datum ukončení propojení",
          value: "to",
          sortable: false,
        },
        {
          text: "Stav propojení",
          value: "status",
          sortable: false,
        },
        {
          text: "",
          value: "cancelCare",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    statusMapping() {
      return {
        active: {
          text: "Aktivní",
          icon: "mdi-link-variant",
          color: "green",
        },
        canceled: {
          text: "Zrušené",
          icon: "mdi-link-variant-off",
          color: "red",
        },
      };
    },
  },

  methods: {
    async fetchCares() {
      this.loading = true;

      const params = new URLSearchParams();

      params.append("skip", this.caresTableParams.skip);
      params.append("take", this.caresTableParams.take);
      params.append("orderBy", "createdAtDesc");

      this.caresTableParams.searchQuery &&
        params.append("searchQuery", this.caresTableParams.searchQuery);

      const { cares, metadata } = await this.getData("cares", {
        params,
      });
      this.cares = cares;
      this.metadata = metadata;
      this.loading = false;
    },

    async cancelCare() {
      this.cancelCareDialogLoading = true;
      await this.deleteData(`cares/${this.selectedCare.id}/cancel`);
      await this.fetchCares();
      this.$emit("patientCareCanceled");
      this.cancelCareDialog = false;
      this.cancelCareDialogLoading = false;
    },

    async updateFooterOptions(event) {
      this.caresTableParams.skip = event.page * event.itemsPerPage;
      this.caresTableParams.take = event.itemsPerPage;
      await this.fetchCares();
    },

    async search(query) {
      this.caresTableParams.searchQuery = query;
      await this.fetchCares();
    },

    async showCancelCareDialog(item) {
      this.cancelCareDialog = true;
      this.selectedCare = item;
    },

    translateStatus(item) {
      switch (item.status) {
        case "pending":
          return "Čeká na potvrzení";
        case "accepted":
          return "Žádost přijata";
        case "rejected":
          return "Žádost zamítnuta";
        default:
          return "-";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
