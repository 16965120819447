<template>
  <div class="guard-limits">
    <div class="container">
      <Human
        v-if="$vuetify.breakpoint.mdAndUp"
        class="human-illustration"
        fill="#092B69"
      />
      <Earth
        v-if="$vuetify.breakpoint.mdAndUp"
        class="earth-illustration"
        fill="#092B69"
      />

      <Microscope
        v-if="$vuetify.breakpoint.mdAndUp"
        class="microscope-illustration"
        fill="#092B69"
      />
      <h1 class="mt-4">
        Vracení doplatků aneb Předepsané léky na diabetes nemusejí být drahé
      </h1>
      <div class="card">
        <p>
          Pro některé pacienty, a dokonce i pro část lékařů je moderní léčba
          cukrovky synonymem léčby nákladné. Ve skutečnosti se pro ni ale více
          hodí pojmy jako šetrná, účinná nebo dobře snášená. Na to, aby náklady
          na předepsané léky nepřesáhly určitou hranici, totiž myslí
          legislativa, čímž šetří i vaši kapsu. A platí to i pro nejmodernější
          antidiabetika. Jednoduše řečeno: část toho, co za léky v lékárně
          zaplatíte, vám zdravotní pojišťovna vrátí.
        </p>

        <h2>Zákon stanovující, komu a kolik může být vyplaceno</h2>
        <p>
          Jedním ze zásadních úředních dokumentů je
          <strong>zákon o veřejném zdravotním pojištění č. 48/1997 Sb.,</strong>
          konkrétně <strong>§ 16b odst. 1,</strong> který
          <strong
            >pro pacienty stanovuje tzv. ochranné roční limity pro doplatky za
            léčiva vystavená na recept</strong
          >
          v případě, že
          <strong>nejsou plně hrazená zdravotní pojišťovnou.</strong>
        </p>
        <p>To se řídí následujícími podmínkami:</p>

        <div class="mb-4">
          <ol>
            <li>Musí se jednat o předepsané léčivé přípravky.</li>
            <li>Jde o léky částečně hrazené ze zdravotního pojištění.</li>
            <li>Léčivo musí být vydáno na území ČR.</li>
          </ol>
        </div>

        <p>
          Roční limit (tzv. doplatkový nebo ochranný limit) je stanoven ve velmi
          přátelské výši
          <strong
            >500 Kč u osob pobírajících invalidního důchod pro invaliditu
            třetího stupně a u osob uznaných jako invalidní ve druhém nebo
            třetím stupni, přestože jim pro nesplnění podmínky doby pojištění
            nebyl přiznán invalidní důchod.</strong
          >
        </p>
        <p>
          <strong>Děti mladší 18 let a senioři nad 65 let věku</strong> mohou
          <strong>doplácet maximálně 1 000 Kč ročně,</strong> a to včetně roku,
          kdy tuto hranici překročili. Pro
          <strong>seniory starší 70 let</strong> se pak hranice doplatku opět
          snižuje na
          <strong
            >500 Kč/rok.U všech ostatních pojištěnců je doplatkový limit</strong
          >
          nastaven na <strong>5 000 Kč ročně.</strong>
        </p>
        <p>
          Díky tomuto limitu může být léčba volena podle pacientových potřeb a v
          souladu s medicínskou odborností. Jde tedy nejen o finanční, ale také
          zdravotní jištění, díky němuž lékař nemusí (a ani nesmí) vyřazovat z
          léčby léky, které mají vyšší doplatek a které pacient potřebuje.
        </p>
        <h2>Jak vzniká doplatek</h2>
        <p>
          Je to velmi jednoduché. Doplatek na částečně hrazené léky je rozdíl
          mezi obchodní cenou léčiva a úhradou, kterou bude lékárna čerpat ze
          zdravotního pojištění pacienta. Je to tedy ta část ceny, kterou
          nehradí pojišťovna.
        </p>
        <h2>Co se do ochranného limitu nepočítá</h2>
        <p>
          Do limitu na doplatky ovšem nepatří automaticky každá koruna, kterou
          zaplatíte v lékárně. U osob do 65 let se do limitu započítávají pouze
          léčiva. Naopak nad tímto věkovým limitem se kromě léčiv započítává i
          řada podpůrných a doplňkových léků. Jejich seznam lze najít v příloze
          k vyhlášce č. 385/2007 Sb. Jedná se například o bylinky, vitamíny a
          minerály, některá projímadla apod.
        </p>
        <p>
          Kromě výše uvedeného se do tohoto limitu nezapočítávají ani regulační
          poplatky za ošetření na pohotovosti.
        </p>

        <h2>Léky bez započitatelného doplatku</h2>
        <p>
          Započitatelný doplatek, tedy částku, která se pacientovi započítává do
          jeho ochranného limitu, nicméně nemá každý částečně hrazený přípravek.
          To platí především pro tzv. inovativní (nové) léky, za který pacient v
          lékárně sice doplatek uhradí, ten se mu ovšem do ochranného limitu
          nezapočítá buď vůbec, nebo pouze částečně. Pokud je ve skupině tzv.
          terapeuticky zaměnitelných léků (tedy léků s obdobnou nebo blízkou
          účinností a bezpečností a obdobným klinickým využitím – zkrátka
          prakticky stejných) přípravek, který pojišťovna hradí, započitatelný
          doplatek bude 0 Kč. Pokud takový přípravek v dané skupině neexistuje,
          započitatelný doplatek se bude rovnat hodnotě nejnižšího doplatku,
          který v dané skupině existuje. A přesně tato výše se bude započítávat
          do ochranného limitu pacienta.
        </p>
        <h2>Vrácení doplatku u moderní léčby cukrovky</h2>
        <p>
          Nárok na vrácení doplatků vychází ze splnění indikačních omezení
          úhrady. Přesná výše vráceného doplatku se pak odvíjí podle toho, zda
          jde o úhradu základní (vrácený doplatek bude vyšší), nebo zvýšenou
          (pacientovi vrácená částka bude nižší).
        </p>
        <p>
          Co se týče moderních léků v terapii diabetu, jako je Rybelsus nebo
          Ozempic, zde nicméně stále platí, že část peněz, kterou za své léky
          zaplatíte v lékárně, vám bude vyplacena zpět.
        </p>
        <h2>Jak a kdy mi pojišťovna přeplatky vrátí</h2>
        <p>
          Limit doplatků ani žádné období nemusíte nijak hlídat. To za vás
          <strong>kvartálně</strong> dělá příslušná pojišťovna.
          <strong
            >O přeplatku vás pojišťovna automaticky informuje obyčejným dopisem
            na adresu uvedenou v registru pojištěnců.</strong
          >
          Sem je také standardně
          <strong>zasílána poštovní poukázka s daným doplatkem.</strong>
          Peníze mohou pojišťovny zasílat také
          <strong
            >na bankovní účet klienta. Je ovšem třeba u vaší pojišťovny nahlásit
            číslo účtu,</strong
          >
          a to prostřednictvím webového formuláře, osobně na jakémkoli
          klientském pracovišti, e-mailem, přes mobilní aplikaci nebo datovou
          schránkou.
        </p>
        <v-alert color="grey lighten-2">
          TIP: Zkontrolujte si, zda má vaše pojišťovna vaši aktuální adresu (pro
          výplatu doplatků formou složenky) a platné číslo bankovního účtu (pro
          bezhotovostní vrácení doplatků).
        </v-alert>
        <p>
          Lhůta pro výplatu peněz ze strany pojišťovny je maximálně 60
          kalendářních dnů po uplynulém kalendářním čtvrtletí.
        </p>
        <v-alert color="grey lighten-2">
          TIP: Výši doplatků lze jednoduše sledovat na vašem online kontu nebo v
          aplikaci vaší zdravotní pojišťovny (pokud ji příslušná pojišťovna má)
          – například u VZP (Moje VZP) nebo OZP (Vitakarta).
        </v-alert>
        <p>
          Případně si lze v lékárně vždy nechat vydat paragon a doplatky si
          počítat.
        </p>
        <h2>Doplatky se vám vrátí</h2>
        <p>
          Jednou z hlavních obav, které má pacient po sdělení nové diagnózy, je
          doplatek za léky. Zákony ovšem myslí zejména na ty občany, kteří jsou
          zranitelní nebo znevýhodnění. Proto se není třeba obávat, že by
          doplatky za léky překročily únosnou mez, alespoň ne trvale. Peníze
          vydané za předepsané léky nad zákonem stanovený limit dostanete vždy
          zpět.
        </p>
        <v-alert color="secondary">
          Užíváte-li léky od společnosti Novo Nordisk se započitatelným
          doplatkem, přejděte do
          <strong
            ><router-link to="/produkty/rybelsus"
              >uzavřené sekce</router-link
            ></strong
          >
          a zjistěte, kolik vás léčba bude skutečně stát.
        </v-alert>
      </div>
      <div class="text-right">
        <small>CZ23RYB00126</small>
      </div>
      <div class="my-12">
        <v-divider> </v-divider>
        <small>
          zdroje:
          <ol>
            <li>
              Ochranné limity. Všeobecná zdravotní pojišťovna České republiky.
              Dostupné na
              https://www.vzp.cz/pojistenci/informace-a-zivotni-situace/regulacni-poplatky-a-ochranne-limity/ochranne-limity.
              (Naposledy navštíveno 6. 3. 2023).
            </li>
            <li>
              Vyhláška č. 385/2007 Sb., o stanovení seznamu léčivých látek
              určených k podpůrné nebo doplňkové léčbě. Dostupné na
              https://www.lkcr.cz/doc/cms_library/385-2007-tanoveni-seznamu-lecivych-latek-urcenych-k-podpurne-nebo-doplnkove-lecbe-100445.pdf.
              (Naposledy navštíveno 6. 3. 2023).
            </li>
            <li>
              Jak VZP postupuje při vracení částek nad limit doplatků za léky,
              jde-li o dítě, a kdy je termín jejich splatnosti? Všeobecná
              zdravotní pojišťovna České republiky. Dostupné na
              https://www.vzp.cz/o-nas/tiskove-centrum/otazky-tydne/jak-vzp-postupuje-pri-vraceni-castek-nad-limit-doplatku-za-leky.
              (Naposledy navštíveno 6. 3. 2023).
            </li>
            <li>
              Zákon č. 48/1997 Sb., o veřejném zdravotním pojištění a o změně a
              doplnění některých souvisejících zákonů. Dostupné na
              https://www.zakonyprolidi.cz/cs/1997-48. (Naposledy navštíveno 6.
              3. 2023).
            </li>
            <li>
              Co je započitatelný doplatek na léky, který se počítá do
              ochranného limitu pacienta? Proč se prostě nezapočítávají všechny
              doplatky? Všeobecná zdravotní pojišťovna České republiky. Dostupné
              na
              https://www.vzp.cz/o-nas/tiskove-centrum/otazky-tydne/zapocitatelny-doplatek-na-leky.
              (Naposledy navštíveno 6. 3. 2023).
            </li>r
            <li>
              Co je započitatelný doplatek. Encyklopedie SÚKL. O lécích.
              Dostupné na
              https://www.olecich.cz/encyklopedie/co-je-to-zapocitatelny-doplatek.
              (Naposledy navštíveno 6. 3. 2023).
            </li>
          </ol>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import Earth from "../../components/illustration/Earth.vue";
import Human from "../../components/illustration/Human.vue";
import Microscope from "../../components/illustration/Microscope.vue";
export default {
  components: {
    Human,
    Earth,
    Microscope,
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 1rem;
}

h2 {
  color: var(--v-primary-base);
  margin-bottom: 0.5rem;
}

.human-illustration {
  position: absolute;
  right: -10rem;
  top: 10rem;
}

.earth-illustration {
  position: absolute;
  left: -7rem;
  top: 40rem;
}

.microscope-illustration {
  position: absolute;
  right: -21rem;
  top: 90rem;
}

.card {
  position: relative;
  background: white;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 2rem;
  border-radius: 1rem;
}
</style>
