<template>
  <div class="order-table">
    <div class="pb-8" v-for="(item, i) in items" :key="item.name">
      <div
        class="d-flex flex-column flex-md-row justify-space-between align-center mb-4"
      >
        <div>
          <div
            class="text-section text-h6 px-4 mb-4 mb-md-0"
            :class="
              item.amount === 0 ? 'grey--text lighten-2' : 'primary--text'
            "
            v-html="item.displayName"
          ></div>
          <small v-if="item.amount === 0" class="px-4 grey--text lighten-2"
            >Produkt není současně skladem</small
          >
        </div>
        <div class="d-flex align-center">
          <ButtonElement
            :disabled="item.amount === 0"
            :color="item.amount === 0 ? 'grey lighten-2' : 'secondary'"
            text="-"
            class="primary--text text-h6"
            @click="$emit('decreaseItem', i)"
          />
          <div
            class="amount-text text-h6 px-4"
            :class="
              item.amount === 0 ? 'grey--text lighten-2' : 'primary--text'
            "
          >
            {{ item.count }}
          </div>
          <ButtonElement
            :disabled="item.amount === 0"
            :color="item.amount === 0 ? 'grey lighten-2' : 'secondary'"
            text="+"
            class="primary--text text-h6"
            @click="$emit('increaseItem', i)"
          />
        </div>
      </div>
      <v-divider></v-divider>
    </div>
  </div>
</template>

<script>
import ButtonElement from "./elements/ButtonElement.vue";
export default {
  components: { ButtonElement },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.items.sort((a, b) => (a.count > b.count ? -1 : 1));
  },
};
</script>

<style lang="scss" scoped>
.order-table {
  .text-section {
    max-width: 21rem;
    text-align: center;
  }
  ::v-deep .button-element {
    width: 6rem;
  }

  .amount-text {
    white-space: nowrap;
  }

  @media screen and (min-width: 960px) {
    .text-section {
      text-align: left;
    }
  }
}
</style>
