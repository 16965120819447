<template>
  <div class="user-product-list">
    <ol class="text-base">
      <li v-for="(medicine, i) in medicines" :key="i">
        {{ medicine.displayName }}
      </li>
    </ol>
  </div>
</template>

<script>
import useApiCall from "../use/apiCall";

export default {
  setup() {
    const { getData } = useApiCall();
    return { getData };
  },
  data() {
    return {
      medicines: null,
    };
  },
  async mounted() {
    await this.fetchUserMedicines();
  },

  methods: {
    async fetchUserMedicines() {
      ({ medicines: this.medicines } = await this.getData(
        "medicines?skip=0&take=100"
      ));
    },
  },
};
</script>

<style></style>
