import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./plugins/axios";
import VueGtag from 'vue-gtag';
import { bootstrap } from 'vue-gtag';
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: {
    id: 'G-F066XYW501',
  },
  enabled: false,
  bootstrap: false,
});

bootstrap().then(() =>
  window.gtag('config', 'G-F066XYW501', { debug_mode: true })
);


[axios].forEach((x) => Vue.use(x));

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
