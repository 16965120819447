export default {
  required: (value) => !!value || "Toto pole je povinné",
  email: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || "Špatně zadaný formát emailu";
  },
  zipCode: (value) => {
    const pattern = /\b\d{5}\b/;
    return pattern.test(value) || "Špatně zadaný formát PSČ";
  },
  isNumberBetween0and1000: (value) => {
    const pattern = /^(?:0|[1-9][0-9]{0,2}|1000)$/;
    return pattern.test(value) || "Je potřeba zadat číslo v rozmezí 0 - 1000";
  },

  phoneNumber: (value) => {
    const pattern = /\b\d{9}\b/;
    return pattern.test(value) || "Špatně zadaný formát telefonního čísla";
  },
  max2000Char: (value) => {
    if (!value) {
      return true;
    } else {
      return value.length < 2000 ? true : "Maximální počet znaků je 2000";
    }
  },
  minLength8: (value) => {
    if (!value) {
      return true;
    } else {
      return value.length >= 8 ? true : "Minimální počet znaků je 8";
    }
  },
  max30Char: (value) => {
    if (!value) {
      return true;
    } else {
      return value.length < 30 ? true : "Maximální počet znaků je 30";
    }
  },
  atLeastOneNameRequired: (firstName, lastName) => {
    return !!firstName || !!lastName || "Musíte vyplnit alespoň jedno pole Jméno nebo Příjmení";
  }
};
