<template>
  <v-form class="order-form" @submit.prevent="onSubmit" ref="form">
    <div>
      <InputElement
        :rules="[rules.required, rules.max30Char]"
        v-model="order.billingInformation.firstName"
        label="Jméno"
      />
      <InputElement
        :rules="[rules.required, rules.max30Char]"
        v-model="order.billingInformation.lastName"
        label="Přijmení"
      />
      <InputElement
        :rules="[rules.required]"
        v-model="order.billingInformation.address.street"
        label="Ulice a č.p."
      />
      <InputElement
        :rules="[rules.required]"
        v-model="order.billingInformation.address.city"
        label="Město"
      />
      <InputElement
        :rules="[rules.required, rules.zipCode]"
        v-model="order.billingInformation.address.zipCode"
        label="PSČ"
      />
      <InputElement
        :rules="[rules.required, rules.phoneNumber]"
        v-model="order.billingInformation.number"
        label="Telefon"
      />
      <InputElement
        v-if="!isUserSignedIn"
        v-model="order.notRegisteredBuyerEmail"
        :rules="isUserSignedIn ? [] : [rules.required, rules.email]"
        label="E-mail"
      />

      <v-checkbox
        :rules="[rules.required]"
        v-model="order.acceptTerms"
        dark
        color="accent"
        class="mb-4 mt-0"
      >
        <template v-slot:label>
          <div class="white--text">
            Přečetl/a jsem si
            <a
              class="white--text"
              @click.stop
              target="_blank"
              rel="noopener noreferrer"
              href="https://novocare.b-cdn.net/podminky-uziti.pdf"
            >
              podmínky užití webu
            </a>
            a
            <a
              class="white--text"
              @click.stop
              href="https://novocare.b-cdn.net/oznameni-o-zpracovani-osobnich-udaju.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              zásady zpracování osobních údajů
            </a>
          </div>
        </template>
      </v-checkbox>
      <ButtonElement
        text="Objednat"
        color="accent"
        type="submit"
        :loading="loading"
      />
    </div>
  </v-form>
</template>

<script>
import ButtonElement from "../elements/ButtonElement.vue";
import InputElement from "../elements/InputElement.vue";
import useApiCall from "../../use/apiCall";
import validation from "../../use/validations";

export default {
  components: { InputElement, ButtonElement },
  setup() {
    const { postData } = useApiCall();
    return { postData };
  },
  data() {
    return {
      loading: false,
      rules: validation,
      showAlert: false,
      isUserSignedIn: !this.$store.getters.getBatchNumbers,
      order: {
        notRegisteredBuyerEmail: null,
        buyerNote: null,
        billingInformation: {
          callingCode: "+421",
          address: {
            countryCode: "CZ",
          },
        },
        acceptTerms: false,

        items: [
          {
            productId: null,
            amount: null,
          },
        ],
      },
    };
  },
  methods: {
    async onSubmit() {
      const products = this.$store.getters.getProducts;
      const orderedProducts = products.filter((product) => product.count > 0);
      this.order.items = orderedProducts.map((product) => ({
        productId: product.id,
        amount: product.count,
      }));

      if (orderedProducts.length === 0) {
        this.$emit("noOrderedProducts");
        scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      this.loading = true;

      const response = await this.postData(
        "orders",
        { order: this.order },
        null,
        true
      );

      this.loading = false;

      if (response) {
        this.$store.commit("setFormMessages", {
          type: "success",
          texts: [
            "Vaše objednávka byla přijata na email jsme Vám zaslali potvrzení o přijetí objednávky.",
          ],
        });

        this.$store.commit("setProducts", []);

        this.$store.commit("setOrderSummary", response);

        this.$router.push({ name: "OrderSummary" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order-form {
  .button-element {
    width: 100%;
  }
}
</style>
