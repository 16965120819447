import axios from "axios";
import { v4 } from 'uuid'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers = {
  'X-Client-Id': v4(),
  'X-Client-Version': '2.0.0',
  'X-Client-Type': 'web'
}

export default {
  install(Vue) {
    Vue.prototype.$axios = axios;
  },
};
