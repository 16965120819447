<template>
    <div class="user-profile">
        <div class="primary--text mb-4 text-h5">Vaše údaje</div>
        <div class="primary--text">
            {{ user.billingInformation.fullName }}
        </div>
        <div class="primary--text">
            {{ user.email }}
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        user() {
            return this.$store.getters.getUser;
        }
    }
}
</script>

<style lang="scss" scoped></style>