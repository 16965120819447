<template>
  <section class="section-diabetes-tools">
    <div class="section-diabetes-tools__background"></div>
    <div class="container">
      <v-img
        width="35"
        src="../assets/img/illustrations/pen.svg"
        class="pen-illustration"
      />

      <SectionHeader
        title="Chytrá inzulinová pera"
        text="NovoPen<sup>®</sup> 6 a NovoPen Echo<sup>®</sup> Plus jsou nová
            chytrá inzulinová pera, která automaticky zaznamenávají informace o
            užitých dávkách inzulinu pokaždé, když si ho aplikujete. Od teď již
            nemusíte přemýšlet, zda, kolik a kdy jste si naposledy inzulin
            aplikovali."
      />
      <div class="text-center mb-8">
        <ButtonElement
          text="CHCI VĚDĚT VÍC"
          :color="$vuetify.breakpoint.mdAndUp ? 'secondary' : 'primary'"
          :class="$vuetify.breakpoint.mdAndUp? 'primary--text' : 'white--text'"
          @click="$router.push({ name: 'SmartPen' })"
        />
      </div>

      <div style="width: 100%; vertical-align: bottom">
        <div style="padding: 56.25% 0 0 0; position: relative">
          <iframe
            src="https://player.vimeo.com/video/766840088?h=dd8eeab5a2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            title="NovoPen 6 - videon&amp;aacute;vod k použit&amp;iacute;"
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionHeader from "./SectionHeader.vue";
import ButtonElement from "../components/elements/ButtonElement.vue";

export default {
  components: { SectionHeader, ButtonElement },
};
</script>

<style lang="scss" scoped>
.section-diabetes-tools {
  padding-bottom: 4rem;
  position: relative;

  &__background {
    background-color: var(--v-secondary-base);
    height: 48rem;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  //   .section-header {
  //       margin-bottom: 1rem!important;
  //   }

  .pen-illustration {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: -3rem;
  }
}

@media screen and (min-width: 960px) {
  .section-diabetes-tools {
    &__background {
      height: 30rem;
    }
  }
}
</style>