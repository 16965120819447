<template>
  <svg
    width="266"
    height="227"
    viewBox="0 0 266 227"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_152_55)">
      <path
        d="M-55.001 -16.9998L21.4305 -16.9998C82.7967 -16.9998 132.68 32.9167 132.68 94.2493C132.68 135.595 109.981 173.292 73.3892 192.643L72.4183 193.212C54.7082 203.155 39.2746 203.925 37.1654 202.385C35.7593 201.381 35.0898 199.774 35.4245 198.301C35.7593 196.861 37.1654 194.953 41.9529 194.183C46.0707 193.513 48.3138 191.672 50.8916 189.496C52.3312 188.291 53.8377 187.052 55.813 185.88C57.6208 184.809 62.9104 185.144 65.9904 187.621L64.417 189.596C62.0065 187.654 57.8217 187.621 57.0852 188.056C55.2773 189.127 53.8712 190.333 52.4651 191.471C49.7199 193.748 47.1086 195.89 42.3211 196.694C39.2746 197.196 38.0024 198.167 37.835 198.903C37.7011 199.439 38.1363 200.008 38.605 200.376C40.145 201.012 53.8712 200.778 71.1796 191.036L72.184 190.467C107.973 171.518 130.169 134.658 130.169 94.2828C130.169 34.3228 81.3906 -14.4555 21.4305 -14.4555L-55.001 -14.4555V-16.9663V-16.9998Z"
        :fill="fill"
        fill-opacity="0.5"
      />
      <path
        d="M51.8335 164.053C55.7839 164.053 59.1318 165.091 61.8101 167.133C67.3675 171.385 67.8027 178.482 67.8027 178.783L65.2918 178.917C65.2918 178.917 64.8901 172.657 60.2366 169.108C56.9222 166.597 52.2352 165.928 46.3095 167.2C35.5629 169.476 29.3359 170.849 25.8876 171.619C24.3811 171.954 23.3767 173.36 23.5776 174.9C23.678 175.703 24.0798 176.473 24.3141 176.607L24.4815 179.085C22.3389 179.353 21.3011 176.842 21.1002 175.235C20.7654 172.422 22.5732 169.811 25.352 169.175C28.8002 168.405 35.0273 167.032 45.8073 164.722C47.95 164.254 49.9587 164.053 51.8335 164.053V164.053Z"
        :fill="fill"
        fill-opacity="0.5"
      />
      <path
        d="M42.5924 181.126L42.9941 183.604L19.4253 187.521C18.287 187.722 17.4835 188.726 17.517 189.864C17.517 190.534 17.8183 191.103 18.3539 191.538C18.8561 191.973 19.4922 192.141 20.1618 192.04L38.3071 189.329L38.6754 191.806L20.53 194.518C19.2244 194.719 17.8183 194.35 16.7804 193.48C15.7426 192.609 15.1065 191.337 15.0396 189.965C14.9391 187.554 16.68 185.445 19.057 185.043L42.6259 181.126H42.5924Z"
        :fill="fill"
        fill-opacity="0.5"
      />
      <path
        d="M42.3583 174.063L42.7266 176.54L18.7894 179.955C18.0864 180.056 17.4837 180.424 17.082 180.993C16.6803 181.562 16.5464 182.265 16.6803 182.935C16.9481 184.274 18.2203 185.111 19.5594 184.91L20.4299 184.776L20.8316 187.254L19.9612 187.388C17.2829 187.823 14.7385 186.082 14.2028 183.404C13.935 182.031 14.2028 180.658 15.0398 179.52C15.8433 178.382 17.0485 177.645 18.4546 177.444L42.3918 174.03L42.3583 174.063Z"
        :fill="fill"
        fill-opacity="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_152_55">
        <rect width="266" height="227" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
</style>