<template>
  <v-row justify="center">
    <v-col
      v-for="(item, i) in items"
      :key="i"
      :md="items.length > 2 ? 4 : 'auto'"
    >
      <div class="card">
        <img
          v-if="
            i === 0 &&
            items.length > 1 &&
            showIllustration &&
            $vuetify.breakpoint.mdAndUp
          "
          width="80"
          src="../assets/img/illustrations/human.svg"
          cover
          class="human-illustration"
        />
        <div class="card-header">
          <v-img width="100%" height="300" :src="item.imgSmall"> </v-img>
        </div>
        <div class="pa-6">
          <h3 class="card-title" v-html="item.title"></h3>
          <div class="card-text primary--text" v-html="item.perex" />
          <div class="card-button text-right">
            <ButtonElement
              text="ZJISTIT VÍCE"
              color="secondary"
              class="primary--text"
              @click="$router.push({ path: item.route })"
            />
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ButtonElement from "./elements/ButtonElement.vue";

export default {
  name: "CardGrid",
  components: { ButtonElement },
  props: {
    items: {
      type: Array,
      required: true,
    },
    showIllustration: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: white;
  min-height: 33rem;
  position: relative;

  &-header {
    position: relative;
  }

  &-title {
    color: var(--v-primary-base);
  }

  &-text {
    margin: 1rem 0;
    min-height: 9rem;
  }
}

.human-illustration {
  position: absolute;
  left: -4rem;
  top: -4rem;
}
</style>
