<template>
  <v-form class="recovery-mail-form" @submit.prevent="onSubmit" ref="form">
    <InputElement
      v-model="email"
      label="Váš email"
      :rules="[rules.required, rules.email]"
    />
    <div class="text-center">
      <ButtonElement
        type="submit"
        text="Resetovat heslo"
        color="accent"
        :loading="loading"
      />
    </div>
  </v-form>
</template>

<script>
import ButtonElement from "../elements/ButtonElement.vue";
import InputElement from "../elements/InputElement.vue";
import validation from "../../use/validations";
import useApicall from "../../use/apiCall";

export default {
  components: { InputElement, ButtonElement },
  data() {
    return {
      rules: validation,
      loading: false,
      email: null,
    };
  },

  setup() {
    const { postRawData, getRawData } = useApicall();

    return {
      postRawData,
      getRawData,
    };
  },

  methods: {
    async onSubmit() {
      this.loading = true;
      const response = await this.postRawData(
        "/auth/password/reset-code",
        {
          email: this.email,
          sourceDevice: "web",
        },
        null,
        true
      );

      this.loading = false;

      if (response?.status === 200) {
        this.$store.commit("setFormMessages", {
          type: "success",
          texts: [
            "Pokud jste zadali správný email, poslali jsme Vám na něj instrukce k resetování Vašeho hesla",
          ],
        });

        this.$router.push({
          name: "ResetPassword",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.recovery-mail-form {
  ::v-deep .v-btn {
    width: 100%;
  }
}
</style>
