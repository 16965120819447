<template>
  <v-form class="user-setting-form" ref="form" @submit.prevent="onSubmit">
    <div class="primary--text mb-4 text-h5">Vaše produkty</div>
    <div class="primary--text mb-8">
      <UserProductList ref="userProductList" />
    </div>
    <v-divider class="mb-4"></v-divider>
    <div class="primary--text mb-4 text-h5">Přidat další produkt</div>
    <div v-for="(batchNumber, i) in batchNumbers" :key="i">
      <InputElement
        :rules="[rules.required]"
        label="Číslo šarže"
        v-model="batchNumber.value"
      />
      <div class="d-flex justify-space-between mb-4">
        <div>
          <div
            v-if="i + 1 === batchNumbers.length"
            class="d-flex cursor-pointer"
            @click="addBatchNumber"
          >
            <v-icon color="primary">mdi-plus</v-icon>
            <div class="text primary--text ml-2">Přidat další šarži</div>
          </div>
        </div>
        <div
          v-if="i > 0"
          class="d-flex justify-end cursor-pointer"
          @click="removeBatchNumber(i)"
        >
          <v-icon color="primary">mdi-delete</v-icon>
          <div class="text primary--text ml-2">Odebrat šarži</div>
        </div>
      </div>
    </div>
    <div class="text-center mt-8">
      <ButtonElement type="submit" text="Přidat produkt" color="primary" />
    </div>
  </v-form>
</template>

<script>
import InputElement from "../elements/InputElement.vue";
import validation from "../../use/validations";
import ButtonElement from "../elements/ButtonElement.vue";
import useApiCall from "../../use/apiCall";
import UserProductList from "../userProductList.vue";

export default {
  components: { InputElement, ButtonElement, UserProductList },
  setup() {
    const { postData, getRawData } = useApiCall();
    return {
      postData,
      getRawData,
    };
  },
  data() {
    return {
      batchNumbers: [{ value: "" }],
      rules: validation,
    };
  },

  methods: {
    addBatchNumber() {
      this.batchNumbers.push({
        value: "",
      });
    },
    removeBatchNumber(index) {
      this.batchNumbers.splice(index, 1);
    },
    async onSubmit() {
      const batchNumberValue = this.batchNumbers.map((batchNumber) => {
        return batchNumber.value.toUpperCase();
      });

      if (this.$store.getters.getUser) {
        await this.addBatchNumberForLoginUser(batchNumberValue);
      } else {
        await this.addBatchNumberForNotRegisteredUser(batchNumberValue);
      }
    },

    async addBatchNumberForNotRegisteredUser(batchNumberValue) {
      const params = new URLSearchParams();
      batchNumberValue.forEach((element) => {
        params.append("batchNumbers", element);
      });

      const request = {
        params: params,
      };

      const response = await this.getRawData(
        "/medicines/validate-batch-numbers",
        request,
        true
      );

      if (response) {
        const currentBatchNumbers = this.$store.getters.getBatchNumbers;

        currentBatchNumbers.push(...batchNumberValue);
        const uniqueBatchNumbers = [...new Set(currentBatchNumbers)];

        const payload = [];
        uniqueBatchNumbers.forEach((el) => {
          payload.push({
            batchNumber: el,
          });
        });
        this.$store.commit("setBatchNumbers", payload);
        this.$store.dispatch("fetchProducts");
        await this.$refs.userProductList.fetchUserMedicines();
        this.$store.commit("setFormMessages", {
          type: "success",
          texts: ["Šarže byla úspěšně přidána"],
        });
      }
    },

    async addBatchNumberForLoginUser(batchNumberValue) {
      const response = await this.postData(
        "users/batch-numbers",
        { batchNumbers: batchNumberValue },
        null,
        true
      );

      if (response) {
        await this.$refs.userProductList.fetchUserMedicines();
        this.$store.dispatch("fetchProducts");
        this.$store.commit("setFormMessages", {
          type: "success",
          texts: ["Šarže byla úspěšně přidána"],
        });
      }
    },
  },
};
</script>

<style></style>
