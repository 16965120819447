<template>
  <div>
    <v-row>
      <v-col>
        <v-expansion-panels flat>
          <v-expansion-panel>
            <div class="accent pa-6">
              <div class="text-h5 white--text font-weight-bold">
                JAK ZDRAVOTNÍ POJIŠŤOVNY VRACEJÍ PŘEPLATKY ZA LÉKY?
              </div>
            </div>
            <div class="secondary">
              <div class="pa-4">
                <ul>
                  <li>
                    Zdravotní pojišťovny u svých pojištěnců sledují překročení
                    ochranného limitu pro doplatky na léky. Pokud ho
                    zaznamenají, automaticky zajistí vrácení přeplatku.
                    Překročení ochranného limitu tedy není třeba hlídat a o
                    vrácení přeplatků nad ochranný limit není třeba žádat.
                  </li>
                  <li>
                    Přehled o svých výdajích na léky můžete mít v rámci osobního
                    účtu své zdravotní pojišťovny. Jednou ročně si lze vyžádat k
                    zaslání tištěný výpis z účtu.
                  </li>
                  <li>
                    Zdravotní pojišťovna vás o vzniku přeplatku informuje a
                    vyplatí ho, případně si s vámi upřesní metodu platby.
                  </li>
                  <li>
                    Přeplatky jsou vypláceny vždy čtvrtletně, do 60 kalendářních
                    dnů od uplynutí kalendářního čtvrtletí, během něhož byl
                    ochranný limit pro doplatky překročen.
                  </li>
                  <li>
                    Od ledna 2022, kdy vyšla novela zákona o zdravotním
                    pojištění, zdravotní pojišťovny nevracejí v každém čtvrtletí
                    přeplatky nižší než 200 Kč. Nízké částky jsou převedeny do
                    dalšího čtvrtletí a vyplaceny v tom čtvrtletí, kdy jejich
                    souhrn překročí 200 Kč, nebo v posledním čtvrtletí
                    kalendářního roku.
                  </li>
                  <li>
                    Přeplatky se vracejí poštovní poukázkou (složenkou) nebo
                    převodem na bankovní účet. Zejména z praktických,
                    bezpečnostních i ekonomických důvodů pojišťovny preferují
                    bezhotovostní úhrady.
                  </li>

                  <li>
                    Číslo bankovního účtu je však třeba pojišťovně nahlásit,
                    některé za tímto účelem zřídily speciální formuláře (např.
                    VZP na webové adrese
                    <a
                      href="https://moje.vzp.cz/nahlaseni-zmeny/nahlaseni-bankovniho-uctu"
                      target="_blank"
                      rel="noopener noreferrer"
                      >https://moje.vzp.cz/nahlaseni-zmeny/nahlaseni-bankovniho-uctu</a
                    >
                    nebo VoZP na www.vozp.cz/formulare-pojistenci).
                  </li>
                </ul>
              </div>
            </div>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col>
        <div class="insurance-card pa-8">
          <div
            v-for="insurance in insurances"
            :key="insurance.link"
            class="mb-4"
          >
            <div class="mb-2">
              <img :src="insurance.img" />
            </div>
            <a
              :href="insurance.link"
              target="_blank"
              rel="noopener noreferrer"
              >{{ insurance.link }}</a
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      insurances: [
        {
          img: "https://general-storage.b-cdn.net/Pojistovny%20loga/Slice%201.png",
          link: "https://www.vzp.cz/o-nas/aktuality/zmena-u-ochranneho-limitu-u-doplatku-na-leky",
        },
        {
          img: "https://general-storage.b-cdn.net/Pojistovny%20loga/Slice%202.png",
          link: "https://benefity.ozp.cz/aktuality/aktualita-za-preplatky-na-leky-vratila-ozp-v-kvetnu-milion-korun",
        },
        {
          img: "https://general-storage.b-cdn.net/Pojistovny%20loga/Slice%203.png",
          link: "https://www.cpzp.cz/clanek/5325-0-Preplatky-z-doplatku-za-leky.html",
        },
        {
          img: "https://general-storage.b-cdn.net/Pojistovny%20loga/Slice%204.png",
          link: "https://www.zpskoda.cz/zps-vyplaci-preplatky-za-leky-za-prvni-ctvrtleti-roku-2023",
        },
        {
          img: "https://general-storage.b-cdn.net/Pojistovny%20loga/Slice%207.png",
          link: "https://www.vozp.cz/nejcastejsi-dotazy",
        },
        {
          img: "https://general-storage.b-cdn.net/Pojistovny%20loga/Slice%208.png",
          link: "https://www.zpmvcr.cz/pojistenci/informace-pro-pojistence/prava-pojistence",
        },
        {
          img: "https://general-storage.b-cdn.net/Pojistovny%20loga/Slice%209.png",
          link: "https://www.rbp213.cz/cs/pojistenci/zdravotni-sluzby/poplatky-a-doplatky/a-97",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.insurance-card {
  background-color: #e8ebee;
}

a {
  word-break: break-all;
}

li {
  margin-bottom: 1rem;
  color: var(--v-primary-base);
}
</style>
