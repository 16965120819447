<template>
  <v-form class="registration-form" @submit.prevent="onSubmit" ref="form">
    <div v-if="!hideBatchNumbersInput">
      <div
        v-if="inValidBatchNumbers.length > 0"
        class="red pa-4 mb-4 white--text"
      >
        <div v-for="(b, i) in inValidBatchNumbers" :key="i">
          {{ b.value }} není platné číslo šarže
        </div>
      </div>

      <div v-for="(batchNumber, i) in batchNumbers" :key="i">
        <InputElement
          :rules="[rules.required]"
          label="Číslo šarže"
          v-model="batchNumber.value"
        />
        <div class="d-flex justify-space-between mb-4">
          <div>
            <div
              v-if="i + 1 === batchNumbers.length"
              class="text-btn d-flex"
              @click="addBatchNumber"
            >
              <v-icon color="white">mdi-plus</v-icon>
              <div class="text white--text ml-2">Přidat další šarži</div>
            </div>
          </div>
          <div
            v-if="i > 0"
            class="text-btn d-flex justify-end"
            @click="removeBatchNumber(i)"
          >
            <v-icon color="white">mdi-delete</v-icon>
            <div class="text white--text ml-2">Odebrat šarži</div>
          </div>
        </div>
      </div>
    </div>
    <InputElement
      :rules="[rules.required, rules.email]"
      label="Email"
      v-model="user.email"
    />
    <PasswordInputElement
      label="Heslo"
      type="password"
      :rules="[rules.required]"
      v-model="user.password"
    />

    <v-checkbox
      v-model="user.acceptTerms"
      dark
      label="Souhlasím s podmínkami"
      :rules="[rules.required]"
    >
      <template #label>
        <div>
          <a
            @click.stop
            href="https://novocare.b-cdn.net/podminky-uziti.pdf"
            target="_blank"
            rel="noopener noreferrer"
            class="white--text"
          >
            Souhlasím s podmínkami aplikace NovoCare
          </a>
        </div>
      </template>
    </v-checkbox>

    <v-checkbox
      v-model="acceptPrivacyPolicy"
      class="mb-8"
      dark
      label="Souhlasím s podmínkami"
      :rules="[rules.required]"
    >
      <template #label>
        <div>
          <a
            @click.stop
            href="https://novocare.b-cdn.net/NN-oznameni-o-zpracovani-osobnich-udaju.pdf"
            target="_blank"
            rel="noopener noreferrer"
            class="white--text"
          >
            Souhlasím se zpracováním osobních údajů
          </a>
        </div>
      </template>
    </v-checkbox>

    <div class="text-center">
      <ButtonElement
        :loading="loading"
        text="Registrovat"
        color="accent"
        type="submit"
        class="submit-btn"
      />
    </div>
  </v-form>
</template>

<script>
import ButtonElement from "../elements/ButtonElement.vue";
import InputElement from "../elements/InputElement.vue";
import PasswordInputElement from "../elements/PasswordInputElement.vue";
import validation from "../../use/validations";
import useApiCall from "../../use/apiCall";

export default {
  components: { InputElement, ButtonElement, PasswordInputElement },
  setup() {
    const { postData, getData } = useApiCall();
    return { postData, getData };
  },
  props: {
    hideBatchNumbersInput: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      messages: null,
      inValidBatchNumbers: [],
      batchNumbers: [{ value: "" }],
      user: {
        batchNumbers: [],
        email: null,
        role: "patient",
        password: null,
        acceptTerms: false,
        origin: "WebRegistration",
      },
      acceptPrivacyPolicy: false,
      rules: validation,
    };
  },
  mounted() {
    if (this.email) {
      this.user.email = this.email;
    }
  },
  methods: {
    async onSubmit() {
      await this.registerUser();
    },

    async registerUser() {
      await this.validateBatchNumber();

      if (this.inValidBatchNumbers.length > 0) {
        return;
      }

      let getBatchNumbers = this.batchNumbers.map((batchNumber) =>
        batchNumber.value.toUpperCase()
      );

      if (this.hideBatchNumbersInput) {
        getBatchNumbers = this.$store.getters.getBatchNumbers;
      }

      this.user.batchNumbers = getBatchNumbers;

      const response = await this.postData(
        "/users",
        {
          user: this.user,
        },
        null,
        true
      );

      this.loading = false;

      if (response) {
        this.$store.commit("setFormMessages", {
          type: "success",
          texts: [
            "Pro dokončení registrace zkontrolujte prosím svojí emailovou schránku",
          ],
        });

        this.$router.push({
          name: "RegistrationVerify",
        });
      }
    },

    async validateBatchNumber() {
      this.loading = true;
      const params = new URLSearchParams();
      console.log(this.batchNumbers);
      this.batchNumbers.forEach((batchNumber) => {
        params.append("batchNumbers", batchNumber.value.toUpperCase());
      });

      const request = {
        params: params,
      };

      const response = await this.getData(
        "/medicines/validate-batch-numbers",
        request,
        true
      );

      this.loading = false;

      const batchNumbersResponse = response.batchNumbers;

      return (this.inValidBatchNumbers = batchNumbersResponse.filter(
        (item) => item.isValid === false
      ));
    },

    addBatchNumber() {
      this.batchNumbers.push({
        value: "",
      });
    },
    removeBatchNumber(index) {
      this.batchNumbers.splice(index, 1);
    },

    getErrorMessage(error) {
      const errorArray = error.response.data.errors;
      const errorMessageArray = errorArray.map((er) => er.message);
      this.messages = errorMessageArray;
      this.messages.type = "error";
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-btn {
  width: 100%;
}

.text-btn {
  cursor: pointer;

  &:hover {
    .text {
      text-decoration: underline;
    }
  }
}
</style>
