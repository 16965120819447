<template>
  <div class="wrong-product-page">
    <div class="container">
      <h1 class="text-center mt-8">Bohužel k této sekci nemáte přístup</h1>
      <div class="text-center">
        <img
          width="300"
          src="https://general-storage.b-cdn.net/wired-lineal-926-roadblock.gif"
        />
      </div>
      <div class="text-center mt-8">
        <ButtonElement
          text="Zpět na domovskou stránku"
          @click="$router.push({ name: 'Home' })"
        ></ButtonElement>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from "../components/elements/ButtonElement.vue";

export default {
  components: {
    ButtonElement,
  },
};
</script>

<style lang="scss" scoped></style>
