<template>
  <section class="section-education">
    <img
      width="30"
      src="../assets/img/illustrations/bulp.png"
      class="bulp-illustration"
    />
    <div class="section-education__background"></div>
    <div class="container">
      <SectionHeader
        title="O cukrovce"
        text="Cukrovka je v současné době onemocnění, které se nedá vyléčit, ale můžete ho poměrně dobře léčit. Čím více toho o ní víte, tím lépe ji budete zvládat. Tento web je průvodcem pro nemocné a jejich nejbližší na cestě za zdravějším a&nbsp;spokojenějším životem s cukrovkou."
      />
      <CardGrid
        :showIllustration="false"
        class="card-grid"
        :items="educationCards"
      />
    </div>
  </section>
</template>

<script>
import CardGrid from "./CardGrid.vue";
import SectionHeader from "./SectionHeader.vue";
export default {
  components: { SectionHeader, CardGrid },
  data() {
    return {
      educationCards: [
        {
          title: "O cukrovce 1. typu",
          route: "/cukrovka-typu-1",
          imgSmall: "https://novocare.b-cdn.net/HP/Cukrovka%201%201.png",
          perex:
            "<ul><li>Cukrovkou 1. typu trpí celosvětově téměř 54 milionů lidí, tedy asi 10&nbsp;% všech diabetiků.</li><li>Obvykle je diagnostikována u dětí nebo mladých dospělých.</li><li>Tělo při ní produkuje jen velmi malé nebo žádné množství inzulinu.</li><li>Jedinou léčbou je v současné době aplikace inzulinu.</li>",
        },
        {
          title: "O cukrovce 2. typu",
          route: "/cukrovka-typu-2",

          imgSmall: "https://novocare.b-cdn.net/HP/Cukrovka%202.png",
          perex:
            "<ul><li>Jde o metabolickou poruchu, při které tělo nevytváří dostatek inzulinu nebo na něj správně nereaguje.</li><li>Většinou vzniká v dospělosti, především v důsledku nesprávného životního stylu.</li><li>Primární příčinou cukrovky 2. typu je obezita. Kvůli tloustnutí populace se diabetes „dvojka“ stále častěji vyskytuje i u dětí.</li>",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.section-education {
  position: relative;
  padding-bottom: 4rem;
  &__background {
    background: var(--v-primary-base);
    height: 30rem;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .card-grid {
    margin-bottom: 4rem;
    ::v-deep .card {
      max-width: 33rem;
      margin: auto;
    }
    ::v-deep .card-text {
      min-height: 11rem;
    }
  }

  .bulp-illustration {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: -3rem;
  }
}
</style>
