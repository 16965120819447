<template>
  <section class="section-downloads">
    <div class="container">
      <img
        width="40"
        src="../assets/img/illustrations/microscope.svg"
        class="microscope-illustration"
      />
      <SectionHeader
        title="Materiály ke stažení"
        text="Stáhněte si bezplatně materiály o cukrovce v této sekci. Pokud užíváte léky na cukrovku od společnosti Novo Nordisk, můžete si většinu materiálů nechat zaslat v tištěné podobě přímo k vám domů."
      />

      <DownloadCardGrid :cards="cards" />

      <div class="text-center mt-8">
        <ButtonElement
          text="Všechny materiály ke stažení"
          class="mt-4"
          @click="$router.push({ name: 'Downloads' })"
        />
      </div>
    </div>
  </section>
</template>

<script>
import DownloadCardGrid from "./DownloadCardGrid.vue";
import ButtonElement from "./elements/ButtonElement.vue";
import SectionHeader from "./SectionHeader.vue";
import Downloads from "../data/downloads.json";

export default {
  components: { SectionHeader, ButtonElement, DownloadCardGrid },
  computed: {
    cards() {
      const ids = [1, 2, 5];
      let cards = [];
      ids.forEach((id) => {
        Downloads.forEach((download) => {
          if (download.id === id) {
            cards.push(download);
          }
        });
      });
      return cards;
    },
  },
};
</script>

<style lang="scss" scoped>
.section-downloads {
  position: relative;

  .microscope-illustration {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: -3rem;
  }
}
</style>