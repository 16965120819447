<template>
  <div class="news">
    <div class="news__background"></div>
    <div class="container page">
      <img
        width="30"
        src="../assets/img/illustrations/bulp.png"
        class="bulp-illustration"
      />
      <SectionHeader title="Aktuality" />

      <NewsCard
        v-for="n in news"
        :key="n.text"
        :news="n"
        @click="$router.push({ path: `aktuality/${n.path}` })"
      ></NewsCard>
    </div>
  </div>
</template>
<script>
import SectionHeader from "@/components/SectionHeader";
import NewsCard from "../components/NewsCard.vue";

export default {
  components: {
    SectionHeader,
    NewsCard,
  },
  data() {
    return {
      news: [
        {
          path: "ochranne-limity",
          title:
            "Vrácení doplatků aneb Předepsané léky na diabetes nemusejí být drahé",
          img: "https://general-storage.b-cdn.net/MicrosoftTeams-image%20(50).png",
          text: "Pro některé pacienty, a dokonce i pro část lékařů, je moderní léčba cukrovky synonymem léčby nákladné. Ve skutečnosti se pro ni ale více hodí pojmy jako šetrná, účinná nebo dobře snášená.",
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.v-main__wrap {
  background-color: #f5faff;
}
.news {
  position: relative;

  .bulp-illustration {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: -3rem;
  }
}
</style>
