<template>
  <div v-if="showPageText" class="text-center mt-16 text-h5 primary--text">
    {{ pageText }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPageText: false,
      pageText: "",
    };
  },

  mounted() {
    if (!this.$route.query.code || !this.$route.query.sourceDevice) {
      this.showPageText = true;
      this.pageText = "Odkaz je neplatný, zkontrolujte prosím adresu URL.";
      this.$emit("showPageInformationText");
      return;
    }

    if (
      this.$route.query.sourceDevice !== "mobile" &&
      this.$route.query.sourceDevice !== "web"
    ) {
      this.showPageText = true;
      this.pageText = "Odkaz je neplatný, zkontrolujte prosím adresu URL.";
      this.$emit("showPageInformationText");
      return;
    }

    if (this.$route.query.sourceDevice === "mobile") {
      this.showPageText = true;
      this.$emit("showPageInformationText");
      this.pageText = "Prosím otevřte tento odkaz ve svém mobilním zařízení.";
      return;
    }
  },
};
</script>

<style lang="scss" scoped></style>
