<template>
  <div class="page-hero">
    <div class="page-hero__background"></div>
    <div class="container">
      <div
        class="
          d-flex
          justify-space-between
          align-center
          flex-column flex-column-reverse flex-md-row
        "
      >
        <div>
          <div class="page-hero__text mr-4">
            <h1>
              Pomáháme diabetikům lépe porozumět jejich nemoci a žít naplno
            </h1>
          </div>
          <ButtonElement
            text="O cukrovce"
            color="primary"
            class="mr-4 mb-4"
            @click="$router.push({ name: 'AboutDiabetes' })"
          />
          <ButtonElement
            v-if="!$store.getters.getIsAuthorized"
            icon="lock"
            text="UŽÍVÁM LÉKY NA CUKROVKU"
            color="accent"
            class="mb-4"
            @click="$router.push({ name: 'Login' })"
          />
        </div>
        <div class="page-hero__img-wrapper mb-12 mb-md-0">
          <img
            v-if="$vuetify.breakpoint.mdAndUp"
            width="100"
            src="../assets/img/illustrations/human.svg"
            cover
            class="human-illustration"
          />
          <img
            v-if="$vuetify.breakpoint.mdAndUp"
            width="100"
            src="../assets/img/illustrations/hand.svg"
            cover
            class="hand-illustration"
          />
          <v-img
            src="https://novocare.b-cdn.net/HP/hero-img%201-min.png"
            class="grey lighten-2 page-hero__img"
            max-width="500"
          ></v-img>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from "./elements/ButtonElement.vue";
export default {
  components: { ButtonElement },
};
</script>

<style lang="scss" scoped>
.page-hero {
  margin-top: 5rem;
  &__background {
    background: var(--v-bgLight-base);
    width: 100%;
    height: 45rem;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__text {
    margin-bottom: 2rem;
    max-width: 30rem;
  }

  &__img-wrapper {
    position: relative;
    max-width: 500px;
    width: 100%;

    .human-illustration {
      position: absolute;
      left: -3rem;
      top: -4rem;
    }

    .hand-illustration {
      position: absolute;
      right: -2rem;
      bottom: -3rem;
    }
  }

  &__img {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  strong {
    border-bottom: 3px solid var(--v-accent-base);
    font-weight: bold;
  }
}

@media screen and (min-width: 960px) {
  .page-hero {
    &__background {
      height: 25rem;
    }
  }
}
</style>