<template>
  <v-form class="login-form" @submit.prevent="onSubmit" ref="form">
    <v-alert
      v-if="$store.getters.getLoginAgainText"
      type="info"
      icon="mdi-shield-lock-outline"
      >{{ $store.getters.getLoginAgainText }}</v-alert
    >
    <div>
      <InputElement
        :rules="[rules.required, rules.email]"
        label="Email"
        v-model="credentials.email"
      />

      <PasswordInputElement
        label="Heslo"
        type="password"
        :rules="[rules.required]"
        v-model="credentials.password"
      />
      <div class="text-right mb-4">
        <router-link to="/reset-hesla" class="white--text"
          >Zapomněli jste heslo?</router-link
        >
      </div>
    </div>

    <ButtonElement
      type="submit"
      text="Vstoupit"
      color="accent"
      class="mb-8"
      :loading="loading"
    />
  </v-form>
</template>

<script>
import ButtonElement from "../elements/ButtonElement.vue";
import InputElement from "../elements/InputElement.vue";
import validation from "../../use/validations";
import useApicall from "../../use/apiCall";
import PasswordInputElement from "../elements/PasswordInputElement.vue";

export default {
  components: { InputElement, ButtonElement, PasswordInputElement },

  setup() {
    const { postData, getRawData } = useApicall();
    return {
      postData,
      getRawData,
    };
  },

  data() {
    return {
      loading: false,
      credentials: {
        email: null,
        password: null,
      },
      batchNumbers: [
        {
          batchNumber: "",
        },
      ],
      messages: null,
      rules: validation,
    };
  },

  computed: {
    query() {
      return this.$route.query.redirect;
    },
  },

  methods: {
    async onSubmit() {
      await this.signIn();
    },

    async signIn() {
      this.loading = true;
      const userInfo = await this.postData(
        "/auth",
        {
          credentials: this.credentials,
          staySignedIn: false,
          isMobile: false,
        },
        null,
        true
      );

      this.loading = false;

      if (userInfo) {
        if (userInfo.user.role !== "nurse") {
          this.$store.commit(
            "setLoginAgainText",
            "Pokoušíte se přihlásit jako pacient, prosím využijte přihlašovací stránku pro pacienty."
          );
          return;
        }
        await this.$store.commit("setUserInfo", userInfo);
        this.$store.commit("setUserFullName", userInfo.user.fullName);
        this.checkQueryAndRedirect();
      }
    },

    checkQueryAndRedirect() {
      if (this.query) {
        this.$router.push({
          path: this.query,
        });
      } else {
        this.$router.push({
          name: "Patients",
        });
      }
    },

    getErrorMessage(error) {
      const errorArray = error.response.data.errors;
      const errorMessageArray = errorArray.map((er) => er.message);
      this.messages = errorMessageArray;
      this.messages.type = "error";
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  .button-element {
    width: 100%;
  }

  .text-btn {
    cursor: pointer;

    &:hover {
      .text {
        text-decoration: underline;
      }
    }
  }
}
</style>
