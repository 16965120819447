<template>
  <div class="diabetes-certificate-card pa-8 mb-4 secondary">
    <div class="d-md-flex">
      <div>
        <h1 class="mb-4">Průkaz diabetika</h1>
        <p class="primary--text mr-12 mb-12">
          Je souhrnem nejdůležitějších informací o diabetikovi. Slouží jemu
          samotnému, ošetřujícímu lékaři i případným osobám, které budou s
          diabetikem v případě jeho náhlé nevolnosti nebo pokud upadne do
          bezvědomí. Podává základní osobní informace, jméno a kontakt na
          ošetřujícího lékaře, způsob léčby, základní pokyny první pomoci, názvy
          užívaných léků a jejich dávkování.
        </p>
        <ButtonElement
          text="Stáhnout"
          @click="openDocument"
          class="mr-4 download-btn text--primary"
          color="secondary"
        />
        <ButtonElement
          text="Objednat"
          @click="order('PRUKAZDIABETIKA')"
          class="my-4 my-md-0"
        />
      </div>
      <v-img
        src="https://novocare.b-cdn.net/image%2018.png"
        max-width="400"
      ></v-img>
    </div>
  </div>
</template>

<script>
import ButtonElement from "./elements/ButtonElement.vue";
export default {
  components: { ButtonElement },
  methods: {
    order(code) {
      const products = this.$store.getters.getProducts;

      const index = products.findIndex((el) => {
        return el.code === code;
      });
      this.$store.commit("setIncreaseProduct", index);
    },

    openDocument() {
      open(
        "https://novocare.b-cdn.net/Novo%20Nordisk%202022-10%20prukazka%20292x105%20K_03%20(1).pdf"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.diabetes-certificate-card {
  border-radius: 1rem;

  .download-btn {
    border: 2px solid;
    border-color: 1px solid var(--v-primary-base) !important;
  }

  .v-btn {
    border: 2px solid;
  }
}
</style>
