<template>
  <div class="section-header text-center mb-12">
    <h1 v-html="title"></h1>
    <div class="section-header__divider"></div>
    <div class="section-header__text primary--text" v-html="text"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.section-header {
  &__divider {
    margin: 1rem auto;
    width: 5rem;
    border-bottom: 3px solid var(--v-accent-base);
  }

  &__text {
    max-width: 35rem;
    margin: auto;
  }
}
</style>