<template>
  <div class="page-footer primary white--text pa-8">
    <div class="container">
      <v-row justify="space-between">
        <v-col md="auto" cols="12">
          <div class="mb-2">
            <a
              class="white--text"
              href="https://novocare.b-cdn.net/oznameni-o-zpracovani-osobnich-udaju.pdf"
              target="__blank"
              >Zpracování osobních údajů</a
            >
          </div>
          <div class="mb-2">
            <a
              class="white--text"
              href="https://www.novonordisk.cz/contact-us/contact-us-form/hla-eni-ne-adoucich-uink.html"
              target="_blank"
              rel="noopener noreferrer"
              >Nahlášení nežádoucích účinků</a
            >
          </div>
          <div class="mb-2">
            <a
              href="https://novocare.b-cdn.net/podminky-uziti.pdf"
              target="_blank"
              rel="noopener noreferrer"
              class="white--text"
            >
              Podmínky užití webu
            </a>
          </div>
          <div
            class="mb-2 text-decoration-underline cursor-pointer"
            @click="showCookieModal"
          >
            Nastavení Cookies
          </div>
        </v-col>

        <v-col md="auto" cols="12">
          <div class="mb-2">Copyright TME solutions</div>
          <div>info@novocare.cz</div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFooter",
  methods: {
    showCookieModal() {
      this.$emit("showCookieModal");
    },
  },
};
</script>
