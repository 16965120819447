<template>
  <div class="container">
    <div class="users my-8" v-if="metadata">
      <SectionHeader title="Uživatelé" />
      <div class="text-right mb-4"></div>
      <v-row>
        <v-col md="4">
          <InputSearchQuery
            label="Hledat uživatele"
            @search="search"
          ></InputSearchQuery>
        </v-col>
        <v-col md="4">
          <InputSelectElement
            v-model="userRoleParam"
            label="Role"
            :items="roleOptions"
            @change="fetchUsers"
          ></InputSelectElement>
        </v-col>

        <v-col md="4">
          <ButtonElement
            text="Pozvat sestru"
            icon="account-plus"
            color="primary"
            @click="inviteNurse"
          >
          </ButtonElement>
        </v-col>
      </v-row>
      <v-btn
        class="mb-4"
        color="primary"
        @click="showUploadExcelModal = true"
        text
      >
        <v-icon>mdi-microsoft-excel</v-icon> Nahrát sestry z excelu
      </v-btn>
      <DataTable
        :loading="usersTableLoading"
        :headers="headers"
        :items="users"
        :metadata="metadata"
        :server-items-length="metadata.totalRecords"
        @updateFooterOptions="updateFooterOptions"
      >
        <template v-slot:item.role="{ item }">
          {{ getUserRole(item.role) }}
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{
            item.registeredAt ? getCzechDateWithoutTime(item.registeredAt) : "-"
          }}
        </template>
        <template v-slot:item.tempAccountCreatedAt="{ item }">
          {{
            item.tempAccountCreatedAt
              ? getCzechDateWithoutTime(item.tempAccountCreatedAt)
              : "-"
          }}
        </template>
        <template v-slot:item.isTemp="{ item }">
          {{ item.isTemp ? "NE" : "ANO" }}
        </template>
        <template v-slot:item.edit="{ item }">
          <v-btn
            icon
            v-if="item.role === 'nurse'"
            color="primary"
            @click="editNurse(item)"
          >
            <v-icon>mdi-pencil</v-icon></v-btn
          >
        </template>

        <template v-slot:item.sendInviteAgain="{ item }">
          <v-btn
            :loading="item.loading"
            icon
            v-if="item.role === 'nurse'"
            color="primary"
            @click="inviteAgain(item)"
          >
            <v-icon>mdi-email-fast-outline</v-icon></v-btn
          >
        </template>
      </DataTable>
    </div>

    <AppDialog
      :show-dialog="showInviteNurseDialog"
      title="Vytvořit uživatele"
      hideActions
      @closeDialog="showInviteNurseDialog = false"
    >
      <CreateNurseForm
        @closeDialog="showInviteNurseDialog = false"
        @fetchUsers="fetchUsers"
        :nurseId="nurseId"
      />
    </AppDialog>
    <AppDialog
      :show-dialog="showUploadExcelModal"
      title="Pozvat sestry skrze Excel"
      hideActions
      @closeDialog="showUploadExcelModal = false"
    >
      <UploadNurseExcelForm
        @closeDialog="showUploadExcelModal = false"
        @fetchUsers="fetchUsers"
        :nurseId="nurseId"
      />
    </AppDialog>
  </div>
</template>

<script>
import DataTable from "../components/elements/DataTable.vue";
import useApiCall from "../use/apiCall";
import useFormatDate from "../use/formatDate";
import SectionHeader from "../components/SectionHeader.vue";
import ButtonElement from "../components/elements/ButtonElement.vue";
import InputSelectElement from "../components/elements/InputSelectElement.vue";
import InputSearchQuery from "../components/elements/InputSearchQuery.vue";
import AppDialog from "../components/elements/AppDialog.vue";
import CreateNurseForm from "../components/forms/CreateNurseForm.vue";
import UploadNurseExcelForm from "../components/forms/UploadNurseExcelForm.vue";

import { ref } from "vue";

export default {
  setup() {
    const { getData, postData } = useApiCall();
    const { getCzechDate, getCzechDateWithoutTime } = useFormatDate();

    const showInviteNurseDialog = ref(false);

    return {
      postData,
      getData,
      getCzechDate,
      getCzechDateWithoutTime,
      showInviteNurseDialog,
    };
  },

  components: {
    UploadNurseExcelForm,
    InputSearchQuery,
    AppDialog,
    DataTable,
    InputSelectElement,
    SectionHeader,
    ButtonElement,
    CreateNurseForm,
  },

  data() {
    return {
      users: [],
      searchQuery: null,
      showUploadExcelModal: false,
      metadata: null,
      userRoleParam: null,
      usersTableLoading: false,
      senInviteAgainLoading: false,
      nurseId: null,
      roleOptions: [
        {
          text: "Sestry",
          value: "nurse",
        },
        {
          text: "Pacienti",
          value: "patient",
        },
      ],
      skip: 0,
      take: 10,
      headers: [
        {
          text: "ID",
          value: "id",
          sortable: false,
        },
        {
          text: "E-mail",
          value: "email",
          sortable: false,
        },
        {
          text: "Role",
          value: "role",
          sortable: false,
        },
        {
          text: "Datum registrace	",
          value: "createdAt",
          sortable: false,
        },
        {
          text: "Datum prvního vstupu bez registrace",
          value: "tempAccountCreatedAt",
          sortable: false,
        },
        {
          text: "Plnohodnotná registrace",
          value: "isTemp",
          sortable: false,
        },
        {
          text: "Upravit",
          value: "edit",
          sortable: false,
        },
        {
          text: "Znovu pozvat",
          value: "sendInviteAgain",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      const params = new URLSearchParams();

      params.append("take", this.take);
      params.append("skip", this.skip);
      params.append("orderBy", "CreatedAtDesc");

      if (this.userRoleParam) {
        params.append("role", this.userRoleParam);
      }

      this.searchQuery && params.append("searchQuery", this.searchQuery);

      try {
        this.usersTableLoading = true;
        ({ users: this.users, metadata: this.metadata } = await this.getData(
          "/users",
          { params: params }
        ));

        this.users = this.users.map((user) => {
          return {
            ...user,
            loading: false,
          };
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.usersTableLoading = false;
      }
    },

    async updateFooterOptions(event) {
      this.skip = event.page * event.itemsPerPage;
      this.take = event.itemsPerPage;
      await this.fetchUsers();
    },

    async inviteAgain(item) {
      try {
        item.loading = true;
        await this.postData("/users/activation/request-code", {
          email: item.email,
          sourceDevice: "web",
        });

        this.$store.commit("setFormMessages", {
          type: "success",
          texts: ["Pozvánka byla úspěšně odeslána"],
        });
      } catch (error) {
        console.log(error);
      } finally {
        item.loading = false;
      }
    },

    inviteNurse() {
      this.nurseId = null;
      this.showInviteNurseDialog = true;
    },

    editNurse(item) {
      this.nurseId = item.id;
      this.showInviteNurseDialog = true;
    },

    async search(query) {
      this.searchQuery = query;
      await this.fetchUsers();
    },

    getUserRole(role) {
      switch (role) {
        case "admin":
          return "Admin";
        case "patient":
          return "Pacient";
        case "nurse":
          return "Sestra";
        default:
          return "Nepřiřazeno";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
