import store from '../store/index';
import axios from 'axios';

export default function () {
  const tokenExpiration = new Date(store.getters.getTokenExpiration);
  const tokenExpirationSubs2Hours = new Date(
    store.getters.getTokenExpiration
  ).setHours(new Date(store.getters.getTokenExpiration).getHours() - 2);
  const today = new Date();
  const validToken = today < tokenExpiration;
  const tokenExpireSoon = today > tokenExpirationSubs2Hours;

  async function getNewToken() {
    try {
      const {
        data: { auth: response },
      } = await axios.post('auth/token', null, {
        params: {
          staySignedIn: true,
        },
        headers: {
          Authorization: `Bearer ${store.state.userInfo.auth.accessToken}`,
        },
      });
      store.commit('setAuth', response);
    } catch (error) {
      console.log(error);
    }
  }

  if (store.state.userInfo && !validToken) {
    store.commit(
      'setLoginAgainText',
      'Byl jste automaticky odhlášen prosím přihlašte se znovu'
    );
    store.commit('setFormMessages', {
      type: 'info',
      texts: ['Byl jste automaticky odhlášen prosím přihlašte se znovu'],
    });
    store.commit('setUserInfo', null);
    store.commit('setBatchNumbers', null);
    store.commit('setProducts', []);
    return;
  }

  if (tokenExpireSoon) {
    getNewToken();
  }

  return validToken;
}
