<template>
  <div class="activate-account">
    <v-alert
      v-for="(message, i) in messages"
      :key="i"
      :type="messages.type"
      width="500"
      class="ma-auto"
    >
      {{ message }}
    </v-alert>

    <PageInformationText
      @showPageInformationText="showPageInformationText = true"
    />
  </div>
</template>

<script>
import PageInformationText from "../components/elements/PageInformationText.vue";

export default {
  name: "ActivateAccount",
  components: { PageInformationText },
  data() {
    return {
      messages: null,
      showPageInformationText: false,
    };
  },
  async mounted() {
    if (this.showPageInformationText) {
      return;
    }
    try {
      const { data: userInfo } = await this.$axios.post(
        "/users/activation/submit-code",
        {
          code: this.$route.query.code,
        }
      );

      this.$store.commit("setUserInfo", userInfo);
      this.$router.push({
        name: "Products",
      });
    } catch (error) {
      this.getErrorMessage(error);
    }
  },
  methods: {
    getErrorMessage(error) {
      const errorArray = error.response.data.errors;
      const errorMessageArray = errorArray.map((er) => er.message);
      this.messages = errorMessageArray;
      this.messages.type = "error";
    },
  },
};
</script>

<style></style>
