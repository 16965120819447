<template>
  <v-text-field :type="type" v-bind="$attrs" solo outlined flat :label="label" :placeholder="label" :value="value"
    @input="(v) => $emit('input', v)" @change="$emit('change')"></v-text-field>
</template>

<script>
export default {
  name: "InputElement",
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "string",
    },
  },
};
</script>

<style></style>
