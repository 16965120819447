<template>
  <v-app>
    <MessageAlert />
    <AppDrawer :changeDrawer="changeDrawer" />
    <PageHeader v-if="showHeaderAndFooter" @clickOnNavIcon="clickOnNavIcon" />
    <v-main>
      <router-view />
    </v-main>
    <PageFooter
      v-if="showHeaderAndFooter"
      @showCookieModal="showCookiesModal = true"
    />
    <CookiesSetting
      v-if="showHeaderAndFooter"
      :showCookiesModal="showCookiesModal"
      @onCookiesSetting="showCookiesModal = true"
      @onSaveSettings="showCookiesModal = false"
    />
  </v-app>
</template>

<script>
import AppDrawer from "./components/AppDrawer.vue";
import CookiesSetting from "./components/CookiesSetting.vue";
import MessageAlert from "./components/MessageAlert.vue";
import PageFooter from "./components/PageFooter.vue";
import PageHeader from "./components/PageHeader.vue";
export default {
  components: {
    PageHeader,
    PageFooter,
    MessageAlert,
    AppDrawer,
    CookiesSetting,
  },
  name: "App",
  data() {
    return {
      changeDrawer: false,
      showCookiesModal: false,
    };
  },
  methods: {
    clickOnNavIcon() {
      this.changeDrawer = !this.changeDrawer;
    },
  },

  computed: {
    showHeaderAndFooter() {
      return (
        this.$route.path !== "/pdf/j29fj1" &&
        this.$route.path !== "/pdf/hf28ng" &&
        this.$route.name !== "PdfReport"
      );
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: FigtreeRegular;
  src: url("../public/fonts/Figtree-Regular.ttf");
}

body {
  font-family: FigtreeRegular;
}

h1 {
  color: var(--v-primary-base);
}

.container {
  position: relative;
  max-width: 35rem;
  display: block;
  margin: auto;
  width: 100%;
}

.page {
  margin-top: 4rem;
}

.cursor-pointer {
  cursor: pointer;
}

section {
  margin: 7rem 0 8rem 0;
  h1 {
    font-size: 2rem;
  }
}

.border-card {
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid #e0e0e0;
}

@media screen and (min-width: 960px) {
  .container {
    max-width: 72rem;
  }
}
</style>
