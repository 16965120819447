<template>
    <div class="stickers mt-4 mb-12">
        <div class="container">
            <div class="text-h4 mb-8">
                Samolepky na inzulinové pero
            </div>
            <v-row>
                <v-col md="6" v-for="sticker in stickers" :key="sticker.title">
                    <StickerCard :item="sticker"></StickerCard>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>

import StickerCard from '../components/StickerCard.vue';

export default {
    components: { StickerCard },
    data() {
        return {
            stickers: []
        }
    },


    methods: {
        getStickers() {
            const products = this.$store.getters.getProducts;
            products.forEach((el, i) => { el.index = i });
            this.stickers = products.filter(el => el.code.includes('SAMOLEPKA'));

            this.stickers.forEach((el) => {
                switch (el.code) {
                    case 'SAMOLEPKAPEROHVEZD':
                        el.imgAll = 'https://novocare.b-cdn.net/samolepky/Hve%CC%8Czdic%CC%8Cky_all-min.jpg';
                        el.imgDetail = 'https://novocare.b-cdn.net/samolepky/Hve%CC%8Czdic%CC%8Cky_detail-min.jpg'
                        break;

                    case 'SAMOLEPKAPEROLEV':
                        el.imgAll = 'https://novocare.b-cdn.net/samolepky/Lev_all-min.jpg';
                        el.imgDetail = 'https://novocare.b-cdn.net/samolepky/Lev_detail-min.jpg'
                        break;

                    case 'SAMOLEPKAPEROMANDALA':
                        el.imgAll = 'https://novocare.b-cdn.net/samolepky/mandala_all-min.jpg';
                        el.imgDetail = 'https://novocare.b-cdn.net/samolepky/Mandala_detail-min.jpg'
                        break;

                    case 'SAMOLEPKAPEROVESMIR':
                        el.imgAll = 'https://novocare.b-cdn.net/samolepky/Vesmi%CC%81r_all-min.jpg';
                        el.imgDetail = 'https://novocare.b-cdn.net/samolepky/Vesmi%CC%81r_detail-min.jpg'
                        break;

                    case 'SAMOLEPKAPERORAKETA':
                        el.imgAll = 'https://novocare.b-cdn.net/samolepky/Raketa_all-min.jpg';
                        el.imgDetail = 'https://novocare.b-cdn.net/samolepky/Raketa_detail-min.jpg'
                        break;

                    case 'SAMOLEPKAPEROUNICORN':
                        el.imgAll = 'https://novocare.b-cdn.net/samolepky/Unicorn_all-min.jpg';
                        el.imgDetail = 'https://novocare.b-cdn.net/samolepky/Unicorn_detail-min.jpg'
                        break;

                    case 'SAMOLEPKAPEROSRDCE':
                        el.imgAll = 'https://novocare.b-cdn.net/samolepky/Srdce_all-min.jpg';
                        el.imgDetail = 'https://novocare.b-cdn.net/samolepky/Srdce_detail-min.jpg'
                        break;

                    case 'SAMOLEPKAPEROCERVENE':
                        el.imgAll = 'https://novocare.b-cdn.net/samolepky/c%CC%8Cervena%CC%81_all-min.jpg';
                        el.imgDetail = 'https://novocare.b-cdn.net/samolepky/c%CC%8Cervena%CC%81_detail-min.jpg'
                        break;

                    case 'SAMOLEPKAPEROFORMULE':
                        el.imgAll = 'https://novocare.b-cdn.net/samolepky/Formule_all-min.jpg';
                        el.imgDetail = 'https://novocare.b-cdn.net/samolepky/Formule_detail-min.jpg'
                        break;

                    default:
                        el.imgAll = '';
                        el.imgDetail = ''
                        break;
                }
            });

        }
    },

    mounted() {
        this.getStickers();
    }
}

</script>

<style lang="scss" scoped></style>