<template>
  <v-form class="request-patient-form" @submit.prevent="onSubmit" ref="form">
    <v-text-field
      label="E-mail pacienta"
      solo
      outlined
      :rules="[rules.required, rules.email]"
      flat
      v-model="email"
    ></v-text-field>
    <div class="text-right">
      <ButtonElement
        text="Poslat žádost"
        :loading="loading"
        type="submit"
      ></ButtonElement>
    </div>
  </v-form>
</template>

<script>
import ButtonElement from "../elements/ButtonElement.vue";
import useApiCall from "../../use/apiCall";
import validations from "../../use/validations";

export default {
  components: {
    ButtonElement,
  },

  setup() {
    const { postRawData } = useApiCall();

    return {
      postRawData,
    };
  },

  data() {
    return {
      email: "",
      loading: false,
      rules: validations,
    };
  },

  methods: {
    async sendPatientRequest() {
      try {
        this.loading = true;
        const response = await this.postRawData(
          "care-requests",
          {
            email: this.email,
          },
          {},
          true
        );

        if (response?.status === 200) {
          this.$emit("patientRequestSent");

          this.$store.commit("setFormMessages", {
            type: "success",
            texts: ["Pacientovi byla odeslána pozvánka."],
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    onSubmit() {
      this.sendPatientRequest();
    },
  },
};
</script>

<style lang="scss" scoped></style>
