<template>
  <div class="card-grid">
    <v-row justify="center">
      <v-col md="4" cols="12" v-for="card in cards" :key="card.title">
        <div class="card" :style="{ 'background-image': `url(${card.img})` }">
          <div class="card__overlay"></div>
          <v-tooltip :disabled="!card.tooltip" top>
            <template v-slot:activator="{ on }">
              <v-icon
                v-if="card.tooltip"
                class="info-icon cursor-pointer"
                v-on="on"
                color="white"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <div v-html="card.tooltip"></div>
          </v-tooltip>
          <div>
            <h1 class="card__title mb-4" v-html="card.title"></h1>
            <div class="card__action mb-4">
              <v-btn
                v-if="card.download"
                class="white--text"
                depressed
                text
                @click="open(card.download)"
                >Stáhnout</v-btn
              >
            </div>
            <v-btn
              color="white"
              v-if="card.productCode && isLoggedInUser"
              outlined
              @click="order(card.productCode)"
            >
              <v-icon left>mdi-cart-variant</v-icon> Objednat
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "DownloadCardGrid",
  props: {
    cards: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      default: "#092b69",
    },
  },

  computed: {
    isAdmin() {
      return this.$store.getters.getUserRole === "admin";
    },
    isLoggedInUser() {
      return (this.$store.getters.getUser || this.$store.getters.getBatchNumbers) && !this.isAdmin;
    },
  },

  methods: {
    open(download) {
      open(download);
    },

    order(code) {
      const products = this.$store.getters.getProducts;

      const index = products.findIndex((el) => {
        return el.code === code;
      });
      this.$store.commit("setIncreaseProduct", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 15rem;
  height: 100%;
  background: gray;
  padding: 1rem;
  color: white;
  text-align: center;
  position: relative;
  background-size: cover;
  background-position: bottom;

  .info-icon {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }

  &__overlay {
    background: v-bind("color");
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.6;
    top: 0;
  }

  &__title {
    color: white;
    position: relative;
  }

  &__action {
    color: white;
    text-decoration: underline;
  }
}
</style>
