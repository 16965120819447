import axios from 'axios';
import store from '../store/index';

export default async function (type, payload) {
    try {
        const response = await axios.post(`/magic-codes/${type}/exchange`, payload)
        return response
    } catch (error) {
        const erorArray = error.response.data.errors;
        const errorTexts = erorArray.map((error) => error.message);
        const errodObject = {};
        errodObject.texts = errorTexts;
        errodObject.type = 'error';
        store.commit('setFormMessages', errodObject);
        return false
    }

}
