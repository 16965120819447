<template>
  <div class="d-md-flex card" @click="$emit('click')">
    <div>
      <v-img
        :width="$vuetify.breakpoint.mdAndUp ? '250' : '100%'"
        height="250"
        :src="news.img"
      />
    </div>
    <div class="card__text">
      <div class="mb-4 text-h5">{{ news.title }}</div>
      <div>{{ news.text }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    news: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  background-color: white;
  max-width: 50rem;
  margin: auto;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: rgba(0, 56, 158, 0.2) 0px 12px 18px 0px;
  }

  &__text {
    padding: 2rem;
    color: var(--v-primary-base);
  }

  img {
    width: 10rem;
    height: 10rem;
  }
}
</style>
