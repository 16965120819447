<template>
  <div class="cookies-bar">
    <div class="cookies-bar__header">
      <img
        src="https://general-storage.b-cdn.net/cookie_logo.svg"
        alt="cookie icon"
        width="70"
      />
      <div class="cookies-bar__header-text">Cookies</div>
    </div>
    <div class="cookies-bar__text">
      Tento web používá cookies. Díky nim vidíte jen to, co skutečně chcete. Souhlas s jejich používáním udělíte klinutím na tlačítko "OK".<br />
    </div>
    <div class="cookies-bar__actions">
      <button class="secondary-btn" @click="onSettingButton">Nastavení</button>
      <button class="primary-btn" @click="onAcceptAll">OK</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookiesBar",
  methods: {
    onSettingButton() {
      this.$emit("onSettingButton");
    },
    onAcceptAll() {
      this.$emit("onAcceptAll");
    },
  },
};
</script>

<style scoped lang="scss">
// Cookies bar styles
.cookies-bar {
  position: fixed;
  bottom: 0;
  z-index: 100;
  background: rgb(217 236 255);
  width: 100%;
  text-align: center;
  box-shadow: 00 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;

    &-text {
      font-size: 2rem;
      margin-left: 1rem;
    }
  }

  &__text {
    margin: 1rem;
  }

  &__actions {
    margin: 2rem 0;

    button {
      padding: 0.5rem 1rem;
      width: 8rem;
    }

    .secondary-btn {
      background: whitesmoke;
      border: 1px solid gray;
      margin-right: 1rem;
    }

    .primary-btn {
      background: var(--v-primary-base);
      color: white;
      border: 1px solid var(--v-primary-base);
    }
  }
}
</style>