<template>
  <v-form class="reset-password-form" ref="form" @submit.prevent="onSubmit">
    <PasswordInputElement
      :rules="[rules.required]"
      v-model="password"
      label="Heslo"
    ></PasswordInputElement>
    <PasswordInputElement
      :rules="[rules.required]"
      v-model="passwordAgain"
      label="Heslo znovu"
    ></PasswordInputElement>

    <v-checkbox
      v-model="acceptTerms"
      dark
      label="Souhlasím s podmínkami"
      :rules="[rules.required]"
    >
      <template #label>
        <div>
          <a
            @click.stop
            href="https://novocare.b-cdn.net/podminky-uziti.pdf"
            target="_blank"
            rel="noopener noreferrer"
            class="white--text"
          >
            Souhlasím s podmínkami aplikace NovoCare
          </a>
        </div>
      </template>
    </v-checkbox>

    <v-checkbox
      v-model="acceptPrivacyPolicy"
      class="mb-8"
      dark
      label="Souhlasím s podmínkami"
      :rules="[rules.required]"
    >
      <template #label>
        <div>
          <a
            @click.stop
            href="https://novocare.b-cdn.net/NN-oznameni-o-zpracovani-osobnich-udaju.pdf"
            target="_blank"
            rel="noopener noreferrer"
            class="white--text"
          >
            Souhlasím se zpracováním osobních údajů
          </a>
        </div>
      </template>
    </v-checkbox>

    <div class="text-center">
      <ButtonElement
        text="Nastavit heslo"
        color="accent"
        type="submit"
        :loading="loading"
      />
    </div>
  </v-form>
</template>

<script>
import ButtonElement from "../elements/ButtonElement.vue";
import validation from "../../use/validations";
import useApicall from "../../use/apiCall";
import PasswordInputElement from "../elements/PasswordInputElement.vue";

export default {
  components: { ButtonElement, PasswordInputElement },
  setup() {
    const { postData } = useApicall();
    return { postData };
  },
  data() {
    return {
      password: null,
      loading: false,
      passwordAgain: null,
      rules: validation,
      acceptTerms: false,
      acceptPrivacyPolicy: false,
    };
  },

  methods: {
    async onSubmit() {
      if (this.password !== this.passwordAgain) {
        this.$store.commit("setFormMessages", {
          type: "error",
          texts: ["Hesla se neshodují"],
        });

        return;
      }
      try {
        this.loading = true;
        const response = await this.postData(
          "users/activation/submit-code-nurse",
          {
            code: this.$route.query.code,
            password: this.password,
            acceptTerms: this.acceptTerms,
          },
          null,
          true
        );

        this.loading = false;

        if (response) {
          await this.$store.commit("setUserInfo", response);
          this.$store.commit("setUserFullName", response.user.fullName);

          this.$store.commit("setFormMessages", {
            type: "success",
            texts: ["Vaše heslo bylo nastaveno"],
          });

          await this.$router.push({ path: "/pacienti" });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 100%;
}
</style>
