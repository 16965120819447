<template>
  <div class="tresiba-detail">
    <div class="container">
      <div class="tresiba-detail__header">
        <div class="text-h4 title">
          Moje léčba přípravkem Xultophy<sup>®</sup>
        </div>
      </div>
      <div class="tresiba-detail__text">
        <Human
          v-if="$vuetify.breakpoint.mdAndUp"
          class="human-illustration"
          fill="#e6358e"
        />
        <p>
          Přípravek Xultophy<sup>®</sup> je určen pro léčbu dospělých s diabetem
          2. typu.
        </p>
        <p>
          Může se lišit od jiných léčebných přípravků, které jste možná doteď
          vyzkoušeli, protože obsahuje 2 léčivé látky v jednom peru:
        </p>
        <ul class="mb-4">
          <li>
            inzulin degludek – dlouhodobě působící bazální inzulin, který
            snižuje hladiny cukru v krvi.
          </li>
          <li>
            liraglutid – „analog GLP-1“, který pomáhá tělu vytvářet větší
            množství inzulinu během jídel
          </li>
        </ul>
        <p>
          Přípravek Xultophy<sup>®</sup> se aplikuje pouze jednou denně. Váš
          lékař vám řekne, kolik dávkovacích jednotek přípravku Xultophy<sup
            >®</sup
          >
          máte aplikovat každý den a také vám poradí, jak si upravit velikost
          dávky v návaznosti na vaše hladiny glukózy v krvi. Xultophy<sup
            >®</sup
          >
          je nejlepší aplikovat každý den ve stejnou dobu, vyberte si tedy čas,
          který vám nejlépe vyhovuje.
        </p>
        <p>
          Více informací o přípravku Xultophy<sup>®</sup> naleznete v příbalové
          informaci pro pacienty.
        </p>

        <div>
          <h2 class="primary--text mt-12 mb-4">Návod na pero Flextouch</h2>
          <div>
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                src="https://player.vimeo.com/video/1033415045?h=5d20e17ace&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="Videonávod pro Flextouch"
              ></iframe>
            </div>
          </div>
        </div>
        <Earth
          v-if="$vuetify.breakpoint.mdAndUp"
          class="earth-illustration"
          fill="#e6358e"
        />
        <Hand
          v-if="$vuetify.breakpoint.mdAndUp"
          class="hand-illustration"
          fill="#e6358e"
        />
      </div>
      <Microscope
        v-if="$vuetify.breakpoint.mdAndUp"
        class="microscope-illustration"
        fill="#e6358e"
      />
    </div>
  </div>
</template>

<script>
import Earth from "../illustration/Earth.vue";
import Hand from "../illustration/Hand.vue";
import Human from "../illustration/Human.vue";
import Microscope from "../illustration/Microscope.vue";
export default {
  components: { Microscope, Hand, Earth, Human },
};
</script>

<style lang="scss" scoped>
.tresiba-detail {
  &__header {
    display: inline;
    .title {
      display: inline-block;
      margin-bottom: 1rem;
    }
    position: relative;
  }

  &__text {
    position: relative;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    padding: 2rem;
    border-radius: 1rem;
    background-color: white;

    .human-illustration {
      position: absolute;
      right: 22rem;
      top: -9rem;
      max-width: 10rem;
    }
    .earth-illustration {
      position: absolute;
      left: -8.3rem;
      top: 4rem;
    }

    .hand-illustration {
      position: absolute;
      max-width: 10rem;
      right: -7.5rem;
      bottom: -8rem;
    }
  }

  .microscope-illustration {
    max-width: 32rem;
    margin-left: 10rem;
    margin-top: 0.5rem;
  }
}
</style>
