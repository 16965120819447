<template>
  <div class="order-summary page">
    <div class="container mb-12">
      <SectionHeader title="Shrnutí objednávky" />
      <div v-if="orderSummary.order" class="card white--text">
        <table width="100%">
          <thead>
            <th class="text-left">Produkt</th>
            <th class="text-right">Počet kusů</th>
          </thead>

          <tr v-for="(item, i) in orderSummary.order.items" :key="i">
            <td v-html="item.product.displayName"></td>
            <td class="text-right">
              {{ item.amount }}
            </td>
          </tr>
        </table>
        <v-divider class="white my-4"> </v-divider>
        <div>Datum vytvoření objednávky: {{ orderDate }}</div>
        <div v-if="isRegistered" class="mt-4">
          Detail objednávky najdete v sekci
          <router-link to="objednavky" class="white--text"
            ><strong>Moje objednávky</strong></router-link
          >
        </div>
        <div class="mt-12" v-else>
          <div class="mb-4 text-h6">
            Pro rychlejší objednáni a sledování stavu objednávky se můžete
            zaregistrovat
          </div>
          <RegistrationForm hideBatchNumbersInput :email="orderSummary.order.buyer.email" />
        </div>
      </div>
      <div class="text-center grey--text" v-else>
        Zatím nemáte vytvořenou žádnou objednávku
      </div>
    </div>
  </div>
</template>

<script>
import RegistrationForm from "../components/forms/RegistrationForm.vue";
import SectionHeader from "../components/SectionHeader.vue";
import useApiCall from "../use/apiCall";

export default {
  components: { SectionHeader, RegistrationForm },
  setup() {
    const { getData } = useApiCall();

    return {
      getData,
    };
  },
  data() {
    return {
      orderSummary: this.$store.getters.getOrderSummary,
    };
  },

  computed: {
    isRegistered() {
      return this.$store.getters.getUser;
    },
    orderDate() {
      const date = new Date(this.orderSummary?.order?.orderedAt);
      return date.toLocaleString("cs", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: var(--v-primary-base);
  padding: 2rem;
  max-width: 30rem;
  margin: auto;
}
</style>
