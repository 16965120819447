<template>
  <v-form class="reset-password-form" ref="form" @submit.prevent="onSubmit">
    <PasswordInputElement
      :rules="[rules.required]"
      v-model="currentPassword"
      label="Současné heslo"
    ></PasswordInputElement>
    <PasswordInputElement
      :rules="[rules.required]"
      v-model="newPassword"
      label="Nové heslo"
    ></PasswordInputElement>

    <PasswordInputElement
      :rules="[rules.required]"
      v-model="newPasswordAgain"
      label="Nové heslo znovu"
    ></PasswordInputElement>

    <div class="text-center">
      <ButtonElement
        text="Nastavit heslo"
        color="primary"
        type="submit"
        :loading="loading"
      />
    </div>
  </v-form>
</template>

<script>
import ButtonElement from "../elements/ButtonElement.vue";
import validation from "../../use/validations";
import useApiCall from "../../use/apiCall";
import PasswordInputElement from "../elements/PasswordInputElement.vue";

export default {
  components: { ButtonElement, PasswordInputElement },
  setup() {
    const { putData } = useApiCall();
    return { putData };
  },
  data() {
    return {
      password: null,
      loading: false,
      currentPassword: null,
      newPassword: null,
      newPasswordAgain: null,
      rules: validation,
    };
  },

  methods: {
    async onSubmit() {
      if (this.newPassword !== this.newPasswordAgain) {
        this.$store.commit("setFormMessages", {
          type: "error",
          texts: ["Hesla se neshodují"],
        });

        return;
      }
      try {
        this.loading = true;
        const { auth } = await this.putData(
          "auth/password",
          {
            currentPassword: this.currentPassword,
            newPassword: this.newPassword,
            staySignedIn: true,
          },
          null,
          true
        );

        this.loading = false;

        if (auth) {
          console.log(auth);
          await this.$store.commit("setAuth", auth);

          this.$store.commit("setFormMessages", {
            type: "success",
            texts: ["Vaše heslo bylo nastaveno"],
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 100%;
}
</style>
