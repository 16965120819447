<template>
  <div>
    <v-navigation-drawer v-model="drawer" fixed right temporary app>
      <v-list nav dense>
        <v-list-item-group active-class="primary--text text--accent-4">
          <v-list-item
            v-for="menuItem in menuItems"
            :key="menuItem.name"
            color="primary"
            @click="
              menuItem.redirect
                ? $router.push({ name: menuItem.redirect })
                : menuItem.action()
            "
          >
            <v-list-item-icon>
              <v-icon v-text="menuItem.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider v-if="isAuthorized && !isNurse" class="mb-4"></v-divider>
      <v-badge
        :value="$store.getters.getCheckoutItems"
        class="ml-4"
        v-if="isAuthorized && !isNurse"
        bordered
        color="primary"
        :content="$store.getters.getCheckoutItems"
        overlap
      >
        <v-btn
          block
          color="accent"
          depressed
          class="pa-6"
          @click="$router.push({ name: 'Order' })"
        >
          <v-icon left>mdi-cart-outline</v-icon>
          Objednávka
        </v-btn>
      </v-badge>

      <template v-if="isAuthorized" v-slot:append>
        <div class="pa-2">
          <v-btn text block @click="logOut"> Odhlásit se </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import mobileMenuItems from "@/data/mobileMenuItems";

export default {
  props: {
    changeDrawer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isAuthorized() {
      return this.$store.getters.getIsAuthorized;
    },
    isAdmin() {
      return this.$store.getters.getUserRole === "admin";
    },
    isNurse() {
      return this.$store.getters.getUserRole === "nurse";
    },
    isPatient() {
      return (
        this.$store.getters.getUserRole === "patient" ||
        this.$store.getters.getBatchNumbers?.length > 0
      );
    },
    menuItems() {
      if (this.isAdmin) {
        return mobileMenuItems.admin;
      } else if (this.isPatient) {
        return mobileMenuItems.patient;
      } else if (this.isNurse) {
        return mobileMenuItems.nurse;
      } else {
        return mobileMenuItems.public;
      }
    },
  },
  data() {
    return {
      drawer: false,
    };
  },
  watch: {
    changeDrawer() {
      this.drawer = !this.drawer;
    },
  },

  methods: {
    logOut() {
      this.$store.commit("setUserInfo", null);
      this.$store.commit("setUserFullName", null);
      this.$store.commit("setBatchNumbers", null);
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep button {
  width: 14rem;
}
</style>
