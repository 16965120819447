<template>
    <div class="sticker-card">
        <v-card class="card">
            <div class="d-flex image-wrapper">
                <v-img class="sticker-img" :src="item.imgDetail" max-width="50%"
                    @click="openDialog(item.imgDetail)"></v-img>
                <v-img class="sticker-img" :src="item.imgAll" max-width="50%" @click="openDialog(item.imgAll)"></v-img>
            </div>
            <v-card-title class="d-flex justify-center primary mt-4">
                <div class="white--text">{{ item.displayName }}</div>
            </v-card-title>
            <v-card-text class="grey lighten-5 pt-4">
                <div>
                    <div class="d-flex align-center justify-center ">
                        <ButtonElement :disabled="item.amount === 0"
                            :color="item.amount === 0 ? 'grey lighten-2' : 'secondary'" text="-"
                            class="primary--text text-h6" @click="decreaseItem(item.index)" />
                        <div class="amount-text text-h6 px-4" :class="item.amount === 0 ? 'grey--text lighten-2' : 'primary--text'
                            ">
                            {{ item.count }}
                        </div>
                        <ButtonElement :disabled="item.amount === 0"
                            :color="item.amount === 0 ? 'grey lighten-2' : 'secondary'" text="+"
                            class="primary--text text-h6" @click="increaseItem(item.index)" />
                    </div>
                </div>
            </v-card-text>
        </v-card>

        <v-dialog v-model="showDialogImage" max-width="1200">

            <v-card>
                <div class="text-right pa-4">
                    <v-btn icon @click="showDialogImage = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-img :src="dialogImage"></v-img>
            </v-card>

        </v-dialog>
    </div>
</template>

<script>

import ButtonElement from './elements/ButtonElement.vue';

export default {
    name: 'StickerCard',
    components: { ButtonElement },
    props: {
        item: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            showDialogImage: false,
            dialogImage: ''
        }
    },

    methods: {

        openDialog(img) {
            this.dialogImage = img;
            this.showDialogImage = true;
        },

        increaseItem(index) {
            this.$store.commit("setIncreaseProduct", index);
        },
        decreaseItem(index) {
            this.$store.commit("setDecreaseProduct", index);
        }
    },
}

</script>

<style lang="scss" scoped>
.sticker-card {
    .card {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        ::v-deep .button-element {
            width: 6rem;
        }
    }

    .v-card__title {
        word-break: normal;
    }

    .sticker-img {
        cursor: zoom-in;
    }

    .image-wrapper {
        min-height: 11rem;
    }
}
</style>