<template>
  <v-select
    :type="type"
    v-bind="$attrs"
    solo
    outlined
    :items="items"
    :clearable="clearable"
    flat
    :label="label"
    :placeholder="label"
    :value="value"
    @input="(v) => $emit('input', v)"
    @change="$emit('change')"
  ></v-select>
</template>

<script>
export default {
  name: "InputElement",
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },

    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "string",
    },
  },
};
</script>

<style></style>
