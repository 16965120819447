<template>
  <div class="downloads mb-12 page">
    <div class="downloads__background"></div>
    <img
      width="50"
      src="../assets/img/illustrations/cloud.svg"
      class="cloud-illustration"
    />
    <div class="container">
      <SectionHeader
        title="Ke stažení"
        text="<p>V této sekci najdete veškeré všeobecné materiály věnované cukrovce<br> 1. i 2. typu. Všechny tyto materiály si můžete bezplatně stáhnout do svého počítače.</p><p> Pokud užíváte léky na cukrovku od společnosti Novo Nordisk, můžete se přihlásit do uzavřené sekce <a href='/prihlaseni'>Pro pacienty</a>, kde najdete více informací i přímo k&nbsp;léčbě, kterou vám zvolil váš lékař.</p>"
      />
      <div class="card-wrapper">
        <img
          v-if="$vuetify.breakpoint.mdAndUp"
          width="70"
          src="../assets/img/illustrations/human.svg"
          cover
          class="human-illustration"
        />
        <DownloadCardGrid :cards="cards" />
      </div>
    </div>
  </div>
</template>

<script>
import DownloadCardGrid from "../components/DownloadCardGrid.vue";
import SectionHeader from "../components/SectionHeader.vue";
import Downloads from "../data/downloads.json";

export default {
  components: { DownloadCardGrid, SectionHeader },
  data() {
    return {
      cards: Downloads,
    };
  },
};
</script>

<style lang="scss" scoped>
.downloads {
  position: relative;
  &__background {
    background: var(--v-bgLight-base);
    height: 40rem;
    width: 100%;
    position: absolute;
    left: 0;
    top: -5rem;
  }

  .card-wrapper {
    position: relative;

    .human-illustration {
      position: absolute;
      left: -3.5rem;
      top: -3rem;
    }
  }

  .cloud-illustration {
    position: absolute;
    left: 50%;
    top: -2.5rem;
    transform: translate(-50%);
  }
}
</style>