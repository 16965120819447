<template>
  <div class="diabetes-tools page mb-8">
    <div class="diabetes-tools__background"></div>
    <div class="container">
      <img
        width="30"
        src="../assets/img/illustrations/bulp.png"
        class="bulp-illustration"
      />

      <SectionHeader class="section-header" title="Pomůcky pro diabetiky" />
      <CardGrid class="card-grid" :items="educationCards" />
    </div>
  </div>
</template>

<script>
import CardGrid from "../components/CardGrid.vue";
import SectionHeader from "../components/SectionHeader.vue";
export default {
  components: { SectionHeader, CardGrid },
  data() {
    return {
      educationCards: [
        {
          imgSmall: "https://novocare.b-cdn.net/novopen/Frame%2021.png",
          title: "Chytrá inzulinová pera",
          perex:
            "NovoPen<sup>®</sup> 6 a NovoPen Echo<sup>®</sup> Plus jsou nová chytrá inzulinová pera, která automaticky zaznamenávají informace o užitých dávkách inzulinu pokaždé, když si ho aplikujete. Od teď již nemusíte přemýšlet, zda, kolik a kdy jste si naposledy inzulin aplikovali.",
          route: "chytra-pera",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.diabetes-tools {
  position: relative;
  background: var(--v-primary-base);
  height: 100%;
  &__background {
    background: var(--v-bgLight-base);
    height: 40rem;
    width: 100%;
    position: absolute;
    left: 0;
    top: -5rem;
  }

  .bulp-illustration {
    position: absolute;
    left: 50%;
    top: -3rem;
    transform: translate(-50%);
  }

  .card-grid {
    ::v-deep .card {
      max-width: 23rem;
      margin: auto;
    }
  }
}
</style>