<template>
  <div class="product-detail">
    <div class="product-detail__img mb-12 container">
      <v-img
        :src="product.imgBig"
        max-width="500"
        max-height="500"
        class="ma-auto mb-8"
      />
    </div>
    <component v-bind:is="product.name"></component>
  </div>
</template>

<script>
import Tresiba from "../components/productsDetail/Tresiba.vue";
import NovoPen from "./productsDetail/Novopen.vue";
import Rybelsus from "../components/productsDetail/Rybelsus.vue";
import Ozempic from "../components/productsDetail/Ozempic.vue";
import Fiasp from "../components/productsDetail/Fiasp.vue";
import Xultophy from "../components/productsDetail/Xultophy.vue";
import Ryzodeg from "../components/productsDetail/Ryzodeg.vue";
import Saxenda from "../components/productsDetail/Saxenda.vue";

export default {
  components: {
    Tresiba,
    NovoPen,
    Rybelsus,
    Ozempic,
    Fiasp,
    Xultophy,
    Ryzodeg,
    Saxenda
  },

  
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep h1 {
  color: v-bind("product.theme.header");
}

::v-deep .title {
  color: v-bind("product.theme.header");
}
::v-deep p {
  color: v-bind("product.theme.text");
}
::v-deep ul {
  color: v-bind("product.theme.text");
}
::v-deep ol {
  color: v-bind("product.theme.text");
}
.product-detail {
  img {
    width: 100%;
  }
  &__text-section {
    max-width: 60%;
  }
}
.divider {
  border-top: 3px solid var(--v-accent-base);
  width: 10rem;
  margin: 1rem 0;
}
</style>
