<template>
  <div class="order">
    <div class="order__background"></div>
    <img
      src="../assets/img/illustrations/heart.png"
      class="heart-illustration"
    />
    <div class="container">
      <SectionHeader title="Objednávka" />
      <div class="d-flex flex-column flex-md-row justify-space-between">
        <div class="order__table">
          <v-alert
            v-model="showAlert"
            type="error"
            class="mb-12"
            prominent
            text
          >
            Prosím přidejte alespoň jeden produkt
          </v-alert>
          <OrderTable
            :items="$store.state.products"
            @decreaseItem="decreaseItem"
            @increaseItem="increaseItem"
          />
        </div>
        <div>
          <div class="order__form-card primary pa-8">
            <div class="white--text my-4">
              Všechny materiály jsou zdarma včetně poštovného. Doručíme je na
              vaši adresu do 5 pracovních dnů.
              <v-divider class="mt-8 mb-4" color="white"></v-divider>
            </div>
            <div class="white--text text-h4 mb-8">Kontaktní údaje</div>
            <OrderForm @noOrderedProducts="noOrderedProducts" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderForm from "../components/forms/OrderForm.vue";
import OrderTable from "../components/OrderTable.vue";
import SectionHeader from "../components/SectionHeader.vue";

export default {
  components: { SectionHeader, OrderForm, OrderTable },
  data() {
    return {
      products: [],
      showAlert: false,
      timeOut: null,
    };
  },
  async mounted() {
    if (this.$store.getters.getCheckoutItems === 0) {
      this.$store.dispatch("fetchProducts");
    }
  },
  methods: {
    increaseItem(index) {
      this.$store.commit("setIncreaseProduct", index);
      this.showAlert = false;
    },
    decreaseItem(index) {
      this.$store.commit("setDecreaseProduct", index);
    },
    noOrderedProducts() {
      this.showAlert = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  position: relative;
  background: var(--v-bgLight-base);
  &__table {
    width: 100%;
    margin-right: 4rem;
  }

  .heart-illustration {
    position: absolute;
    left: 50%;
    top: 1rem;
    transform: translate(-50%);
  }

  &__form-card {
    min-width: 45%;
  }
  padding: 4rem 0;
}
</style>
