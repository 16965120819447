<template>
  <div class="order-list">
    <div class="container">
      <SectionHeader title="Objednávky" />
      <v-row v-if="userRole === 'admin'">
        <v-col cols="12" md="3">
          <v-select
            outlined
            background-color="white"
            :items="statusOptions"
            item-text="name"
            item-value="value"
            v-model="statusFilter"
            label="Stav objednávky"
            clearable
            @change="statusFilterChange"
          ></v-select>
        </v-col>
        <v-col cols="12" md="9">
          <InputElement
            :label="'Vyhledat objednávku'"
            v-model="searchQuery"
            @input="searchInputChange"
          />
        </v-col>
      </v-row>
      <pre></pre>
      <DataTable
        class="mb-12"
        v-if="orders"
        :headers="headers"
        :items="orders"
        :metadata="metadata"
        :server-items-length="metadata.totalRecords"
        @updateFooterOptions="updateFooterOptions"
        @sortBy="sortBy($event)"
        @sortDesc="sortDesc($event)"
      >
        <template v-slot:item.status="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :color="item.iconColor">
                {{ item.icon }}
              </v-icon>
            </template>
            <span>{{ item.iconTooltip }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.orderedAt="{ item }">
          {{ getCzechDate(item.orderedAt) }}
        </template>

        <template v-slot:item.buyer.isTemp="{ item }">
          <v-icon>{{
            `mdi-${
              item.buyer.isTemp
                ? "account-cancel-outline"
                : "account-check-outline"
            }`
          }}</v-icon>
        </template>
        <template v-slot:item.statusLastSetAt="{ item }">
          {{ getCzechDate(item.orderedAt) }}
        </template>
        <template v-slot:item.goToDetail="{ item }">
          <v-btn @click="openDetail(item)" color="primary" icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </template>
      </DataTable>
    </div>
  </div>
</template>
„

<script>
import DataTable from "../components/elements/DataTable.vue";
import InputElement from "../components/elements/InputElement.vue";
import SectionHeader from "../components/SectionHeader.vue";
import useApiCall from "../use/apiCall";
import useFormatDate from "../use/formatDate";

export default {
  setup() {
    const { getData } = useApiCall();
    const { getCzechDate } = useFormatDate();
    return {
      getData,
      getCzechDate,
    };
  },
  data() {
    return {
      skip: 0,
      take: 10,
      orderByParams: ["orderedAtDesc"],
      orders: null,
      metadata: null,
      searchQuery: null,
      requestTimeOut: null,
      statusFilter: null,
      statusIconColor: {},
      headers: [
        {
          text: "Stav",
          value: "status",
          align: "center",
          sortable: false,
        },
        {
          text: "Email",
          value: "buyer.email",
          sortable: false,
        },
        {
          text: "Datum vytvoření objednávky",
          value: "orderedAt",
        },
        {
          text: "Poslední změna provedena",
          value: "statusLastSetAt",
        },

        {
          text: "",
          value: "goToDetail",
          align: "right",
          sortable: false,
        },
      ],
      statusOptions: [
        {
          name: "Nová",
          value: "new",
        },
        {
          name: "Připravena k odeslání",
          value: "readyForTransit",
        },
        {
          name: "V přepravě",
          value: "inTransit",
        },
        {
          name: "Zrušena",
          value: "canceled",
        },
        {
          name: "Doručená",
          value: "completed",
        },
      ],
    };
  },
  computed: {
    userRole() {
      return this.$store.getters.getUserRole;
    },
  },
  components: {
    SectionHeader,
    DataTable,
    InputElement,
  },

  async mounted() {
    await this.fetchOrders();
    if (this.userRole === "admin") {
      const item = {
        text: "Registrovaný uživatel",
        value: "buyer.isTemp",
        align: "center",
        sortable: false,
      };
      this.headers.splice(3, 0, item);
    }
  },
  methods: {
    async fetchOrders() {
      const params = new URLSearchParams();

      params.append("take", this.take);
      params.append("skip", this.skip);

      this.orderByParams.forEach((param) => {
        params.append("orderBy", param);
      });

      if (this.userRole === "patient") {
        const user = this.$store.getters.getUser;
        params.append("buyerId", user.id);
      }

      if (this.searchQuery) {
        params.append("searchQuery", this.searchQuery);
      }

      if (this.statusFilter) {
        params.append("status", this.statusFilter);
      }

      const response = await this.getData("/orders", {
        params: params,
      });

      this.orders = response.orders;
      this.metadata = response.metadata;

      this.orders.forEach((order) => {
        this.getStatusIcon(order);
      });
    },
    async updateFooterOptions(event) {
      this.skip = event.page * event.itemsPerPage;
      this.take = event.itemsPerPage;
      await this.fetchOrders();
    },
    openDetail(item) {
      this.$router.push({ name: "OrderDetail", params: { id: item.hashId } });
    },

    searchInputChange() {
      if (this.requestTimeOut) {
        clearTimeout(this.requestTimeOut);
      }
      this.requestTimeOut = setTimeout(() => {
        this.fetchOrders();
        clearTimeout(this.requestTimeOut);
      }, 600);
    },

    getStatusIcon(item) {
      switch (item.status) {
        case "new":
          item.icon = "mdi-package-variant";
          item.iconColor = "blue";
          item.iconTooltip = "Nová objednávka čeká na zpracování";
          break;
        case "readyForTransit":
          item.icon = "mdi-package-variant-closed";
          item.iconColor = "grey";
          item.iconTooltip = "Připravena k odeslání";
          break;
        case "inTransit":
          item.icon = "mdi-truck-delivery-outline";
          item.iconColor = "orange";
          item.iconTooltip = "V přepravě";
          break;
        case "completed":
          item.icon = "mdi-package-variant-closed-check";
          item.iconColor = "green";
          item.iconTooltip = "Doručená";
          break;
        case "canceled":
          item.icon = "mdi-package-variant-remove";
          item.iconColor = "red";
          item.iconTooltip = "Zrušená";
          break;
        default:
          return null;
      }
    },
    async sortBy(event) {
      if (event) {
        await this.fetchOrders();
      }
    },
    async sortDesc(event) {
      event
        ? (this.orderByParams = ["orderedAtDesc"])
        : (this.orderByParams = ["orderedAtAsc"]);
      await this.fetchOrders();
    },
    async statusFilterChange() {
      await this.fetchOrders();
    },
  },
};
</script>

<style></style>
