var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tresiba-detail"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"tresiba-detail__text"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('Human',{staticClass:"human-illustration",attrs:{"fill":"#e6358e"}}):_vm._e(),_vm._m(1),_c('p',[_vm._v(" Může se lišit od jiných léčebných přípravků, které jste možná doteď vyzkoušeli, protože obsahuje 2 léčivé látky v jednom peru: ")]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),(_vm.$vuetify.breakpoint.mdAndUp)?_c('Earth',{staticClass:"earth-illustration",attrs:{"fill":"#e6358e"}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('Hand',{staticClass:"hand-illustration",attrs:{"fill":"#e6358e"}}):_vm._e()],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('Microscope',{staticClass:"microscope-illustration",attrs:{"fill":"#e6358e"}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tresiba-detail__header"},[_c('div',{staticClass:"text-h4 title"},[_vm._v(" Moje léčba přípravkem Xultophy"),_c('sup',[_vm._v("®")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Přípravek Xultophy"),_c('sup',[_vm._v("®")]),_vm._v(" je určen pro léčbu dospělých s diabetem 2. typu. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"mb-4"},[_c('li',[_vm._v(" inzulin degludek – dlouhodobě působící bazální inzulin, který snižuje hladiny cukru v krvi. ")]),_c('li',[_vm._v(" liraglutid – „analog GLP-1“, který pomáhá tělu vytvářet větší množství inzulinu během jídel ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Přípravek Xultophy"),_c('sup',[_vm._v("®")]),_vm._v(" se aplikuje pouze jednou denně. Váš lékař vám řekne, kolik dávkovacích jednotek přípravku Xultophy"),_c('sup',[_vm._v("®")]),_vm._v(" máte aplikovat každý den a také vám poradí, jak si upravit velikost dávky v návaznosti na vaše hladiny glukózy v krvi. Xultophy"),_c('sup',[_vm._v("®")]),_vm._v(" je nejlepší aplikovat každý den ve stejnou dobu, vyberte si tedy čas, který vám nejlépe vyhovuje. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Více informací o přípravku Xultophy"),_c('sup',[_vm._v("®")]),_vm._v(" naleznete v příbalové informaci pro pacienty. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"primary--text mt-12 mb-4"},[_vm._v("Návod na pero Flextouch")]),_c('div',[_c('div',{staticStyle:{"padding":"56.25% 0 0 0","position":"relative"}},[_c('iframe',{staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"},attrs:{"src":"https://player.vimeo.com/video/1033415045?h=5d20e17ace&badge=0&autopause=0&player_id=0&app_id=58479","frameborder":"0","allow":"autoplay; fullscreen; picture-in-picture; clipboard-write","title":"Videonávod pro Flextouch"}})])])])
}]

export { render, staticRenderFns }