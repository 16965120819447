<template>
  <div class="d-flex justify-end">
    <div v-for="(userType, i) in loginUserType" :key="i" md="auto" class="mr-4">
      <div class="cursor-pointer" @click="makeButtonActive(i)">
        <div class="d-flex align-center justify-center">
          <div
            class="user-type-button mr-4"
            :class="buttonIsActive(i) && 'active-button'"
          >
            <v-icon :color="buttonIsActive(i) ? 'white' : 'primary'" size="35">
              {{ `mdi-${userType.icon}` }}
            </v-icon>
          </div>
          <div
            class="button-title mt-2 primary--text"
            :class="buttonIsActive(i) ? 'font-weight-bold' : ''"
          >
            {{ userType.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeButton: null,
      loginUserType: [
        {
          icon: "account",
          name: "patient",
          title: "Pacient",
        },
        {
          icon: "doctor",
          name: "nurse",
          title: "Zdravotní sestra",
        },
      ],
    };
  },

  mounted() {
    this.$store.getters.getLoginUserType === "nurse"
      ? this.makeButtonActive(1)
      : this.makeButtonActive(0);
  },

  methods: {
    makeButtonActive(index) {
      this.activeButton = index;
      this.$store.commit("setLoginUserType", this.loginUserType[index].name);
      this.$store.commit("setLoginAgainText", null);
    },

    buttonIsActive(index) {
      return this.activeButton === index;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-type-button {
  background: white;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--v-primary-base);
  padding: 1rem;
  transition: 0.5s;

  &.active-button {
    background: var(--v-primary-base);
    color: white;
  }
}
</style>
