<template>
  <div class="transaction-table">
    <InputElement
      label="Vyhledat transakci"
      v-model="searchQuery"
      @input="searchInputChange"
    />
    <DataTable
      v-if="transactions"
      :headers="headers"
      :items="transactions"
      :metadata="metadata"
      @updateFooterOptions="updateFooterOptions"
    >
      <template v-slot:item.product.displayName="{ item }">
        <span v-html="item.product.displayName"></span>
      </template>
      <template v-slot:item.amount="{ item }">
        {{ getSign(item.type) }} {{ item.amount }}
      </template>

      <template v-slot:item.type="{ item }">
        {{ getTransactionType(item.type) }}
      </template>
      <template v-slot:item.occurredAt="{ item }">
        {{ getCzechDate(item.occurredAt) }}
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "./elements/DataTable.vue";
import useApiCall from "../use/apiCall";
import InputElement from "./elements/InputElement.vue";
import useFormatDate from "../use/formatDate";

export default {
  components: { DataTable, InputElement },

  setup() {
    const { getData, postData } = useApiCall();
    const { getCzechDate } = useFormatDate();
    return {
      getData,
      postData,
      getCzechDate,
    };
  },

  data() {
    return {
      transactions: null,
      metadata: null,
      take: 10,
      skip: 0,
      searchQuery: null,
      requestTimeOut: null,
      plusMinusSign: null,
      orderBy: "occurredAtDesc",
      headers: [
        {
          text: "Produkt",
          value: "product.displayName",
          sortable: false,
        },
        {
          text: "Typ",
          value: "type",
          sortable: false,
        },
        {
          text: "Množství",
          value: "amount",
          align: "right",
          sortable: false,
        },
        {
          text: "Změněno uživatelem",
          value: "user.email",
          sortable: false,
        },
        {
          text: "Datum změny",
          value: "occurredAt",
          sortable: false,
        },
      ],
    };
  },

  async mounted() {
    await this.fetchTransactions();
  },

  methods: {
    async fetchTransactions() {
      const params = new URLSearchParams();

      params.append("take", this.take);
      params.append("skip", this.skip);
      params.append("orderBy", this.orderBy);

      if (this.searchQuery) {
        params.append("searchQuery", this.searchQuery);
      }

      const response = await this.getData("/products/transactions", {
        params: params,
      });
      this.transactions = response.productTransactions;
      this.metadata = response.metadata;
    },

    async updateFooterOptions(event) {
      this.skip = event.page * event.itemsPerPage;
      this.take = event.itemsPerPage;
      await this.fetchTransactions();
    },

    searchInputChange() {
      if (this.requestTimeOut) {
        clearTimeout(this.requestTimeOut);
      }
      this.requestTimeOut = setTimeout(() => {
        this.fetchTransactions();
        clearTimeout(this.requestTimeOut);
      }, 600);
    },

    getSign(item) {
      switch (item) {
        case "newOrder":
          return "-";
        case "cancelOrder":
          return "-";
        case "restoredOrder":
          return "+";
        case "restock":
          return "+";
        case "discard":
          return "-";
        default:
          return "chyba";
      }
    },

    getTransactionType(item) {
      switch (item) {
        case "newOrder":
          return "Nová objedávka";
        case "cancelOrder":
          return "Zrušená objednávka";
        case "restoredOrder":
          return "Obnovená objednávka";
        case "restock":
          return "Doplnění zboží";
        case "discard":
          return "Vyřazení zboží";
        default:
          return "chyba";
      }
    },
  },
};
</script>

<style></style>
