<template>
  <v-form class="order-form" @submit.prevent="onSubmit" ref="form">
    <div>
      <div class="mb-4 text-right">
        <a
          href="/files/nurse-invite-excel.xlsx"
          download="nurse-invite-excel.xlsx"
          >Stáhnout vzorový soubor</a
        >
      </div>
      <v-file-input
        solo
        outlined
        flat
        label="Nahrajte Excel"
        v-model="file"
      ></v-file-input>
      <div class="text-right">
        <ButtonElement
          :text="'Nahrát Excel'"
          type="submit"
          :loading="loading"
        ></ButtonElement>
      </div>
    </div>
  </v-form>
</template>

<script>
import useApiCall from "../../use/apiCall";
import validation from "../../use/validations";
import ButtonElement from "../elements/ButtonElement.vue";

export default {
  components: { ButtonElement },
  setup() {
    const { postRawData, postData, getData, putData } = useApiCall();
    return { postData, getData, putData, postRawData };
  },

  data() {
    return {
      loading: false,
      rules: validation,
      file: null,
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true;

      await this.uploadFile();

      this.loading = false;
    },

    async uploadFile() {
      console.log("file", this.file);

      const formData = new FormData();
      formData.append("file", this.file);

      const response = await this.postRawData(
        "nurses/invite-excel",
        formData,
        null,
        true
      );

      if (response) {
        console.log(response);
        this.$store.commit("setFormMessages", {
          type: "success",
          texts: ["Sestra byla úspěšně pozvána."],
        });
        this.$emit("closeDialog");
        this.$emit("fetchUsers");
      }
    },
  },
};
</script>
