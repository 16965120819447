<template>
  <div class="home">
    <PageHero />
    <alert-stripe class="mt-12"></alert-stripe>
    <SectionEducation />
    <SectionDiabetesTools />
    <SectionDownloads />
  </div>
</template>

<script>
import PageHero from "../components/PageHero.vue";
import SectionDownloads from "../components/SectionDownloads.vue";
import SectionEducation from "../components/SectionEducation.vue";
import SectionDiabetesTools from "../components/SectionDiabetesTools.vue";
import AlertStripe from "../components/elements/AlertStripe.vue";

export default {
  components: {
    PageHero,
    SectionEducation,
    SectionDownloads,
    SectionDiabetesTools,
    AlertStripe,
  },
};
</script>
