export default function () {
  function getCzechDate(date) {
    const convertedDate = new Date(date);
    return convertedDate.toLocaleString("cs", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Europe/Prague"
    });
  }

  function getCzechDateWithoutTime(date) {
    const convertedDate = new Date(date);
    return convertedDate.toLocaleString("cs", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      timeZone: "Europe/Prague"
    });
  }

  function getCzechDateMonth(date) {
    const convertedDate = new Date(date);
    return convertedDate.toLocaleString("cs", {
      year: "numeric",
      month: "numeric",
    });
  }

  function getDateToSend(date) {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function getStartOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  function getEndOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  return {
    getEndOfMonth,
    getStartOfMonth,
    getDateToSend,
    getCzechDate,
    getCzechDateMonth,
    getCzechDateWithoutTime
  };
}
