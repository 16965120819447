var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tresiba-detail"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"tresiba-detail__text"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('Human',{staticClass:"human-illustration",attrs:{"fill":"#9CB605"}}):_vm._e(),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),(_vm.$vuetify.breakpoint.mdAndUp)?_c('Earth',{staticClass:"earth-illustration",attrs:{"fill":"#9CB605"}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('Hand',{staticClass:"hand-illustration",attrs:{"fill":"#9CB605"}}):_vm._e(),_vm._m(6)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('Microscope',{staticClass:"microscope-illustration",attrs:{"fill":"#9CB605"}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tresiba-detail__header"},[_c('div',{staticClass:"text-h4 title"},[_vm._v(" Moje léčba inzulinem Tresiba"),_c('sup',[_vm._v("®")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Tresiba"),_c('sup',[_vm._v("®")]),_vm._v(" je dlouhodobě působící bazální inzulin nazývaný inzulin degludek. Používá se k léčbě cukrovky u dospělých, dospívajících a dětí ve věku od 1 roku. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Protože inzulin Tresiba"),_c('sup',[_vm._v("®")]),_vm._v(" má dlouhodobý účinek na snížení hladiny cukru v krvi, užívá se jednou denně a působí po celý den i noc. Ideálně by se měl inzulin Tresiba"),_c('sup',[_vm._v("®")]),_vm._v(" aplikovat vždy ve stejnou denní dobu, vyberte si čas, kdy vám to nejvíce vyhovuje. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Váš lékař vám poradí, kolik jednotek přípravku Tresiba"),_c('sup',[_vm._v("®")]),_vm._v(" každý den aplikovat a jak upravovat velikost dávky v návaznosti na vaše hladiny glukózy v krvi. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Přípravek Tresiba"),_c('sup',[_vm._v("®")]),_vm._v(" lze používat s inzulinovými přípravky s rychlým účinkem aplikovanými k jídlu. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Chcete-li se dozvědět více o léčbě přípravkem Tresiba"),_c('sup',[_vm._v("®")]),_vm._v(", stáhněte si edukační materiály níže nebo si je nechte zaslat na vaši adresu. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"primary--text mt-12 mb-4"},[_vm._v("Návod na pero Flextouch")]),_c('div',[_c('div',{staticStyle:{"padding":"56.25% 0 0 0","position":"relative"}},[_c('iframe',{staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"},attrs:{"src":"https://player.vimeo.com/video/1033415045?h=5d20e17ace&badge=0&autopause=0&player_id=0&app_id=58479","frameborder":"0","allow":"autoplay; fullscreen; picture-in-picture; clipboard-write","title":"Videonávod pro Flextouch"}})])])])
}]

export { render, staticRenderFns }