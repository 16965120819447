import axios from 'axios';

export default async function (batchNumber) {
    try {
        await axios.get('/medicines/validate-batch-numbers', { params: { batchNumbers: batchNumber.toUpperCase() } })
        return true
    } catch (error) {
        console.log(error)
        return false
    }

}
