<template>
  <div class="tresiba-detail">
    <div class="container">
      <div class="tresiba-detail__header">
        <div class="text-h4 title">Moje léčba inzulinem Fiasp<sup>®</sup></div>
      </div>
      <div class="tresiba-detail__text">
        <Human
          v-if="$vuetify.breakpoint.mdAndUp"
          class="human-illustration"
          fill="#01395d"
        />
        <p>
          Fiasp<sup>®</sup> je velmi rychle působící inzulin neboli tzv. inzulin
          k jídlu nebo také bolusový inzulin. Fiasp<sup>®</sup> obsahuje účinnou
          látku insulin aspart a je používán k&nbsp;léčbě cukrovky u dospělých,
          dospívajících a dětí ve věku od 1&nbsp;roku.
        </p>
        <p>
          Fiasp<sup>®</sup> se uvolňuje do vašeho krevního oběhu hned poté, co
          je aplikován a pomáhá tím řídit hladinu glukózy v krvi během a po
          jídle.
        </p>
        <p>
          Přípravek Fiasp<sup>®</sup> se podává spolu s jídlem a má být podán
          nejdříve 2 minuty před začátkem jídla s možností podání nejdéle do 20
          minut od začátku jídla.
        </p>
        <p>
          Váš lékař vám poradí, kolik jednotek přípravku Fiasp<sup>®</sup> k
          jídu aplikovat.
        </p>
        <p>
          Více informací o přípravku Fiasp<sup>®</sup> naleznete v příbalové
          informaci pro pacienty.
        </p>

        <div>
          <h2 class="primary--text mt-12 mb-4">Návod na pero Flextouch</h2>
          <div>
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                src="https://player.vimeo.com/video/1033415045?h=5d20e17ace&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="Videonávod pro Flextouch"
              ></iframe>
            </div>
          </div>
        </div>
        <Earth
          v-if="$vuetify.breakpoint.mdAndUp"
          class="earth-illustration"
          fill="#01395d"
        />
        <Hand
          v-if="$vuetify.breakpoint.mdAndUp"
          class="hand-illustration"
          fill="#01395d"
        />
      </div>
      <Microscope
        v-if="$vuetify.breakpoint.mdAndUp"
        class="microscope-illustration"
        fill="#01395d"
      />
    </div>
  </div>
</template>

<script>
import Earth from "../illustration/Earth.vue";
import Hand from "../illustration/Hand.vue";
import Human from "../illustration/Human.vue";
import Microscope from "../illustration/Microscope.vue";
export default {
  components: { Microscope, Hand, Earth, Human },
};
</script>

<style lang="scss" scoped>
.tresiba-detail {
  &__header {
    display: inline;
    .title {
      display: inline-block;
      margin-bottom: 1rem;
    }
    position: relative;
  }

  &__text {
    position: relative;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    padding: 2rem;
    border-radius: 1rem;
    background-color: white;

    .human-illustration {
      position: absolute;
      right: 22rem;
      top: -9rem;
      max-width: 10rem;
    }
    .earth-illustration {
      position: absolute;
      left: -8.3rem;
      top: 4rem;
    }

    .hand-illustration {
      position: absolute;
      max-width: 10rem;
      right: -7.5rem;
      bottom: -8rem;
    }
  }

  .microscope-illustration {
    max-width: 32rem;
    margin-left: 10rem;
    margin-top: 0.5rem;
  }
}
</style>
