<template>
  <div class="nurses-patient-table mt-4">
    <div class="d-flex align-bottom justify-space-between mb-8">
      <div class="text-h5">Žádosti o propojení s pacienty</div>
      <ButtonElement
        text="Žádost o propojení s pacientem"
        icon="account-plus-outline"
        @click="showDialog = true"
      >
      </ButtonElement>
    </div>
    <InputSearchQuery @search="search"></InputSearchQuery>
    <DataTable
      v-if="requests"
      :loading="loading"
      :headers="headers"
      :items="requests"
      :metadata="metadata"
      @updateFooterOptions="updateFooterOptions"
    >
      <template #item.status="{ item }">
        <v-icon :color="statusMapping[item.status].color" class="mr-2">{{
          statusMapping[item.status].icon
        }}</v-icon>

        {{ statusMapping[item.status].text }}
      </template>
      <template #item.createdAt="{ item }">
        {{ getCzechDate(item.createdAt) }}
      </template>
      <template #item.updatedAt="{ item }">
        {{ getCzechDate(item.updatedAt) }}
      </template>
    </DataTable>

    <AppDialog
      hide-actions
      :showDialog="showDialog"
      title="Zažádat o propojení s pacientem"
      @closeDialog="showDialog = false"
    >
      <div class="mb-8">
        Zadejte e-mail pacienta se kterým se chcete propojit. Na Váš e-mail pak
        zašleme informaci o tom, zda pacient přijal Vaši žádost.
      </div>
      <RequestPatientForm
        @patientRequestSent="patientRequestSent"
      ></RequestPatientForm>
    </AppDialog>
  </div>
</template>

<script>
import ButtonElement from "../elements/ButtonElement.vue";
import DataTable from "../elements/DataTable.vue";
import useApiCall from "../../use/apiCall";
import useFormatDate from "../../use/formatDate";
import InputSearchQuery from "../elements/InputSearchQuery.vue";
import AppDialog from "../elements/AppDialog.vue";
import RequestPatientForm from "../forms/RequestPatientForm.vue";

export default {
  components: {
    ButtonElement,
    DataTable,
    InputSearchQuery,
    RequestPatientForm,
    AppDialog,
  },

  setup() {
    const { getData } = useApiCall();
    const { getCzechDate } = useFormatDate();

    return {
      getData,
      getCzechDate,
    };
  },

  data() {
    return {
      requests: [],
      loading: false,
      showDialog: false,
      patientsRequestParams: {
        skip: 0,
        take: 10,
        searchQuery: null,
      },
      metadata: {
        orderBy: "occurredAtDesc",
      },
      headers: [
        {
          text: "Email pacienta",
          value: "patient.email",
          sortable: false,
        },
        {
          text: "Žádost odeslána",
          value: "createdAt",
          sortable: false,
        },
        {
          text: "Žádost aktualizována",
          value: "updatedAt",
          sortable: false,
        },
        {
          text: "Stav žádosti",
          value: "status",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    statusMapping() {
      return {
        pending: {
          text: "Čeká na potvrzení",
          icon: "mdi-account-clock-outline",
          color: "grey",
        },
        accepted: {
          text: "Přijata",
          icon: "mdi-account-check-outline",
          color: "green",
        },
        rejected: {
          text: "Zamítnuta",
          icon: "mdi-account-cancel-outline",
          color: "red",
        },
      };
    },
  },

  methods: {
    patientRequestSent() {
      this.showDialog = false;
      this.fetchRequests();
    },

    async fetchRequests() {
      this.loading = true;
      const params = new URLSearchParams();

      params.append("skip", this.patientsRequestParams.skip);
      params.append("take", this.patientsRequestParams.take);
      params.append("orderBy", "updatedAtDesc");

      this.patientsRequestParams.searchQuery &&
        params.append("searchQuery", this.patientsRequestParams.searchQuery);

      const { careRequests, metadata } = await this.getData("care-requests", {
        params,
      });
      this.requests = careRequests;
      this.metadata = metadata;
      this.loading = false;
    },

    async updateFooterOptions(event) {
      this.patientsRequestParams.skip = event.page * event.itemsPerPage;
      this.patientsRequestParams.take = event.itemsPerPage;
      await this.fetchRequests();
    },

    async search(query) {
      this.patientsRequestParams.searchQuery = query;
      await this.fetchRequests();
    },

    translateStatus(item) {
      switch (item.status) {
        case "pending":
          return "Čeká na potvrzení";
        case "accepted":
          return "Žádost přijata";
        case "rejected":
          return "Žádost zamítnuta";
        default:
          return "-";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
