<template>
  <div class="case-card">
    <div class="d-flex align-center flex-column flex-md-row">
      <div>
        <h1 class="mb-4">Pouzdro na inzulinové pero</h1>
        <p class="mb-12 primary--text">
          Termoizolační pouzdro na dvě inzulinová pera a náplň. Je vhodný pro
          každodenní použití i na cestování. Obal je lehký, skladný, voděodolný,
          snadno přenositelný, uzavíratelný na zip. Součástí pouzdra je i malá
          síťovaná kapsička na zip, kam můžete uložit své další nezbytnosti.
          Rozměry: 19 x 6,7 x 3,2 cm
        </p>
        <ButtonElement text="Objednat" @click="order('POUZDRONAINZULINPERO')" />
      </div>

      <v-img
        class="mt-8 mt-md-0"
        contain
        width="100%"
        max-width="600"
        src="https://novocare.b-cdn.net/pouzdro/_DSC4835-min-removebg-preview%201.png"
      ></v-img>
    </div>
  </div>
</template>

<script>
import ButtonElement from "./elements/ButtonElement.vue";
export default {
  components: { ButtonElement },
  methods: {
    order(code) {
      const products = this.$store.getters.getProducts;

      const index = products.findIndex((el) => {
        return el.code === code;
      });
      this.$store.commit("setIncreaseProduct", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.case-card {
  background-color: var(--v-secondary-base);
  border-radius: 1rem;
  padding: 2rem;
}
</style>