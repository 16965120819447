<template>
  <div>
    <InputElement
      v-model="search"
      :label="label"
      prepend-inner-icon="mdi-magnify"
      @input="handleSearchInput"
    ></InputElement>
  </div>
</template>

<script>
import InputElement from "./InputElement.vue";

export default {
  components: {
    InputElement,
  },
  data() {
    return {
      search: "",
      debounceTimer: null,
    };
  },
  props: {
    label: {
      type: String,
      default: "Hledat pacienta",
    },
  },
  methods: {
    handleSearchInput() {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(() => {
        this.$emit("search", this.search);
      }, 500); // 5000 milliseconds = 5 seconds
    },
  },
};
</script>

<style></style>
