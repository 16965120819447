<template>
  <v-btn
    class="button-element"
    :color="color"
    depressed
    :loading="loading"
    :text="secondary"
    :block="block"
    @click="$emit('click')"
  >
    <v-icon v-if="icon" left>{{ `mdi-${icon}` }} </v-icon> {{ text }}
  </v-btn>
</template>

<script>
export default {
  name: "ButtonElement",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default: null,
    },
    block: {
      type: String,
      default: null,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.button-element.v-btn {
  border-radius: 0;
  padding: 1.5rem 2rem;
  width: 100%;
}

@media screen and (min-width: 960px) {
  .button-element.v-btn {
    width: auto;
  }
}
</style>
