<template>
  <div class="">
    <v-autocomplete
      v-model="selectedMedicine"
      @input="$emit('input', $event)"
      outlined
      solo
      flat
      clearable
      :rules="rules"
      item-text="displayName"
      item-value="id"
      :items="medicines"
      label="Název léku"
      required
    ></v-autocomplete>
  </div>
</template>

<script>
import useApiCall from "@/use/apiCall";

export default {
  setup() {
    const { getData } = useApiCall();

    return {
      getData,
    };
  },
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    medicineId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      selectedMedicine: null,
      medicines: [],
    };
  },

  watch: {
    // Watch for changes in medicineId and update selectedMedicine accordingly
    medicineId(newId) {
      this.selectedMedicine = newId;
    },
  },

  async mounted() {
    await this.getData("medicines").then((response) => {
      this.medicines = response.medicines;
      // Set initial selected medicine based on the provided medicineId
      if (this.medicineId) {
        this.selectedMedicine = this.medicineId;
      }
    });
  },
};
</script>

<style lang="scss" scoped></style>
