import store from '../store/index';
import router from '../router';

export default function () {
  const tokenExpiration = new Date(store.getters.getTokenExpiration);
  const today = new Date();
  const validToken = today < tokenExpiration;

  store.commit('setLoginAgainText', null);  

  if (store.state.userInfo && !validToken && router.history.current.meta !== 'unAuthorized') {
    router.push({ name: 'Login' });
    store.commit(
      'setLoginAgainText',
      'Byl jste automaticky odhlášen prosím přihlašte se znovu'
    );
  }
  return validToken;
}
