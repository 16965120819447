import { render, staticRenderFns } from "./WrongProduct.vue?vue&type=template&id=2115dda4&scoped=true&"
import script from "./WrongProduct.vue?vue&type=script&lang=js&"
export * from "./WrongProduct.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2115dda4",
  null
  
)

export default component.exports