<template>
  <vue-pdf-app :pdf="source"></vue-pdf-app>

  <!-- <iframe
      src="https://novocare.b-cdn.net/saxenda/Krok%20za%20krokem.pdf"
      width="100%"
      height="100%"
      frameborder="0"
    ></iframe> -->
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

export default {
  components: {
    VuePdfApp,
  },

  data() {
    return {
      source: "../../files/pdfs/krok-za-krokem.pdf",
    };
  },
};
</script>

<style lang="scss" scoped>
.pdf-wrapper {
  max-height: 100vh;
  max-width: 60rem;
  margin: auto;
  overflow-x: scroll;
}
</style>
