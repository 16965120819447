<template>
  <div class="app-dialog">
    <v-dialog v-model="showDialog" max-width="700" persistent>
      <div class="white pa-8">
        <div class="text-right">
          <v-btn icon @click="$emit('closeDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="text-h5 mb-6">{{ title }}</div>
        <div class="mb-4">
          <slot></slot>
        </div>

        <div v-if="!hideActions" class="text-right">
          <ButtonElement
            text="Zrušit"
            class="mr-2"
            secondary
            @click="$emit('closeDialog')"
          ></ButtonElement>
          <ButtonElement :text="primaryButtonText"></ButtonElement>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement.vue";

export default {
  components: {
    ButtonElement,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Dialog",
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    primaryButtonText: {
      type: String,
      default: "Ok",
    },
  },
};
</script>

<style></style>
