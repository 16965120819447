<template>
  <div class="tresiba-detail">
    <div class="container">
      <div class="tresiba-detail__header">
        <div class="text-h4 title">
          Moje léčba přípravkem Rybelsus<sup>®</sup>
        </div>
      </div>
      <div class="tresiba-detail__text">
        <Human
          v-if="$vuetify.breakpoint.mdAndUp"
          class="human-illustration"
          fill="#292c70"
        />
        <p>
          RYBELSUS<sup>®</sup> je jednou denně užívaná tableta pro dospělé s
          diabetem 2. typu, která vám v kombinaci se zdravou stravou a pohybem
          může pomoci dosáhnout vašich cílů.
        </p>
        <p>
          RYBELSUS<sup>®</sup> je perorální přípravek pro léčbu diabetu 2. typu,
          který funguje podobně jako přírodní hormon GLP-1, jenž se do těla
          vyplavuje po jídle. Pomáhá vašemu tělu regulovat hladinu cukru v krvi,
          pokud je příliš vysoká, a snižovat hmotnost.
        </p>
        <div class="primary--text text-h6 mb-4">
          Výhody přípravku RYBELSUS<sup>®</sup>
        </div>
        <div>
          <ul class="mb-4">
            <li>
              snížení hladiny cukru v krvi, které vám umožní dosáhnout vaší
              cílové hladiny HbA1c
            </li>
            <li>úspěšnější hubnutí.</li>
          </ul>
        </div>
        <p>
          Přípravek RYBELSUS<sup>®</sup> se užívá nalačno, nejlépe hned po
          probuzení a zapije se douškem vody (max. 120 ml). Předtím než budete
          jíst, pít či užívat jiné léky je potřeba vyčkat 30 minut.
        </p>
        <p>
          Chcete-li vědět více o přínosech vaší léčby a správném užívání
          léčivého přípravku RYBELSUS<sup>®</sup>, stáhněte si níže uvedené
          materiály nebo si je nechte poslat domů poštou.
        </p>
        <v-row>
          <v-col cols="12" md="6">
            <div class="primary--text text-h6 mb-4">
              Dávkování přípravku RYBELSUS<sup>®</sup>
            </div>
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                src="https://player.vimeo.com/video/859272775?h=d7070264f8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="RYBELSUS DÁVKOVÁNÍ PŘÍPRAVKU RYBELSUS"
              ></iframe>
            </div>
          </v-col>

          <v-col>
            <div class="primary--text text-h6 mb-4">
              Začněte svůj den správně
            </div>
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                src="https://player.vimeo.com/video/859272722?h=054756c041&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="RYBELSUS_ZAČNĚTE SVUJ DEN SPRÁVNĚ (2)"
              ></iframe>
            </div>
          </v-col>
        </v-row>

        <Earth
          v-if="$vuetify.breakpoint.mdAndUp"
          class="earth-illustration"
          fill="#292c70"
        />
        <Hand
          v-if="$vuetify.breakpoint.mdAndUp"
          class="hand-illustration"
          fill="#292c70"
        />
      </div>

      <Microscope
        v-if="$vuetify.breakpoint.mdAndUp"
        class="microscope-illustration"
        fill="#292c70"
      />

      <div class="relative d-md-flex mobile-app-banner mt-12">
        <v-img
          src="@/assets/img/novocare-mobile-app-ryblesus-screen.png"
          max-width="350"
          class="ma-auto"
        ></v-img>

        <div>
          <div
            class="pa-md-8 pa-4 mobile-app-banner__text primary--text mt-4 ml-md-4"
          >
            <h2 class="mb-4">Mobilní aplikace NovoCare</h2>
            <div class="mb-4">
              S mobilní aplikací NovoCare můžete mít svou léčbu diabetu pod
              kontrolou. Zjistěte, jak vám může aplikace pomoci.
            </div>

            <div class="mb-4 font-weight-bold">Praktický pomocník do kapsy</div>
            <div>Mezi hlavní funkce patří:</div>
            <ul class="my-4">
              <li>Nastavení užívané dávky a připomenutí</li>
              <li>Sledování cílů (hmotnost, kroky, glykovaný hemoglobin)</li>
              <li>Záznam o užití léku</li>
              <li>Průvodce léčbou a často kladené dotazy</li>
            </ul>

            <v-divider class="mb-4"></v-divider>

            <div class="mb-4">
              Naskenováním QR kódu si můžete stáhnout aplikaci do svého
              mobilního telefonu.
            </div>

            <div class="d-md-flex mb-4">
              <div class="mr-md-8 mb-12 mb-md-0">
                <v-img
                  class="ma-auto mb-2"
                  max-width="200"
                  src="../../assets/img/qr-app-store.png"
                ></v-img>

                <div>
                  <a
                    href="https://apps.apple.com/cz/app/novocare-cz/id6499024041"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <v-btn
                      color="primary"
                      depressed
                      :small="$vuetify.breakpoint.mdAndUp"
                      :block="$vuetify.breakpoint.mdAndDown"
                    >
                      Stáhnout aplikaci pro iOS</v-btn
                    >
                  </a>
                </div>
              </div>

              <div>
                <v-img
                  class="ma-auto mb-2"
                  max-width="200"
                  src="../../assets/img/qr-google-play.png"
                ></v-img>
                <div class="font-weight-bold">
                  <a
                    href="https://play.google.com/store/apps/details?id=cz.tmesolutions.novocareapp&hl=cs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <v-btn
                      color="primary"
                      depressed
                      :small="$vuetify.breakpoint.mdAndUp"
                      :block="$vuetify.breakpoint.mdAndDown"
                    >
                      Stáhnout aplikaci pro Android
                    </v-btn>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Earth from "../illustration/Earth.vue";
import Hand from "../illustration/Hand.vue";
import Human from "../illustration/Human.vue";
import Microscope from "../illustration/Microscope.vue";

export default {
  components: { Microscope, Hand, Earth, Human },
};
</script>

<style lang="scss" scoped>
.mobile-app-banner {
  &__text {
    border-radius: 1rem 1rem 1rem 1rem;
    background-color: #e9f4fe;
  }
}
.tresiba-detail {
  &__header {
    display: inline;

    .title {
      display: inline-block;
      margin-bottom: 1rem;
    }

    position: relative;
  }

  &__text {
    position: relative;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    padding: 2rem;
    border-radius: 1rem;
    background-color: white;

    .human-illustration {
      position: absolute;
      right: 22rem;
      top: -9rem;
      max-width: 10rem;
    }

    .earth-illustration {
      position: absolute;
      left: -8.3rem;
      top: 4rem;
    }

    .hand-illustration {
      position: absolute;
      max-width: 10rem;
      right: -7.5rem;
      bottom: -8rem;
    }
  }

  .microscope-illustration {
    max-width: 32rem;
    margin-left: 10rem;
    margin-top: 0.5rem;
  }

  @media screen and (max-width: 960px) {
    .mobile-app-banner {
      &__text {
        border-radius: 0 0 0 0;
      }
    }
  }
}
</style>
