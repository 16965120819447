<template>
  <div class="tresiba-detail">
    <div class="container">
      <div class="tresiba-detail__header">
        <div class="text-h4 title">
          Moje léčba přípravkem Ozempic<sup>®</sup>
        </div>
      </div>
      <div class="tresiba-detail__text">
        <Human
          v-if="$vuetify.breakpoint.mdAndUp"
          class="human-illustration"
          fill="#e14616"
        />
        <p>
          Přípravek Ozempic<sup>®</sup> je lék ze skupiny GLP-1 a je určený pro
          léčbu cukrovky u dospělých s cukrovkou 2. typu.
        </p>
        <p>
          Přípravek Ozempic<sup>®</sup> je podobný přírodnímu, tělem vyráběnému
          hormonu, který se v organismu uvolňuje po jídle. Pomáhá snižovat
          hladinu cukru v krvi, pokud je příliš vysoká a může pomoci předcházet
          srdečnímu onemocnění.
        </p>
        <p>
          Přípravek Ozempic<sup>®</sup> se aplikuje pomocí pera jednou týdně
          vždy ve stejný den. Lze jej aplikovat nezávisle na jídle.
        </p>
        <p>
          Chcete-li vědět více o přínosech léčby a správném užívání přípravku
          Ozempic<sup>®</sup>, stáhněte si materiály níže nebo si je nechte zaslat domů.
        </p>
        <Earth
          v-if="$vuetify.breakpoint.mdAndUp"
          class="earth-illustration"
          fill="#e14616"
        />
        <Hand
          v-if="$vuetify.breakpoint.mdAndUp"
          class="hand-illustration"
          fill="#e14616"
        />
      </div>
      <Microscope
        v-if="$vuetify.breakpoint.mdAndUp"
        class="microscope-illustration"
        fill="#e14616"
      />
    </div>
  </div>
</template>

<script>
import Earth from "../illustration/Earth.vue";
import Hand from "../illustration/Hand.vue";
import Human from "../illustration/Human.vue";
import Microscope from "../illustration/Microscope.vue";
export default {
  components: { Microscope, Hand, Earth, Human },
};
</script>

<style lang="scss" scoped>
.tresiba-detail {
  &__header {
    display: inline;
    .title {
      display: inline-block;
      margin-bottom: 1rem;
    }
    position: relative;
  }

  &__text {
    position: relative;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    padding: 2rem;
    border-radius: 1rem;
    background-color: white;

    .human-illustration {
      position: absolute;
      right: 22rem;
      top: -9rem;
      max-width: 10rem;
    }
    .earth-illustration {
      position: absolute;
      left: -8.3rem;
      top: 4rem;
    }

    .hand-illustration {
      position: absolute;
      max-width: 10rem;
      right: -7.5rem;
      bottom: -8rem;
    }
  }

  .microscope-illustration {
    max-width: 32rem;
    margin-left: 10rem;
    margin-top: 0.5rem;
  }
}
</style>
