<template>
  <div class="my-12">
    <LoginPatient v-if="loginUserType === 'patient'" />
    <LoginNurse v-else />
  </div>
</template>

<script>
import LoginNurse from "@/views/Login/Nurse.vue";
import LoginPatient from "@/views/Login/Patient.vue";

export default {
  components: { LoginNurse, LoginPatient },
  data() {
    return {
      activeButton: 0,
      userTypes: [
        {
          icon: "account",
          name: "Patient",
          title: "Pacient",
        },
        {
          icon: "doctor",
          title: "Zdravotní sestra",
        },
      ],
    };
  },

  computed: {
    loginUserType() {
      return this.$store.getters.getLoginUserType;
    },
  },

  methods: {
    makeButtonActive(index) {
      this.activeButton = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-type-container {
  max-width: 20rem;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.user-type-button {
  background: white;
  margin: auto;
  width: 3rem;
  border-radius: 50%;
  height: 3rem;
  border: 1px solid var(--v-primary-base);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  transition: 0.5s;

  &.active-button {
    background: var(--v-primary-base);
    color: white;
  }
}
</style>
