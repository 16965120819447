<template>
  <div class="about-diabetes page">
    <div class="about-diabetes__background"></div>
    <img
      width="30"
      src="../assets/img/illustrations/bulp.png"
      class="bulp-illustration"
    />
    <div class="container">
      <SectionHeader
        title="O cukrovce"
        text="Cukrovka je v současné době onemocnění, které se nedá vyléčit, ale můžete ho poměrně dobře léčit. Čím více toho o ní víte, tím lépe ji budete zvládat. Tento web je průvodcem pro nemocné a jejich nejbližší na cestě za zdravějším a spokojenějším životem s cukrovkou."
      />
      <div class="card-wrapper">
        <img
          v-if="$vuetify.breakpoint.mdAndUp"
          width="100"
          src="../assets/img/illustrations/human.svg"
          cover
          class="human-illustration"
        />
        <v-row justify="center">
          <v-col
            v-for="educationCard in educationCards"
            :key="educationCard.title"
          >
            <div class="card ma-auto">
              <v-img
                class="grey"
                width="100%"
                height="250"
                :src="educationCard.img"
              >
              </v-img>
              <div class="pa-6">
                <div class="card-title">
                  <h3>{{ educationCard.title }}</h3>
                </div>
                <div
                  class="card-text primary--text"
                  v-html="educationCard.text"
                />
                <div class="card-button">
                  <ButtonElement
                    text="Více"
                    color="secondary"
                    class="primary--text"
                    @click="$router.push({ name: educationCard.route })"
                  />
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from "../components/elements/ButtonElement.vue";
import SectionHeader from "../components/SectionHeader.vue";
export default {
  components: { SectionHeader, ButtonElement },
  data() {
    return {
      educationCards: [
        {
          title: "O cukrovce 1. typu",
          route: "DiabetesTypeOne",
          img: "https://novocare.b-cdn.net/HP/Cukrovka%201%201.png",
          text: "<p>Cukrovka 1. typu je obvykle diagnostikována u dětí nebo mladých dospělých.</p><p>Znamená, že tělo produkuje velmi malé nebo žádné množství inzulinu.</p>",
        },
        {
          title: "O cukrovce 2. typu",
          route: "DiabetesTypeTwo",

          img: "https://novocare.b-cdn.net/HP/Cukrovka%202.png",
          text: "<p>Tělo nevytváří dostatek inzulinu nebo na něj nereaguje správně.</p><p>Cukrovka 2. typu je diagnostikována u lidí pokročilého a středního věku. Díky růstu obezity se však stále častěji objevuje už u i v dětském věku.</p>",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.about-diabetes {
  background: var(--v-primary-base);
  height: 100%;
  position: relative;
  &__background {
    background: var(--v-bgLight-base);
    height: 40rem;
    width: 100%;
    position: absolute;
    left: 0;
    top: -5rem;
  }

  .bulp-illustration {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: -3rem;
  }

  .card-wrapper {
    position: relative;

    .human-illustration {
      position: absolute;
      left: -3rem;
      top: -4rem;
    }
  }

  .card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    max-width: 30rem;
    background: white;
    min-height: 35rem;

    &-title {
      color: var(--v-primary-base);
    }

    &-text {
      margin: 1rem 0;
      min-height: 9rem;
    }
  }
}
</style>