<template>
  <div class="recovery-mail mb-12">
    <div class="recovery-mail__card">
      <h1 class="white--text text-center mb-4">Resetovat heslo</h1>
      <div class="divider"></div>
      <RecoveryMailForm />
      <div class="d-flex align-center mt-8">
        <v-icon class="white--text" left>mdi-chevron-left</v-icon>
        <router-link to="/prihlaseni" class="white--text d-block"
          >Zpět na přihlášení</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import RecoveryMailForm from "../components/forms/RecoveryMailForm.vue";
export default {
  components: { RecoveryMailForm },
};
</script>

<style lang="scss" scoped>
.recovery-mail {
  &__card {
    position: relative;
    background: var(--v-primary-base);
    padding: 4rem;
    max-width: 35rem;
    margin: auto;
    margin-top: 5rem;
  }

  .divider {
    border: 2px solid var(--v-accent-base);
    margin: 1rem auto 2rem;
    max-width: 10rem;
  }
}
</style>
