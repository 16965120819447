<template>
  <div class="form-message" v-if="this.messages">
    <v-alert :value="alertValue" transition="scale-transition" :type="messages.type" dismissible close-icon="mdi-close"
      prominent class="mb-6">
      <div v-for="(item, i) in messages.texts" :key="i">
        <div v-html="item">
        </div>
      </div>
    </v-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      alertValue: false,
      timeOut: null,
    };
  },
  computed: {
    messages() {
      return this.$store.state.formMessages;
    },
  },

  watch: {
    messages() {
      if (this.messages?.texts?.length) {
        this.alertValue = true;
        if (this.timeOut) {
          clearTimeout(this.timeOut);
        }

        this.timeOut = setTimeout(() => {
          this.alertValue = false;
          this.timeOut = null;
        }, 6000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-message {
  position: fixed;
  right: 0;
  max-width: 25rem;
  z-index: 1000;
}
</style>