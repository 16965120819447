import useApiCall from "@/use/apiCall";
import productsContent from "@/views/Products/ProductsContent";
import store from "../store";

export default function () {
    const { getData } = useApiCall();
    async function listMedicines() {
        const response = await getData("medicines");
        if (!response) {
            return;
        }
        const medicines = response.medicines;


        medicines.forEach((medicine) => {
            productsContent.find((content) => {
                if (content.name === medicine.displayName) {
                    Object.assign(medicine, { ...content });
                }
            });
        });

        const storeMedicines = medicines.map((medicine) => {
            return { id: medicine.id, displayName: medicine.displayName };
        });

        store.commit("setMedicines", storeMedicines);
        if (store.getters.getCheckoutItems === 0) {
            store.dispatch("fetchProducts");
        }
        return medicines;
    }

    return {
        listMedicines,
    };

}