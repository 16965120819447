<template>
  <div class="page-header">
    <v-app-bar color="transparent" app elevate-on-scroll height="100">
      <div class="container d-flex align-center">
        <div class="text-h5 logo" @click="$router.push({ name: 'Home' })">
          <span class="primary--text font-weight-bold">Novo</span
          ><span class="accent--text">Care</span>
        </div>
        <v-spacer></v-spacer>
        <div v-if="$vuetify.breakpoint.mdAndUp">
          <v-btn
            class="pa-6 ml-2"
            v-for="menuItem in menuItems"
            :key="menuItem.name"
            text
            depressed
            color="primary"
            @click="
              menuItem.redirect
                ? $router.push({ name: menuItem.redirect })
                : menuItem.action()
            "
          >
            <div class="text-center">
              <v-icon v-if="menuItem.icon" left>{{
                `mdi-${menuItem.icon}`
              }}</v-icon>
              <strong> {{ menuItem.name }}</strong>

              <div v-html="menuItem.description"></div>
            </div>
          </v-btn>

          <v-badge
            :value="$store.getters.getCheckoutItems"
            class="ml-4"
            v-if="isAuthorized"
            bordered
            color="primary"
            :content="$store.getters.getCheckoutItems"
            overlap
          >
            <v-btn
              v-if="!isNurse"
              color="accent"
              depressed
              class="pa-6"
              @click="redirecToOrders"
            >
              {{ isAdmin ? "Objednávky" : "Objednávka" }}
            </v-btn>
          </v-badge>

          <v-btn
            v-if="isAdmin"
            class="pa-6 ml-4"
            color="primary"
            depressed
            @click="$router.push({ name: 'Stock' })"
          >
            Sklad
          </v-btn>
          <v-btn
            v-if="isAdmin"
            class="pa-6 ml-4"
            color="primary"
            depressed
            @click="$router.push({ name: 'Users' })"
          >
            Uživatelé
          </v-btn>

          <v-menu v-if="isAuthorized" offset-y :nudge-left="70">
            <template v-slot:activator="{ on }">
              <v-btn icon class="ml-4" color="primary" v-on="on">
                <v-icon>mdi-account-outline</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link @click="$router.push({ name: 'UserSetting' })">
                <v-list-item-title>Nastavení účtu</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isPatient"
                link
                @click="$router.push({ name: 'OrderList' })"
              >
                <v-list-item-title>Moje objednávky</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="logOut">
                <v-list-item-title>Odhlásit se</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-app-bar-nav-icon
          v-else
          @click.stop="$emit('clickOnNavIcon')"
        ></v-app-bar-nav-icon>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import menuItems from "@/data/menuItems";

export default {
  computed: {
    isAdmin() {
      return this.$store.getters.getUserRole === "admin";
    },
    isNurse() {
      return this.$store.getters.getUserRole === "nurse";
    },
    isPatient() {
      return (
        this.$store.getters.getUserRole === "patient" ||
        this.$store.getters.getBatchNumbers?.length > 0
      );
    },
    role() {
      return this.$store.getters.getUserRole;
    },
    isAuthorized() {
      return this.$store.getters.getIsAuthorized;
    },
    isLoggedInUser() {
      return this.$store.getters.getUser && !this.isAdmin;
    },
    menuItems() {
      if (this.isAdmin) {
        return menuItems.admin;
      } else if (this.isPatient) {
        return menuItems.patient;
      } else if (this.isNurse) {
        return menuItems.nurse;
      } else {
        return menuItems.public;
      }
    },
  },

  methods: {
    logOut() {
      this.$store.commit("setDefaultState");
      this.$router.push({ name: "Home" });
    },

    redirecToOrders() {
      this.isAdmin
        ? this.$router.push({ name: "OrderList" })
        : this.$router.push({ name: "Order" });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  ::v-deep .theme--light.v-app-bar.v-app-bar--is-scrolled {
    background-color: white !important;
  }

  .logo {
    cursor: pointer;
  }

  ::v-deep &__btn {
    padding: 1rem;
  }
}
</style>
