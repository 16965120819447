<template>
  <div v-if="startDate" class="refund-table pa-2">
    <div class="wrapper">
      <v-row class="text-h4 primary--text font-weight-bold">
        <v-col>
          <div class="text-center">
            {{ `${firstYear ? "1" : "2"}. rok léčby` }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="month in period" :key="month.name" cols="1" class="pa-1 pb-0">
          <div class="grey darken-1 white--text pa-2 text-uppercase text-center mb-2">
            <small>{{ month.name }}</small>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="quarter in quarters" :key="quarter.id" :cols="getColsNumber(quarter)" class="pa-1 pt-0">
          <div class="grey lighten-3 pa-0 text-center">
            <small> {{ `${quarter.id / 3}. čtvrtletí` }}</small>
          </div>
        </v-col>
      </v-row>
      <v-row class="cost-row">
        <div class="label primary--text">ZAPLACENO</div>
        <v-col v-for="cost in costs" :key="cost.d" cols="1" class="pa-1">
          <div class="primary white--text pa-2 text-center">{{ cost }},-</div>
        </v-col>
        <v-col v-for="quarter in quarters" :key="quarter.r" :cols="getColsNumber(quarter)" class="pa-1 pt-0">
          <div class="primary--text pa-2 text-center text-h5 quarter-divider">
            {{ quarter.sumCosts }},-
          </div>
        </v-col>
      </v-row>
      <v-row class="payment-row">
        <div class="label accent--text">ZAPOČITATELNÝ DOPLATEK</div>
        <v-col v-for="payment in payments" :key="payment.s" cols="1" class="pa-1">
          <div class="secondary accent--text pa-2 text-center">
            {{ payment.value }},-
          </div>
        </v-col>
        <v-col v-for="quarter in quarters" :key="quarter.r" :cols="getColsNumber(quarter)" class="pa-1 pt-0">
          <div class="accent--text pa-2 text-center text-h4 quarter-divider d-flex align-center justify-center">
            <div class="py-2 mr-2">{{ quarter.refund }},-</div>
            <v-img v-if="quarter.refund > 0"
              src="https://general-storage.b-cdn.net/Icons/wired-outline-453-savings-pig.gif" max-width="55"></v-img>
          </div>
          <div v-if="quarter.refund > 0" class="accent--text text-center pa-4">
            Přeplatek bude vyplacen {{ getRefundDate(quarter) }}
            {{ quarter.refundYear ? quarter.refundYear : startYear }}
          </div>
        </v-col>
      </v-row>

      <div class="mt-8">
        <v-row class="text-h4 pa-4 white--text text-center primary">
          <v-col> Skutečně zaplatíte za tuto moderní léčbu </v-col>
        </v-row>
        <v-row class="white--text font-weight-bold text-h5">
          <v-col class="text-center accent">
            {{ firstYear ? "1." : "2." }} rok /
            <span class="text-h4 font-weight-bold">{{ totalCosts - totalRefund }},-</span>
          </v-col>
          <v-col class="text-center accent">
            měsíc /
            <span class="text-h4 font-weight-bold">{{ ((totalCosts - totalRefund) / 12).toFixed(0) }},-
            </span>
          </v-col>
          <v-col class="text-center accent">
            den /
            <span class="text-h4 font-weight-bold">{{ ((totalCosts - totalRefund) / 365).toFixed(0) }},-</span>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col>
            <v-divider class="mb-4"></v-divider>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    insuredType: {
      type: String,
      default: null,
    },
    startDate: {
      type: String,
      default: null,
    },
    firstYear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      startMonth: null,
      monthNumber: null,
      period: [],
      costs: [],
      payments: [],
      sumCosts: 0,
      sumPayments: 0,
      sumRefunds: 0,
      quarters: [],
      totalCosts: 0,
      totalRefund: 0,
    };
  },
  methods: {
    getMonth(date) {
      return new Date(date).toLocaleString("cs", {
        month: "long",
      });
    },

    generatePeriod() {
      this.period = [];
      this.payments = [];

      for (let i = 0; i < 12; i++) {
        const startDate = new Date(this.startDate);
        const newDate = startDate.setMonth(startDate.getMonth() + i);

        this.monthNumber = new Date(newDate).getMonth() + 1;
        const formattedDate = this.getMonth(newDate);
        this.period.push({
          name: formattedDate,
          id: this.monthNumber,
          endIndex: i,
          date: startDate,
        });
        this.payments.push({ id: this.monthNumber });
      }

      this.quarters = this.period.filter((e) => {
        if (e.id % 3 === 0) {
          return e;
        }
      });

      const lastCalculatedDateObject = this.quarters[this.quarters.length - 1];

      const lastCalculatedDate = new Date(lastCalculatedDateObject.date);
      const nextDateForCalculation = new Date(
        lastCalculatedDate.setMonth(lastCalculatedDate.getMonth() + 1)
      );

      const periodEndYear = new Date(nextDateForCalculation).getFullYear();
      const formattedPeriodEndMonth = new Date(
        nextDateForCalculation
      ).toLocaleString("cs", {
        month: "2-digit",
      });

      const periodEndDate = `${periodEndYear}-${formattedPeriodEndMonth}`;
      this.$emit("periodEndDate", periodEndDate);
    },

    getCostsAndPayments() {
      this.costs = [];
      if (this.firstYear) {
        for (let i = 0; i < 12; i++) {
          switch (i) {
            case 0:
              this.costs.push(970);
              this.payments[i].value = 517;
              break;
            case 1:
              this.costs.push(2060);
              this.payments[i].value = 1207;
              break;
            default:
              this.costs.push(2180);
              this.payments[i].value = 2180;
              break;
          }
        }
      } else {
        for (let i = 0; i < 12; i++) {
          this.costs.push(2180);
          this.payments[i].value = 2180;
        }
      }
    },

    async generateRefundTable() {
      this.startMonth = await this.getMonth(this.startDate);
      await this.generatePeriod();
      await this.getCostsAndPayments();
      await this.calculateSumPayments();
      await this.calculateRefund();
      await this.calculateTotalCosts();
      await this.calculateTotalRefunds();
    },

    getColsNumber(quarter) {
      if (quarter.endIndex < 3) {
        quarter.startIndex = 0;
        return quarter.endIndex + 1;
      } else {
        quarter.startIndex = quarter.endIndex - 2;
        return 3;
      }
    },

    getRefundDate(quarter) {
      const quarterNumber = quarter.id / 3;
      switch (quarterNumber) {
        case 1:
          return "nejpozději 30.5.";
        case 2:
          return "nejpozději 29.8.";
        case 3:
          return "nejpozději 29.11.";
        case 4:
          return "nejpozději 1.3.";
        default:
          return "";
      }
    },

    calculateSumPayments() {
      this.quarters.forEach((quarter) => {
        this.sumPayments = 0;
        this.sumCosts = 0;
        for (let i = quarter.startIndex; i <= quarter.endIndex; i++) {
          this.sumPayments += this.payments[i].value;
          this.sumCosts += this.costs[i];
          this.$set(quarter, "sumPayments", this.sumPayments);
          this.$set(quarter, "sumCosts", this.sumCosts);
        }
      });
    },

    calculateTotalCosts() {
      this.totalCosts = 0;
      for (let i = 0; i < this.quarters.length; i++) {
        this.totalCosts += this.quarters[i].sumCosts;
      }
    },

    calculateTotalRefunds() {
      this.totalRefund = 0;
      for (let i = 0; i < this.quarters.length; i++) {
        this.totalRefund += this.quarters[i].refund;
      }
    },

    calculateRefund() {
      this.sumRefunds = 0;
      const year = new Date(this.startDate).getFullYear();

      for (let i = 0; i < this.quarters.length; i++) {
        if (this.sumRefunds < this.insuranceType && this.firstYear) {
          this.sumRefunds += this.quarters[i].sumPayments;
          const refund =
            this.sumRefunds - this.insuranceType < 0
              ? 0
              : this.sumRefunds - this.insuranceType;
          this.$set(this.quarters[i], "refund", refund);
        } else if (this.quarters[i].id / 3 === 1) {
          this.sumRefunds += this.quarters[i].sumPayments;
          const refund =
            this.sumRefunds - this.insuranceType < 0
              ? 0
              : this.sumRefunds - this.insuranceType;
          this.$set(this.quarters[i], "refund", refund);
        } else {
          this.$set(this.quarters[i], "refund", this.quarters[i].sumPayments);
        }
        if (i !== 0 && this.quarters[i - 1].id > this.quarters[i].id) {
          this.sumRefunds = 0;
          this.sumRefunds += this.quarters[i].sumPayments;
          const refund =
            this.sumRefunds - this.insuranceType < 0
              ? 0
              : this.sumRefunds - this.insuranceType;

          this.$set(this.quarters[i], "refund", refund);
        }

        if (this.quarters[i].id / 3 === 4) {
          this.$set(this.quarters[i], "refundYear", year + 1);
        }

        if (i !== 0 && this.quarters[i - 1].refundYear) {
          this.$set(this.quarters[i], "refundYear", year + 1);
        }
      }
    },
  },

  computed: {
    startYear() {
      return new Date(this.startDate).getFullYear();
    },
    insuranceType() {
      switch (this.insuredType) {
        case "between18and65":
          return 5000;
        case "between65adn69":
          return 1000;
        case "above70":
          return 500;
        case "ZTP":
          return 500;
        default:
          return 5000;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  li {
    margin-bottom: 1rem;
    color: var(--v-primary-base);
  }
}

.cost-row {
  position: relative;

  .label {
    position: absolute;
    left: -7rem;
    top: 1rem;
  }
}

.payment-row {
  position: relative;

  .label {
    position: absolute;
    left: -14rem;
    top: 1rem;
  }
}

.summary-box {
  background: #143774;
}

.quarter-divider {
  border-right: 3px dotted gainsboro;
  white-space: nowrap;
  margin-right: -0.3rem;
}

@media screen and (max-width: (960px)) {
  .refund-table {
    overflow-x: scroll;

    .wrapper {
      width: 70rem;
    }
  }
}
</style>
