var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tresiba-detail"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"tresiba-detail__text"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('Human',{staticClass:"human-illustration",attrs:{"fill":"#0083c7"}}):_vm._e(),_vm._m(1),_c('p',[_vm._v("Obsahuje dva typy inzulinu v jednom peru:")]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),(_vm.$vuetify.breakpoint.mdAndUp)?_c('Earth',{staticClass:"earth-illustration",attrs:{"fill":"#0083c7"}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('Hand',{staticClass:"hand-illustration",attrs:{"fill":"#0083c7"}}):_vm._e()],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('Microscope',{staticClass:"microscope-illustration",attrs:{"fill":"#0083c7"}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tresiba-detail__header"},[_c('div',{staticClass:"text-h4 title"},[_vm._v(" Moje léčba přípravkem Ryzodeg"),_c('sup',[_vm._v("®")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Přípravek Ryzodeg"),_c('sup',[_vm._v("®")]),_vm._v(" je určen pro léčbu cukrovky u dospělých, dospívajících a dětí od 2 let. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"mb-4"},[_c('li',[_vm._v(" inzulin degludek – bazální inzulin, který má dlouhodobý účinek na snižování hladiny krevního cukru během dne a noci ")]),_c('li',[_vm._v(" inzulin aspart – inzulin s rychlým účinkem tzv. inzulin k jídlu, který snižuje vaši hladinu cukru v krvi po aplikaci ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Přípravek Ryzodeg"),_c('sup',[_vm._v("®")]),_vm._v(" se podává jednou nebo dvakrát denně s hlavním jídlem (jídly) dne. Váš lékař vám dle vašich individuálních potřeb poradí, kdy a jak přípravek Ryzodeg"),_c('sup',[_vm._v("®")]),_vm._v(" aplikovat. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Více informací o přípravku Ryzodeg"),_c('sup',[_vm._v("®")]),_vm._v(" naleznete v příbalové informaci pro pacienty. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"primary--text mt-12 mb-4"},[_vm._v("Návod na pero Flextouch")]),_c('div',[_c('div',{staticStyle:{"padding":"56.25% 0 0 0","position":"relative"}},[_c('iframe',{staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"},attrs:{"src":"https://player.vimeo.com/video/1033415045?h=5d20e17ace&badge=0&autopause=0&player_id=0&app_id=58479","frameborder":"0","allow":"autoplay; fullscreen; picture-in-picture; clipboard-write","title":"Videonávod pro Flextouch"}})])])])
}]

export { render, staticRenderFns }