<template>
  <v-row justify="center" class="mt-12 mb-12">
    <v-col md="3">
      <SectionHeader
        title="Dokončení registrace"
        text="<p>Pro dokončení registrace zadejte ověřovací kód, který jsme Vám zaslali na Váš email</p>"
      />
      <v-otp-input length="4" @finish="otpFinish" color="primary"></v-otp-input>
    </v-col>
  </v-row>
</template>

<script>
import SectionHeader from "@/components/SectionHeader.vue";
import useApiCall from "../use/apiCall";

export default {
  components: {
    SectionHeader,
  },

  setup() {
    const { postData } = useApiCall();

    return {
      postData,
    };
  },

  methods: {
    async otpFinish(otp) {
      const response = await this.postData("/users/activation/submit-code", {
        code: otp,
      });

      if (response) {
        this.$store.commit("setUserInfo", response);
        this.$router.push({
          name: "Products",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
