import { render, staticRenderFns } from "./StickerCard.vue?vue&type=template&id=85c93414&scoped=true&"
import script from "./StickerCard.vue?vue&type=script&lang=js&"
export * from "./StickerCard.vue?vue&type=script&lang=js&"
import style0 from "./StickerCard.vue?vue&type=style&index=0&id=85c93414&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85c93414",
  null
  
)

export default component.exports