<template>
  <div class="container">
    <div class="activate-nurse-account my-8">
      <v-card flat class="pa-12 set-password-form white--text" color="primary">
        <h1 class="white--text mb-2">Aktivace Vašeho účtu</h1>

        <div class="mb-6 text-h6 font-weight-regular">
          Pro přihlášení do svého účtu si prosím nastavte heslo.
        </div>
        <SetPasswordForm />
      </v-card>
    </div>
  </div>
</template>

<script>
import SetPasswordForm from "../components/forms/SetPasswordForm.vue";

export default {
  name: "ActivateNurseAccount",
  components: {
    SetPasswordForm,
  },
};
</script>

<style lang="scss" scoped>
.set-password-form {
  max-width: 40rem;
  margin: auto;
}
</style>
