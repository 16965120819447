var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tresiba-detail"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"tresiba-detail__text"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('Human',{staticClass:"human-illustration",attrs:{"fill":"#01395d"}}):_vm._e(),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),(_vm.$vuetify.breakpoint.mdAndUp)?_c('Earth',{staticClass:"earth-illustration",attrs:{"fill":"#01395d"}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('Hand',{staticClass:"hand-illustration",attrs:{"fill":"#01395d"}}):_vm._e()],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('Microscope',{staticClass:"microscope-illustration",attrs:{"fill":"#01395d"}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tresiba-detail__header"},[_c('div',{staticClass:"text-h4 title"},[_vm._v("Moje léčba inzulinem Fiasp"),_c('sup',[_vm._v("®")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Fiasp"),_c('sup',[_vm._v("®")]),_vm._v(" je velmi rychle působící inzulin neboli tzv. inzulin k jídlu nebo také bolusový inzulin. Fiasp"),_c('sup',[_vm._v("®")]),_vm._v(" obsahuje účinnou látku insulin aspart a je používán k léčbě cukrovky u dospělých, dospívajících a dětí ve věku od 1 roku. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Fiasp"),_c('sup',[_vm._v("®")]),_vm._v(" se uvolňuje do vašeho krevního oběhu hned poté, co je aplikován a pomáhá tím řídit hladinu glukózy v krvi během a po jídle. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Přípravek Fiasp"),_c('sup',[_vm._v("®")]),_vm._v(" se podává spolu s jídlem a má být podán nejdříve 2 minuty před začátkem jídla s možností podání nejdéle do 20 minut od začátku jídla. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Váš lékař vám poradí, kolik jednotek přípravku Fiasp"),_c('sup',[_vm._v("®")]),_vm._v(" k jídu aplikovat. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Více informací o přípravku Fiasp"),_c('sup',[_vm._v("®")]),_vm._v(" naleznete v příbalové informaci pro pacienty. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"primary--text mt-12 mb-4"},[_vm._v("Návod na pero Flextouch")]),_c('div',[_c('div',{staticStyle:{"padding":"56.25% 0 0 0","position":"relative"}},[_c('iframe',{staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"},attrs:{"src":"https://player.vimeo.com/video/1033415045?h=5d20e17ace&badge=0&autopause=0&player_id=0&app_id=58479","frameborder":"0","allow":"autoplay; fullscreen; picture-in-picture; clipboard-write","title":"Videonávod pro Flextouch"}})])])])
}]

export { render, staticRenderFns }