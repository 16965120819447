<template>
    <div class="tresiba-detail">
        <div class="container">
            <div class="tresiba-detail__header">
                <div class="text-h4 title">
                    Moje léčba přípravkem Saxenda<sup>®</sup>
                </div>
            </div>
            <div class="tresiba-detail__text">
                <Human v-if="$vuetify.breakpoint.mdAndUp" class="human-illustration" fill="#503790" />
                <div style="padding:56.25% 0 0 0;position:relative;">
                    <iframe
                        src="https://player.vimeo.com/video/926532278?h=ae44cbe2a0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                        style="position:absolute;top:0;left:0;width:100%;height:100%;"
                        title="saxenda-video-titulky-nahled-7">
                    </iframe>
                </div>
                <Earth v-if="$vuetify.breakpoint.mdAndUp" class="earth-illustration" fill="#503790" />
                <Hand v-if="$vuetify.breakpoint.mdAndUp" class="hand-illustration" fill="#503790" />
            </div>
            <Microscope v-if="$vuetify.breakpoint.mdAndUp" class="microscope-illustration" fill="#503790" />
        </div>
    </div>
</template>

<script>
import Earth from "../illustration/Earth.vue";
import Hand from "../illustration/Hand.vue";
import Human from "../illustration/Human.vue";
import Microscope from "../illustration/Microscope.vue";
export default {
    components: { Microscope, Hand, Earth, Human },
};
</script>

<style lang="scss" scoped>
.tresiba-detail {
    &__header {
        display: inline;

        .title {
            display: inline-block;
            margin-bottom: 1rem;
        }

        position: relative;
    }

    &__text {
        position: relative;
        box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
        padding: 2rem;
        border-radius: 1rem;
        background-color: white;

        .human-illustration {
            position: absolute;
            right: 22rem;
            top: -9rem;
            max-width: 10rem;
        }

        .earth-illustration {
            position: absolute;
            left: -8.3rem;
            top: 4rem;
        }

        .hand-illustration {
            position: absolute;
            max-width: 10rem;
            right: -7.5rem;
            bottom: -8rem;
        }
    }

    .microscope-illustration {
        max-width: 32rem;
        margin-left: 10rem;
        margin-top: 0.5rem;
    }
}
</style>